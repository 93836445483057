import React from 'react';

import { Spinner } from 'components/Spinner';

const ButtonSubmit = ({
	buttonText,
	isDisabled,
}) => {
	return (
		<button
			className="btn_base"
			type="submit"
			disabled={isDisabled}
		>
			{isDisabled ? (
				<Spinner />
			) : buttonText}
		</button>
	);
};

export default ButtonSubmit;
