/* eslint-disable react/jsx-one-expression-per-line,no-console */
import React, { useCallback, useRef, useState } from 'react';
import { debounce } from 'lodash';
import Select from 'react-select';
import { cloneObjectWithoutReference } from 'utils';
import * as zefix from './zefix';

const ZefixAutocompleteField = ({
	id, name, options, field, form, placeholder, setFieldValue, setFieldTouched,
}) => {
	// Local state to manage options
	const selectRef = useRef(null);
	const [inputOptions, setInputOptions] = useState(options || []);

	// Debounced function to load data
	const loadData = useCallback(debounce(async (inputValue) => {
		if (!inputValue || inputValue.length < 3) return;
		zefix.getSearch(inputValue)
			.then(response => {
				const { list } = response;
				const newOptions = list.map(item => ({ label: item.name, value: item.name, searchData: item }));
				if (response.hasMoreResults) {
					newOptions.push({
						label: '...', value: '...', searchData: null,
					});
				}
				setInputOptions(newOptions);
				if (newOptions.length <= 3) {
					const tempRef = [cloneObjectWithoutReference(newOptions)];
					list.map(item => {
						zefix.byEhraid(item.ehraid)
							.then(data => {
								const newOpts = cloneObjectWithoutReference(tempRef[0]);
								newOpts.find(o => o.searchData.ehraid === item.ehraid).data = data;
								tempRef[0] = newOpts;
								setInputOptions(newOpts);
							})
							.catch(error => {
								console.error('Error fetching details data:', error);
							});
						return null;
					});
				}
			})
			.catch(error => {
				console.error('Error fetching search data:', error);
				setInputOptions([]);
			});
	}, 500), []); // Adjust debounce time as needed

	const handleLoadData = (inputValue) => {
		loadData(inputValue);
		return inputValue;
	};

	const handleChange = (option) => {
		const completeForm = (data) => {
			const { address } = data;
			const str = address.houseNumber ? `${address.street} ${address.houseNumber}` : address.street;
			form.setFieldValue('userCompanyVATNumber', data.uidFormatted);
			form.setFieldValue('userCompanyStreet', str);
			form.setFieldValue('userCompanyCity', address.town);
			form.setFieldValue('userCompanyZIP', address.swissZipCode);
		};
		const clearForm = () => {
			form.setFieldValue('userCompanyVATNumber', '');
			form.setFieldValue('userCompanyStreet', '');
			form.setFieldValue('userCompanyCity', '');
			form.setFieldValue('userCompanyZIP', '');
			setFieldValue(id, '');
			setFieldTouched(name);
		};
		if (!option.searchData) {
			clearForm(); // temp value
			return;
		}
		setFieldTouched(name, false);
		setFieldValue(id, option.value);
		if (option.data) {
			completeForm(option.data);
		} else {
			zefix.byEhraid(option.searchData.ehraid)
				.then(results => completeForm(results))
				.catch(e => null);
		}
	};

	const handleBlur = (f) => {
		const { value } = f.target;
		if (value && !inputOptions.find((o) => o.value === value)) {
			selectRef.current.setValue({ label: value, value });
			setFieldTouched(name, false);
			setFieldValue(id, value);
		}
		field.onBlur(f);
	};

	const formatOptionLabel = (
		{
			label, data, searchData,
		},
		{ context },
	) => {
		if (context === 'menu') {
			// This is for the dropdown menu
			let display = <span className="company">{label}</span>;
			let additionalData = null;
			if (data && data.address) {
				const { address } = data;
				const str = address.houseNumber ? (<>{address.street}&nbsp;{address.houseNumber}</>) : address.street;
				additionalData = (
					<>
						<div className="address">
							<div className="street">{str}</div>
							<div className="zipcity">
								<span>{address.swissZipCode}</span>&nbsp;<span>{address.town}</span>
							</div>
						</div>
						<div className="VATNumber">{data.uidFormatted}</div>
					</>
				);
			} else if (searchData) {
				display = (
					<>
						<span className="company">{searchData.name}</span>,&nbsp;<span>{searchData.legalSeat}</span>
						<div className="VATNumber">{searchData.uidFormatted}</div>
					</>
				);
			} else {
				return label;
			}
			return (
				<div className="company_info_dropdown">
					<div>{display}</div>
					{additionalData}
				</div>
			);
		} else {
			return label;
		}
	};

	// copied from FormField.js and modified to look like a text box
	const textBoxSelectStyles = {
		control: (base, state) => ({
			...base,
			width: "100%",
			minHeight: "0 !important",
			height: "4rem",
			padding: "0 1.4rem",
			backgroundColor: "#fbfbfb",
			borderRadius: "2px",
			borderColor: state.isFocused ? "#5486a4" : "transparent",
			boxShadow: '0 0 0 1px #456d86',
			border: "1px",
			fontSize: "1.7rem",
			color: "#575757",
		}),
		option: (base, state) => ({
			...base,
			backgroundColor: state.isSelected ? "#e5e5e5" : "#fbfbfb",
			":hover": {
				backgroundColor: "#EDEDED",
			},
			fontSize: "1.7rem",
			color: "#575757",
		}),
		placeholder: (base) => ({
			...base,
			fontSize: "1.7rem",
			color: "#575757",
			padding: 0,
		}),
		singleValue: (base) => ({
			...base,
			fontSize: "1.7rem",
			color: "#575757",
			padding: 0,
		}),
		input: (styles) => ({
			...styles,
			margin: "0",
			padding: "0",
		}),
		valueContainer: (base) => ({ ...base, padding: "0" }),
		dropdownIndicator: (base) => ({ ...base, display: "none" }),
		indicatorSeparator: (base) => ({ ...base, display: "none" }),
	};

	return (
		<Select
			options={inputOptions}
			onInputChange={(inputValue) => handleLoadData(inputValue)}
			onChange={handleChange}
			onBlur={handleBlur}
			menuIsOpen={inputOptions.length ? undefined : false}
			value={field.value ? inputOptions.find((o) => o.value === field.value) : undefined}
			formatOptionLabel={formatOptionLabel}
			styles={textBoxSelectStyles}
			placeholder={placeholder}
			ref={selectRef}
		/>
	);
};

export default ZefixAutocompleteField;
