import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import {
	de, frCH, itCH, enUS,
} from 'date-fns/locale';
import 'react-datepicker/dist/react-datepicker.css';
import i18n from 'utils/i18n';

const HighlightedDatePicker = (props) => {
	const [selectedDate, setSelectedDate] = useState(null);
	const today = new Date();
	const getLocale = (language) => {
		switch (language) {
			case 'de':
				return de;
			case 'fr':
				return frCH;
			case 'it':
				return itCH;
			case 'en_US':
			default:
				return {
					...enUS,
					options: {
						...enUS.options,
						weekStartsOn: 1, // 0 is Sunday, 1 is Monday
					},
				};
		}
	};

	return (
		<DatePicker
			selected={selectedDate}
			onChange={(date) => setSelectedDate(date)}
			minDate={today} // Disable past dates
			locale={getLocale(i18n.language)}
			dateFormat="dd.MM.yyyy"
			{...props}
		/>
	);
};

export default HighlightedDatePicker;
