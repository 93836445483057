import React from 'react';
import { View, Text } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';
import { formatNumberToFixedPdf as formatNumberToFixed } from 'utils';
import { PdfBlockHeading } from '../PdfBlockHeading';
import { PdfTotalInfoItem } from './PdfTotalInfoItem';

import styles from './styles';

const PdfTotalInfo = ({
	quantity, m2, linearMeter, kg, price, showPrice,
}) => {
	const { t } = useTranslation('configurationTableHeading');

	return (
		<View>
			<PdfBlockHeading
				title={t('totalCart')}
				iconName="equals"
			/>
			<View style={styles.totalInfoHead} className="total_info__head">
				<PdfTotalInfoItem show={quantity} value={t('quantity')} head />
				<PdfTotalInfoItem show={m2} value="m" unit={<Text style={styles.totalInfoItemSup}>2</Text>} head />
				<PdfTotalInfoItem show={linearMeter} value={t('linearMeterAbbrev')} head />
				<PdfTotalInfoItem show={kg} value="kg" head />
				{showPrice ? (<PdfTotalInfoItem show={price} value={t('price')} head />) : null}
			</View>
			<View style={styles.totalInfoBody} className="total_info__body">
				<PdfTotalInfoItem show={quantity} value={quantity} />
				<PdfTotalInfoItem show={m2} value={formatNumberToFixed(m2)} />
				<PdfTotalInfoItem show={kg} value={formatNumberToFixed(kg)} />
				{showPrice ? (<PdfTotalInfoItem show={price} value={price === null ? '-' : formatNumberToFixed(price)} />) : null}
			</View>
		</View>
	);
};

export default PdfTotalInfo;
