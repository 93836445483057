// eslint-disable-next-line object-curly-newline
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import i18n from "utils/i18n";
import GlassConfigUtil from "components/GlassConfig/GlassConfigUtil";
import { AuthContext } from "context/auth";
import { UiContext } from "context/ui";
import GlassUiContext from './GlassStructureContext';

const GlassStructureState = ({ children }) => {
	const [flatGlassStructure, setFlatGlassStructure] = useState(null);
	const [screenshotDataUrl, setScreenshotDataUrl] = useState(null);
	const uiHandlerRef = useRef(null);
	const [uiHandler, setUiHandler] = useState(null);
	const { currentUserAdditionalData } = useContext(AuthContext);
	const [showPrice, setShowPrice] = useState(currentUserAdditionalData?.company?.showPrices || false);
	const { viewApiRef } = useContext(UiContext);

	useEffect(() => {
		setShowPrice(currentUserAdditionalData?.company.showPrices || false);
	}, [currentUserAdditionalData]);

	// Invalidate screenshot when a new structure is set
	useEffect(() => {
		setScreenshotDataUrl(null);
	}, [flatGlassStructure]);

	const glassConfig = useMemo(() => {
		if (screenshotDataUrl && flatGlassStructure) {
			let lang = i18n.language === 'en' ? 'en_US' : i18n.language;
			let translatedGlassConfig = GlassConfigUtil.convertFlatGlassStructureToGlassConfig(flatGlassStructure, uiHandlerRef, lang);
			if (translatedGlassConfig) {
				translatedGlassConfig.screenshot = screenshotDataUrl;
			}
			return translatedGlassConfig;
		}
		return {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [screenshotDataUrl, flatGlassStructure, i18n.language]);

	// take glass screenshot and finalize glassConfig
	const takeScreenshot = useCallback(() => {
		if (!viewApiRef.current.areAllModelsLoaded()) {
			return;
		}

		viewApiRef.current.take_screenshot({
			callback: (blob) => {
				if (blob) {
					// Create a data URL from the Blob
					const reader = new FileReader();
					reader.onloadend = () => {
						setScreenshotDataUrl(reader.result);
					};
					reader.readAsDataURL(blob);
				} else {
					setScreenshotDataUrl(null);
				}
			},
			width: 900,
			height: 600,
		});
	}, [viewApiRef]);

	const providerValue = useMemo(() => {
		return {
			showPrice,
			flatGlassStructure,
			setFlatGlassStructure,
			glassConfig,
			takeScreenshot,
			uiHandlerRef,
			uiHandler,
			setUiHandler,
		};
	}, [
		showPrice,
		flatGlassStructure,
		setFlatGlassStructure,
		glassConfig,
		takeScreenshot,
		uiHandlerRef,
		uiHandler,
		setUiHandler,
	]);

	return (
		<GlassUiContext.Provider value={providerValue}>
			{children}
		</GlassUiContext.Provider>
	);
};

export default GlassStructureState;
