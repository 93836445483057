import React from 'react';
import { View, Text } from '@react-pdf/renderer';

import styles from './styles';

const getItemStyle = isLastCell => {
	if (isLastCell) {
		return {
			...styles.totalInfoItem,
			...styles.totalInfoItemHead,
		};
	}

	return styles.totalInfoItem;
};

export const PdfTotalInfoItem = ({
	show, value, unit, head,
}) => {
	return show ? (
		<View style={getItemStyle(head)}>
			<Text>{value}</Text>
			{unit && <Text>{unit}</Text>}
		</View>
	) : null;
};
