import React from "react";

const GlassConfigTotalCell = ({ label, value }) => {
	if (!label || !value) return null;

	return (
		<div className="glass_config_total__cell">
			<div className="glass_config_table__cell_title glass_config_table__notes_hdr">
				{label}
			</div>
			<div className="glass_config_total__value">{value}</div>
		</div>
	);
};

export default GlassConfigTotalCell;
