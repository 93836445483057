import React from 'react';

const GlassConfigTableInput = React.forwardRef(({
	placeholder = '',
	name,
	type = 'text',
	...props
}, ref) => {
	return (
		<div className="glass_config_table_input">
			<input
				ref={ref}
				className="glass_config_table_input__element"
				type={type}
				name={name}
				placeholder={placeholder}
				{...props}
			/>
		</div>
	);
});

export default GlassConfigTableInput;
