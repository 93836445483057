import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SaveAsBlock } from 'components/SaveAsSection';
import { resetFilters } from 'components/ThreeJs/assets/main';
import { AppDataContext } from 'context/appData';
import { ConfigurationDataContext, GlassStructureContext } from 'context/configuration';
import { UiContext } from 'context/ui';
import GlassProductSideOption from './GlassProductSideOption';
import InfoBox from './InfoBox';

const sqmPriceFormat = (price) => {
	if (!price) {
		return price;
	}
	const roundedPrice = Math.round(price);
	if (roundedPrice === price) {
		return `${price}.00`;
	}
	return `${Math.round(price * 100) / 100.0}`; // Display 2 digits
};

const GlassProductSidebar = ({
	name,
	rightKeys,
}) => {
	const { t } = useTranslation('commonConfigurationAction');
	const { isConfiguratorLoaded } = useContext(AppDataContext);
	const {
		addNewConfiguration, editingGlassIdRef, saveEditedConfiguration, isLoading,
	} = useContext(ConfigurationDataContext);
	const {
		flatGlassStructure,
		glassConfig,
		uiHandlerRef,
		takeScreenshot,
		showPrice,
	} = useContext(GlassStructureContext);
	const { showPopupByKey } = useContext(UiContext);
	const [expectingGlassConfig, setExpectingGlassConfig] = useState(false);
	const [submitting, setSubmitting] = useState(false);
	const [glassSqmData, setGlassSqmData] = useState(null);

	const onAddPositionHandler = () => {
		// changes glassConfig when done which is picked up by useEffect
		setExpectingGlassConfig(true);
		takeScreenshot();
		setSubmitting(true);
	};

	const showResetGlassPopup = (e) => {
		e.stopPropagation();
		showPopupByKey("resetGlass");
	};

	const showResetConfigurationPopup = (e) => {
		e.stopPropagation();
		showPopupByKey("resetConfiguration");
	};

	useEffect(() => {
		if (expectingGlassConfig && Object.keys(glassConfig).length > 0 && !isLoading) {
			if (editingGlassIdRef.current === null) {
				addNewConfiguration(glassConfig);
			} else {
				saveEditedConfiguration(glassConfig);
			}
			setExpectingGlassConfig(false);
			resetFilters(); // reset the glass on addPosition
			if (submitting) {
				setSubmitting(false);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [glassConfig, expectingGlassConfig, isLoading]);

	useEffect(() => {
		if (flatGlassStructure) {
			setGlassSqmData({ price: sqmPriceFormat(flatGlassStructure.sqmPrice), weight: flatGlassStructure.sqmWeight });
		}
	}, [flatGlassStructure]);

	return (
		<div id={name} className="glass_product_col info_mod">
			<div className="instruction instruction_side">
				2.&nbsp;
				{t('secondCustomize')}
			</div>
			{rightKeys.map(f => <GlassProductSideOption key={f} name={f} uiHandlerRef={uiHandlerRef} />)}
			<div className="glass_product_col_ctrl">
				<div className="glass_product_sqm_data glass_product_selection_info">
					<div className="glass_product_sqm_weight">
						{(glassSqmData?.weight || "--")}
						&nbsp;kg/m
						<sup>
							2
						</sup>
					</div>
					<div>
						<span className="glass_product_sqm_price">
							<span className={showPrice ? "" : "glass_product_sqm_price_blur"}>
								{(showPrice && glassSqmData?.price) || "xxx.xx"}
								&nbsp;
							</span>
							CHF/m
							<sup>
								2&nbsp;
								<InfoBox hoverMessage={
									t(
										showPrice
											? (glassSqmData?.price ? 'priceInfoBox' : 'priceNotAvailableInfoBox')
											// eslint-disable-next-line comma-dangle
											: ('loginForPriceInfoBox')
									)
								}
								/>
							</sup>
						</span>
						<br />
						<span style={{ fontSize: "1.3rem" }}>{"(<25m²)"}</span>
					</div>
				</div>
				<div className="btn_wrap offset_mod flex_mod">
					<div className="cart_pdf">
						<button disabled={!isConfiguratorLoaded || submitting} className="btn_v3" type="button" onClick={onAddPositionHandler}>
							<span className="btn_in">{editingGlassIdRef.current === null ? t('recordPositions') : t('updateGlassType')}</span>
						</button>
						<SaveAsBlock pdfData={glassConfig} takeScreenshot={takeScreenshot} isDisabled={!isConfiguratorLoaded} isHomePage />
					</div>
				</div>
				<ul className="save_as_block__list mod_left_align">
					<li className="save_as_block__list_item">
						<button
							className="btn_v4 save_as_block__glass_button"
							type="button"
							onClick={showResetGlassPopup}
						>
							{t("resetGlass")}
						</button>
					</li>
					<li className="save_as_block__list_item">
						<button
							className="btn_v4 save_as_block__glass_button"
							type="button"
							onClick={showResetConfigurationPopup}
						>
							{t("resetConfiguration")}
						</button>
					</li>
				</ul>
			</div>
		</div>
	);
};

export default GlassProductSidebar;
