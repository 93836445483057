import SoundDampening from '../Glass/SoundDampening.js';
import AbstractFilter from './AbstractFilter.js'

export default class SoundFilter extends AbstractFilter {

	constructor() {
		super("SoundFilter", false);

		this.i18n_prefix = "sound_";
		this.filterOptions = {
			// "Rw45C-1Ctr-3": "format",
			"NPD": "format_npd",
		}
	}

	setSoundValues(values) {
		// Object.keys(this.filterOptions).forEach((key) => delete this.filterOptions[key]);
		const formatFn = (p) => {
			const v = SoundDampening.displayFromCanonical(p);
			return { translationKey: v.orBetter ? 'format_better_than' : 'format', displayVal: v.displayVal };
		};
		values.forEach(v => (this.filterOptions[v] = formatFn)); // becomes "sound_format" or "sound_format_better_than" in FilterUIElement.js
		values.forEach(v => (this.filterOptions[`${v}OrBetter`] = formatFn)); // becomes "sound_format" or "sound_format_better_than" in FilterUIElement.js
	}

	applyFilter(dbView) {
		super.applyFilter(dbView);
		if (this.selectedFilter === null || this.selectedFilter === 'NPD') {
			return;
		}

		// Comparison needs to be as follows, but filtering has to happen all over the glass-structure (glasses and spacers)
		// if (SoundDampening.getCanonicalSoundValue(dbView['frg'][i]) == this.selectedFilter) {

		for (var i = 0; i < dbView['frg'].length; ++i) {
			if (dbView['frg'][i] == this.selectedFilter) {
				dbView['frgFiltered'].push(dbView['frg'].splice(i, 1)[0]);
				i -= 1;
			}
		}
	}
}
