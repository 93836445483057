import AbstractFilter from './AbstractFilter.js'

export default class ButtJointsFilter extends AbstractFilter {
	constructor() {
		super('ButtJointsFilter', false);
		this.i18n_prefix = "buttJoints_";
		this.filterOptions = {
			"checked": "yes",
			"unchecked": "no",
		};
	}

	_isButtJoint(aGlass) {
		// Some entries are empty: treat those as unchecked
		return aGlass.butt_joints ? "checked" : "unchecked";
	}

	applyFilter(dbView) {
		super.applyFilter(dbView);
		if (this.selectedFilter === null) {
			return;
		}

		for (var i = 0; i < dbView['frg'].length; ++i) {
			if (this._isButtJoint(dbView['frg'][i]) !== this.selectedFilter) {
				dbView['frgFiltered'].push(dbView['frg'].splice(i, 1)[0]);
				i -= 1;
			}
		}

		// Filter out all mid and out glasses since only 1-Glazed can be butt-joined
		for (var i = 0; i < dbView['mid'].length; ++i) {
			dbView['midFiltered'].push(dbView['mid'].splice(i, 1)[0]);
			i -= 1;
		}

		for (var i = 0; i < dbView['out'].length; ++i) {
			dbView['outFiltered'].push(dbView['out'].splice(i, 1)[0]);
			i -= 1;
		}
	}
}
