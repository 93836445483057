/* eslint-disable indent */
export const TRANSLATION_DE = {
  i18n: {
    language: "Sprache",
    languageName: "Deutsch",
  },
  faq: {
    instruction: "Bedienungsanleitung",
  },
  visualizerComponent: {
    measurementArrowsLabelFormat: "ED {}",
  },
  highlightComponent: {
    next: "Weiter",
  },
  baseFormPlaceholders: {
    userEmailPlaceholder: "E-Mail",
    userPasswordPlaceholder: "Passwort",
    repeatPasswordPlaceholder: "Passwort wiederholen",
    newPasswordPlaceholder: "Neues Passwort",
    repeatNewPasswordPlaceholder: "Neues Passwort wiederholen",
    userGenderPlaceholder: "Geschlecht",
    userCompanyPlaceholder: "Firma",
    userCompanyVATNumberPlaceholder: "Schweizer MwSt. Nr.",
    userFirstNamePlaceholder: "Vorname",
    userLastNamePlaceholder: "Nachname",
    userFunctionPlaceholder: "Funktion",
    userCompanyEmailPlaceholder: "Email",
    userStreetPlaceholder: "Strasse & Hausnummer",
    userZIPPlaceholder: "PLZ",
    userCityPlaceholder: "Stadt",
    userPhonePlaceholder: "Telefon",
    searchField: "Suchen",
    userGenderMale: "Männlich",
    userGenderFemale: "Weiblich",
    userGenderDiv: "Divers",
    userFunctionOwner: "Inhaber/-in",
    userFunctionCoOwner: "Mitinhaber/-in",
    userFunctionManagement: "Verwaltung",
    userFunctionExecutiveManagement: "Geschäftsleitung",
    userFunctionPurchasingProcurement: "Einkauf/Beschaffung",
    userFunctionSales: "Verkauf",
    userFunctionProjectManagement: "Projektleitung",
    userFunctionCalculationCosting: "Kalkulation",
    userFunctionAdministrativeProcessing: "Sachbearbeiter",
    userFunctionOther: "Andere",
  },
  commonAppValues: {
    types: "# Typen",
    created: "Erstellt",
    lastEdited: "Zuletzt bearbeitet",
    configuration: "Konfiguration",
    company: "Unternehmen",
    user: "Benutzer",
    orders: "Aufträge",
    openOrders: "Offene Aufträge",
    openRequests: "Offene Anfragen",
    drafts: "Entwürfe",
    requests: "Anfragen",
    addPosition: "Position hinzufügen",
    companies: "Unternehmen",
    configurationCodeLabel: "Code",
  },
  notifications: {
    loadConfiguration: "Konfiguration wird geladen...",
    configurationUpdatedSuccessfully: "Konfiguration gespeichert.",
    configurationIsSavedInTheCompany:
      "Die Konfiguration wurde in Ihrem Unternehmen gespeichert!",
    configurationUpdatedError:
      "Es ist ein Fehler aufgetreten, die Konfiguration wurde nicht aktualisiert",
    configurationNotFound: "Die Konfiguration wurde nicht gefunden!",
    confirmationOfPositionDeletion:
      "Bist du sicher, dass du diese Position löschen willst?",
    confirmationOfGlassDeletion:
      "Bist du sicher, dass du diese Glaskonfiguration löschen willst?",
    confirmationOfGlassEdit: "Willst du diese Glaskonfiguration bearbeiten?",
    errorAddingFileWrongFormat: "Es können nur Bilder, Zeichnungen und PDFs hinzugefügt werden",
    confirmationOfDocumentDeletion:
      "Bist du sicher, dass du dieses Dokument löschen willst?",
    confirmationOfConfigurationDeletion:
      "Bist du sicher, dass du diese Konfiguration löschen willst?",
    errorLargeFileSizeFmt: "Maximale Dateigrösse {}",
    accountCreationInProgress: "Kontoerstellung im Gange",
    accountCreatedSuccessfully: "Du hast dich erfolgreich angemeldet",
    orderSuccessful: "Deine Bestellung wurde abgeschickt. Vielen Dank.",
    quoteSuccessful: "Deine Anfrage wurde abgeschickt. Vielen Dank.",
    emailSendingInProgress: "Bitte warte während die Nachricht gesendet wird.",
    configurationDeletionInProgress:
      "Die Löschung der Konfiguration ist im Gange",
    configurationWasDeletedSuccessfully:
      "Die Konfiguration wurde erfolgreich gelöscht",
    passwordResetInProgress: "Kennwortrücksetzung in Bearbeitung",
    passwordHasBeenSuccessfullyChanged: "Passwort wurde erfolgreich geändert",
    pleaseWait: "Bitte warten...",
    companyDataWasUpdated: "Die Firmendaten wurden aktualisiert!",
    emailWithInstuctionsToResetPasswordHasBeenSent:
      "Eine E-Mail mit Anweisungen zum Zurücksetzen deines Passworts wurde gesendet.",
    confirmationOfChangeTypeConfiguration:
      "Bist du sicher, dass du den Typ dieser Konfiguration ändern möchtest?",
    configurationTypeChangeInProgress:
      "Die Änderung des Konfigurationstyps ist im Gange",
    configTypeChangedSuccessfully: "Konfigurationstyp erfolgreich geändert!",
    configurationStatusChangeInProgress:
      "Änderung des Konfigurationsstatus in Bearbeitung",
    configurationStatusChangedSuccessfully:
      "Konfigurationsstatus erfolgreich geändert!",
    duplicatingConfigWithAllFilesInProgress:
      "Bitte warten. Duplizieren einer Konfiguration mit allen Dateien im Gange",
    configurationDuplicationCompletedSuccessfully:
      "Konfiguration erfolgreich dupliziert",
    confirmationOfClientCompanyDeletion:
      "Bist du sicher, dass du dieses Kundenunternehmen mit seinen Benutzern und Konfigurationen löschen möchtest?",
    clientCompanyDeletionInProgress: "Löschung des Kundenunternehmens läuft",
    clientCompanyWasDeletedSuccessfully:
      "Kundenunternehmen wurde erfolgreich gelöscht",
    companyNotFound: "Firma nicht gefunden!",
    loadingCompanyData: "Laden der Firmendaten...",
    dataLoadedSuccessfully: "Daten erfolgreich geladen!",
    dataHasBeenSuccessfullyUpdated:
      "Die Daten wurden erfolgreich aktualisiert!",
    userHasBeenSuccessfullyRemovedFromTheCompany:
      "Der Benutzer wurde erfolgreich aus dem Unternehmen entfernt!",
    confirmationOfUserDeletion:
      "Bist du sicher, dass du diesen Benutzer aus dem Unternehmen entfernen willst?",
    invitationLinkHasBeenCopied: "Link wurde in die Zwischenablage kopiert!",
    anErrorHasOccurred: "Ein Fehler ist aufgetreten",
    loadingDataOfAllCompaniesInProgress:
      "Laden der Daten aller Unternehmen läuft",
    uploadDocAreaDragAndDrop: "Dateien hierher ziehen & ablegen",
    uploadDocAreaOr: "oder",
    uploadDocAreaBrowseFiles: "Dateien auf Ihrem Computer suchen",
    uploadDocAreaMaxFileSizeFmt: "Maximale Dateigrösse {}",
    attachmentDeletionInProgress: "Anhang wird gelöscht...",
    attachmentWasDeletedSuccessfully: "Anhang gelöscht",
  },
  formNotifications: {
    emailRequired: "E-Mail ist erforderlich",
    enterValidEmail: "Bitte gib eine gültige E-Mail ein",
    VATNumberRequired: "MwSt Nummer ist erforderlich",
    enterValidVATNumber: "Bitte gib eine gültige schweizer MwSt Nummer ein (CHE-000.000.000)",
    fieldIsRequired: "Ist erforderlich",
    nameFieldInvalidCharacters: "Ungültige Zeichen",
    nameFieldCharactersLength: "Muss aus mindestens drei Zeichen bestehen",
    passwordFieldCharactersLong: "Das Passwort muss 8 Zeichen lang sein",
    passwordFieldMustContainNumber:
      "Ungültiges Passwort. Muss eine Zahl enthalten.",
    passwordNotMatched: "Kennwort stimmt nicht überein",
  },
  configurationCategory: {
    glassType: "Glastyp",
    fireResistant: "Brandschutzglas",
    spacer1: "Abstandshalter",
    gas1: "Gas",
    middleGlass: "Mittleres Glas (Silverstar EN2plus)",
    spacer2: "Abstandshalter",
    gas2: "Gas",
    outsideGlass: "Äusseres Glas (Silverstar EN2plus)",
    structure: "Aufbau",
    structure_info: "",
    manufacturer: "Hersteller",
    manufacturer_info: "",
    fireResistanceClass: "Feuerwiderstand",
    fireResistanceClass_info: "Nach EN 13501-2",
    insulation: "U-Wert",
    insulation_info: "In [W/m²K]\nNach EN 673",
    application: "Anwendung",
    application_info: "",
    whiteGlass: "Weissglas",
    whiteGlass_info: "",
    resistanceClass: "Widerstandsklasse",
    resistanceClass_info: "Nach EN 1627",
    buttJoints: "Stossfugen",
    buttJoints_info: "",
    sound: "Schalldämmung Rw",
    sound_info: "Nach EN 140-3",
    thickness: "Elementdicke",
  },
  filterName: {
    StructureFilter: "Aufbau",
    ManufacturerFilter: "Hersteller",
    FireClassFilter: "Feuerwiderstand",
    InsulationFilter: "U-Wert",
    ApplicationFilter: "Anwendung",
    WhiteGlassFilter: "Weissglas",
    ResistanceClassFilter: "Widerstandsklasse",
    ButtJointsFilter: "Stossfugen",
    SoundFilter: "Schalldämmung",
  },
  commonConfigurationAction: {
    recordPositions: "Positionen Erfassen",
    updateGlassType: "Glastyp aktualisieren",
    resetGlass: "Glas zurücksetzen",
    resetConfiguration: "Alle Positionen zurücksetzen",
    saveAsPdf: "Als PDF speichern",
    creatingPdf: "PDF wird erstellt...",
    glassDotPdfFmt: "CDR_Glas_{date}.pdf",
    configurationDotPdfFmt: "CDR_Konfiguration_{date}.pdf",
    backToPositionsEntry: "Zurück zur Positionseingabe",
    continue: "Fortsetzen",
    continueWithoutReference: "Ohne Kommission Fortsetzen",
    open: "Öffnen",
    reopen: "Wiedereröffnen",
    delete: "Löschen",
    complete: "Abschliessen",
    duplicate: "Duplizieren",
    edit: "Bearbeiten",
    order: "Bestellen",
    request: "Anfrage",
    duplicateAsDraft: "Duplizieren als Entwurf",
    firstSelectProperties: "Eigenschaften Auswählen",
    secondCustomize: "Anpassen",
    sizeValidationError:
      "Bitte stelle sicher, dass alle erforderlichen Felder ausgefüllt sind: Breite, Höhe und Menge.",
    selectProductToContinue: "Wähle ein Produkt aus, um fortzufahren.",
    priceInfoBox:
      "Tagespreis inkl. Lagerlieferung & Energiekostenzuschlag (EKZ)\nexkl. LSVA & MwSt.\nMindest-Berechnungsfläche pro Stück: 0.5 m²\nPreis gilt bis 25 m², bei mehr Menge bitte anfragen.\nFormgläser +50%.",
    priceNotAvailableInfoBox: "Der Preis für diese Position ist derzeit nur auf Anfrage verfügbar",
    loginForPriceInfoBox: "Bitte logge dich ein um Preise zu sehen",
  },
  configurationTableHeading: {
    length: "Breite",
    width: "Höhe",
    linearMeterAbbrev: "LFM",
    quantity: "Menge",
    price: "Preis",
    form: "Modell",
    formNote:
      "Für Formgläser setze das Häckchen. Bei Bestellungen füge unten deine Zeichnung an. Bei Kreisform reicht eine Notiz. Für reine Anfragen reicht das Häckchen ohne Zeichnung",
    notes: "Notiz",
    cantonalInsurance: "VKF-Nr",
    cantonalInsuranceLink: "https://www.bsronline.ch/de/registersuche/",
    total: "Gesamt",
    totalCart: "Alle Positionen",
    pos: "pos",
    pc: "stk",
    kg: "kg",
  },
  registrationForm: {
    title: "Registrierung",
    registerButton: "Registrieren",
    alreadyHaveAccount: "Du hast bereits ein Konto? ",
    logIn: "Anmelden",
  },
  loginForm: {
    title: "Anmeldung",
    dontHaveAccount: "Du hast noch kein Konto?",
    loginButton: "Anmelden",
    lostPassword: "Passwort verloren",
    signUp: "Registrieren",
    successfullyLoggedIn: "Du bist nun eingeloggt",
    loggingInProgress: "Anmelden...",
    successfullyLoggedOut: "Du bist nun abgemeldet",
  },
  lostPasswordForm: {
    dontHaveAccount: "Du hast noch kein Konto?",
    title: "Passwort verloren",
    submitButton: "Passwort zurücksetzen",
    signUp: " Registrieren",
    loggingInProgress: "Reset im Gange",
    successfullyLoggedIn: "Zurücksetzen erfolgreich",
  },
  resetConfigurationForm: {
    title: "Konfiguration zurücksetzen",
    text: "Möchtest du eine neue Konfiguration starten und die aktuelle behalten? Der Konfigurationscode ist 30 Tage lang gültig.",
    configurationCode: "Konfigurationscode",
    resetButton: "Zurücksetzen",
    or: "oder",
    textNew:
      "Willst du eine neue Konfiguration starten und alle Inhalte und Dateien löschen?",
    resetDeleteButton: "Zurücksetzen und löschen",
    noConfigurationDefinedResetGlass: "Keinen positionen erfasst. Nur das aktuelle Glas wird zurückgesetzt.",
    confirmReset: "Willst du eine neue Konfiguration starten und alle Inhalte und Dateien löschen?",
    backToConfigurator: "Um zurück zum Konfigurator zu gelangen, drücke beim Glastyp Titel auf den Bleistift um sie zu bearbeiten.",
  },
  resetGlassForm: {
    title: "Glas zurücksetzen",
    text: "Möchtest du das aktuelle Glas und die Filter zurücksetzen?",
    resetButton: "Zurücksetzen",
  },
  guestForm: {
    title: "Gast",
    additionalInfo: "Zusätzliche Infos",
    continue: "Fortsetzen",
  },
  accountCompanyForm: {
    title: "Unternehmen",
  },
  accountPage: {
    companyFormTitle: "Firma",
    userFormTitle: "Benutzer",
    usersInCompanyTitle: "Benutzer im Unternehmen",
    registerNewUser: "Neuen Benutzer für dieses Unternehmen registrieren",
    copyInviteLinkFmt: "Link zur Selbstregistrierung bei {company} in die Zwischenablage kopieren",
    saveButton: "Speichern",
  },
  configurationCodeForm: {
    title: "Konfigurationscode",
    enterYourCodePlaceholder: "Gib deinen Code ein",
    overwriteConfigurationCheckbox:
      "Damit wird deine aktuelle Konfiguration überschrieben",
    submitButton: "laden",
  },
  resetPasswordForm: {
    title: "Passwort vergessen",
    submitButton: "Passwort zurücksetzen",
  },
  homePage: {
    allStatementsWithoutGuarantee: "Alle Angaben ohne Gewähr",
    disclaimer: "Haftungsausschluss",
    disclaimerLink: "https://admin.cdr.ch/wp-content/uploads/2024/05/Disclaimer-Configurator-DE.pdf",
    termsAndConditions: "AGB",
    termsAndConditionsLink: "https://admin.cdr.ch/wp-content/uploads/2024/05/AGB-DE.pdf",
    cookieSettings: "Cookie Einstellungen",
  },
  cartPage: {
    configurationCodeTitle: "Konfigurationscode",
    configurationCodeLabel: "Code",
    configurationCodeDescription:
      "Dies ist der Code für deine Konfiguration, wenn du diese später ohne Anmeldung abrufen möchtest. Er ist 30 Tage lang gültig.",
    yourReference: "Deine Kommission / Referenz",
    yourReferenceInputLabel: "Kommission 1",
    yourReferenceInputLabelPlaceholder: "",
    yourReferenceOptionalTitle: "",
    buildingReferenceInputLabel: "Kommission 2",
    buildingReferenceInputLabelPlaceholder: "",
    uploadedDocuments: "Hochgeladene Dokumente",
    addNewTypeTitle: "Neuen Glastyp hinzufügen",
    createFirstConfiguration: "Erstelle deine erste Konfiguration",
    addNewTypeBtn: "Hinzufügen",
    cartIsEmpty: "Keine Positionen erfasst",
    termsText:
      "Ich akzeptiere die <a href=\"{termsURL}\" target=\"_blank\">Allgemeinen Geschäftsbedingungen</a> und den <a href=\"{disclaimerURL}\" target=\"_blank\">Haftungsausschluss</a>",
  },
  pageNavigation: {
    login: "Anmelden",
    myCode: "Mein Code",
    dashboard: "Dashboard",
    account: "Konto",
    logout: "Abmelden",
    adminDashboard: "Admin Dashboard",
    configurator: "Konfigurator",
  },
  workflowNavigation: {
    configure: "Konfigurieren",
    cart: "Positionen Erfassen",
    inquireOrder: "Anfragen / Bestellen",
  },
  requestOrOrderPage: {
    request: "Anfrage",
    order: "Bestellen",
    preferredQuoteDate: "Offerte bis",
    preferredOrderDeliveryDate: "Lieferung am",
  },
  orderConfirmationPage: {
    orderConfirmationTitle: "Bestellbestätigung",
    quoteConfirmationTitle: "Anfragebestätigung",
    emailConfirmationSentToFmt: "Eine E-Mail Bestätigung wurde an {email} versandt.",
    backToStart: "Zurück zum Einstieg",
  },
  adminDashboardPage: {
    clientsTitle: "Kunden",
  },
  contactCompanyInformation: {
    showPrices: "Preisanzeige",
    customerReference: "Kunden Nr.",
    company: "Firma",
    address: "Adresse",
    zip: "PLZ",
    city: "Ort",
    tel: "Tel",
    email: "Email",
    numAccounts: "Benutzer",
  },
  deletePositionPopup: {
    title: "Position löschen",
    description: "Bist du sicher, dass du diese Position löschen willst?",
    deleteButton: "Löschen",
  },
  deleteAttachmentPopup: {
    title: "Anhang löschen",
    description: "Bist du sicher, dass du diesen Anhang löschen willst?",
    deleteButton: "Löschen",
  },
  supportedDevicesPopup: {
    title: "Mobil",
    description:
      "Dieser Konfigurator wird derzeit auf mobilen Geräten nicht unterstützt. Bitte verwende einen Desktop-Computer oder vergrössere das Browserfensters.",
  },
  genericWarningPopup: {
    warning: "Achtung",
    confirm: "Bestätigen",
  },
  glassCharacteristics: {
    glassStructure: "Aufbau",
    fireResistance: "Feuerwiderstand",
    uValue: "U-Wert",
    thickness: "Dicke",
    clearGlass: "Weissglas",
    resistanceClass: "Widerstandsklasse",
    buttJoints: "Geeignet für Stossfugenverglasung",
    application: "Geeignete Anwendungen",
    sound: "Schalldämmung",
    valid: "Gültige Konfiguration",
    glassMaxSizeWarningFormat:
      "Glas ist beschränkt auf {max_width_mm} x {max_height_mm} mm",
    filteredCategoryLabel: "Wegen filter nicht verfügbar",
  },
  filters: {
    changesUValue: "Ändert den U-Wert",
    gas_argon: "Argon",
    gas_krypton: "Krypton",
    gas_mixedGas: "Mischgas",
    gas_expensive:
      "Der Preis für dieses Gas ist derzeit sehr volatil und teuer",
    spacer_format: "{} mm",
    structure_1Glazed: "1-fach (Mono)",
    structure_2Glazed: "2-fach (Iso)",
    structure_3Glazed: "3-fach (Iso)",
    manufacturer_indep: "Unabhängig",
    manufacturer_AGC: "AGC",
    manufacturer_GlasTroesch: "Glas Trösch",
    fireResistanceClass_EI30: "EI30",
    fireResistanceClass_EI60: "EI60",
    fireResistanceClass_EI90: "EI90",
    insulation_UValueFormat: "{}",
    application_in: "Innen",
    application_inOut: "Innen/Aussen",
    application_outOut: "Aussen/Aussen",
    whiteGlass_yes: "Ja",
    whiteGlass_no: "Nein",
    resistanceClass_none: "Ohne",
    resistanceClass_rc2: "RC2 (WK2 / P4A)",
    resistanceClass_rc3: "RC3 (WK3 / P5A)",
    resistanceClass_rc4: "RC4 (WK4 / P6B)",
    buttJoints_yes: "Ja",
    buttJoints_no: "Nein",
    sound_format: "{} dB",
    sound_format_better_than: "min {} dB",
    sound_format_npd: "NPD",
  },
  pdfPage: {
    pageFooterFmt: "Seite {pageNum} von {numPages}",
    drafts: "Entwurf",
    order: "Bestellung",
    orderDeliveryDateFmt: "Lieferung am {}",
    request: "Anfrage",
    requestDueDateFmt: "Offerte bis {}",
  },
  firebaseErrorMessages: {
    // Fehler für Cloud Storage
    "storage/unknown": "Ein unbekannter Fehler ist aufgetreten.",
    "storage/object-not-found": "Kein Objekt existiert unter dem gewünschten Bezug.",
    "storage/bucket-not-found": "Für den Cloud-Speicher ist kein Bucket konfiguriert.",
    "storage/project-not-found": "Für den Cloud-Speicher ist kein Projekt konfiguriert.",
    "storage/quota-exceeded": "Das Kontingent für deinen Cloud-Speicher-Bucket wurde überschritten.",
    "storage/unauthenticated": "Benutzer ist nicht authentifiziert, bitte authentifiziere dich und versuche es erneut.",
    "storage/unauthorized": "Der Benutzer ist nicht berechtigt, die gewünschte Aktion durchzuführen, überprüfe deine Sicherheitsregeln, um sicherzustellen, dass sie korrekt sind.",
    "storage/retry-limit-exceeded": "Die maximale Zeitgrenze für eine Operation (Upload, Download, Löschen usw.) wurde überschritten. Versuche erneut hochzuladen.",
    "storage/invalid-checksum": "Die Datei auf dem Client stimmt nicht mit der Prüfsumme der vom Server empfangenen Datei überein. Versuche es sie erneut hochzuladen.",
    "storage/canceled": "Der Benutzer hat die Operation abgebrochen.",
    "storage/invalid-event-name": "Ungültiger Ereignisname angegeben. Muss einer von [`running`, `progress`, `pause`] sein.",
    "storage/invalid-url": "Ungültige URL an refFromURL() übergeben. Muss in der Form sein: gs://bucket/objekt oder https://firebasestorage.googleapis.com/v0/b/bucket/o/objekt?token=<TOKEN>",
    "storage/invalid-argument": "Das an put() übergebene Argument muss eine `Datei`, ein `Blob` oder ein `UInt8`-Array sein. Das an putString() übergebene Argument muss ein roher, `Base64`- oder `Base64URL`-String sein.",
    "storage/no-default-bucket": "In den Eigenschaften storageBucket deiner Konfiguration wurde kein Bucket festgelegt.",
    "storage/cannot-slice-blob": "Tritt häufig auf, wenn sich die lokale Datei geändert hat (gelöscht, erneut gespeichert usw.). Versuche erneut hochzuladen, nachdem du überprüft hast, dass die Datei sich nicht geändert hat.",
    "storage/server-file-wrong-size": "Die Datei auf dem Client entspricht nicht der Grösse der vom Server empfangenen Datei. Versuche erneut hochzuladen.",

    // Fehler der Authentifizierungs-API
    "auth/claims-too-large": "Die Claims-Ladung, die setCustomUserClaims() bereitgestellt wurde, überschreitet die maximal zulässige Grösse von 1000 Bytes.",
    "auth/email-already-exists": "Die angegebene E-Mail wird bereits von einem bestehenden Benutzer verwendet. Jeder Benutzer muss eine einzigartige E-Mail-Adresse haben.",
    "auth/id-token-expired": "Das bereitgestellte Firebase-ID-Token ist abgelaufen.",
    "auth/id-token-revoked": "Das Firebase-ID-Token wurde widerrufen.",
    "auth/insufficient-permission": "Das Zertifikat, das zur Initialisierung des Admin SDK verwendet wurde, hat nicht die notwendigen Berechtigungen, um auf die angeforderte Authentifizierungsressource zuzugreifen. Siehe Konfigurieren eines Firebase-Projekts für die Dokumentation zur Generierung eines Zertifikats mit den entsprechenden Berechtigungen und dessen Verwendung zur Authentifizierung der Admin SDKs.",
    "auth/internal-error": "Der Authentifizierungsserver hat beim Versuch, die Anfrage zu verarbeiten, einen unerwarteten Fehler festgestellt. Die Fehlermeldung sollte die Antwort des Authentifizierungsservers enthalten, die weitere Informationen enthält. Wenn der Fehler weiterhin besteht, melde das Problem bitte unserem Bug Report-Supportkanal.",
    "auth/invalid-argument": "Ein ungültiges Argument wurde einer Authentifizierungsmethode bereitgestellt. Die Fehlermeldung sollte weitere Informationen enthalten.",
    "auth/invalid-claims": "Die bereitgestellten benutzerdefinierten Claim-Attribute für setCustomUserClaims() sind ungültig.",
    "auth/invalid-continue-uri": "Die Fortsetzungs-URL muss ein gültiger URL-String sein.",
    "auth/invalid-creation-time": "Die Erstellungszeit muss ein gültiger UTC-Datumsstring sein.",
    "auth/invalid-credential": "Das zur Authentifizierung der Admin SDKs verwendete Zertifikat kann nicht für die gewünschte Aktion verwendet werden. Einige Authentifizierungsmethoden wie createCustomToken() und verifyIdToken() erfordern, dass das SDK mit einem Zertifikat als Refresh-Token oder einem Standardanwendungstoken initialisiert wird. Siehe die Dokumentation zur Initialisierung des SDK, wie man die Admin SDKs mit einem Zertifikat authentifiziert.",
    "auth/invalid-disabled-field": "Der bereitgestellte Wert für die deaktivierte Benutzereigenschaft ist ungültig. Es muss ein Boolescher Wert sein.",
    "auth/invalid-display-name": "Der bereitgestellte Wert für die Benutzereigenschaft displayName ist ungültig. Es muss ein nicht leerer String sein.",
    "auth/invalid-dynamic-link-domain": "Der bereitgestellte dynamische Link-Domain ist für das aktuelle Projekt nicht konfiguriert oder autorisiert.",
    "auth/invalid-email": "Der bereitgestellte Wert für die Benutzereigenschaft E-Mail ist ungültig. Es muss eine E-Mail-Adresse sein.",
    "auth/invalid-email-verified": "Der bereitgestellte Wert für die Benutzereigenschaft emailVerified ist ungültig. Es muss ein Boolescher Wert sein.",
    "auth/invalid-hash-algorithm": "Das Hashing-Algorithmus muss mit einem der Strings aus der Liste der unterstützten Algorithmen übereinstimmen.",
    "auth/invalid-hash-block-size": "Die Hash-Blockgrösse muss eine gültige Zahl sein.",
    "auth/invalid-hash-derived-key-length": "Die abgeleitete Schlüssellänge des Hashes muss eine gültige Zahl sein.",
    "auth/invalid-hash-key": "Der Hash-Schlüssel muss ein gültiger Byte-Puffer sein.",
    "auth/invalid-hash-memory-cost": "Die Hash-Speicherkosten müssen eine gültige Zahl sein.",
    "auth/invalid-hash-parallelization": "Die Hash-Parallelisierung muss eine gültige Zahl sein.",
    "auth/invalid-hash-rounds": "Die Anzahl der Hash-Runden muss eine gültige Zahl sein.",
    "auth/invalid-hash-salt-separator": "Das Salzseparatorfeld des Hash-Algorithmen muss ein gültiger Byte-Puffer sein.",
    "auth/invalid-id-token": "Das bereitgestellte ID-Token ist kein gültiges Firebase-ID-Token.",
    "auth/invalid-last-sign-in-time": "Die letzte Anmeldezeit muss ein gültiger UTC-Datumsstring sein.",
    "auth/invalid-page-token": "Das in listUsers() bereitgestellte nächste Seitentoken ist ungültig. Es muss ein gültiger, nicht leerer String sein.",
    "auth/invalid-password": "Der bereitgestellte Wert für die Benutzereigenschaft Passwort ist ungültig. Es muss ein String mit mindestens sechs Zeichen sein.",
    "auth/invalid-password-hash": "Der Passwort-Hash muss ein gültiger Byte-Puffer sein.",
    "auth/invalid-password-salt": "Das Passwort-Salz muss ein gültiger Byte-Puffer sein.",
    "auth/invalid-phone-number": "Der bereitgestellte Wert für die Telefonnummer ist ungültig. Es muss ein String sein, der dem E.164-Standard entspricht.",
    "auth/invalid-photo-url": "Der bereitgestellte Wert für die Benutzereigenschaft photoURL ist ungültig. Es muss ein URL-String sein.",
    "auth/invalid-provider-data": "providerData muss ein gültiges Array von UserInfo-Objekten sein.",
    "auth/invalid-provider-id": "providerId muss ein gültiger String eines unterstützten Anbieter-Identifikators sein.",
    "auth/invalid-oauth-responsetype": "Nur ein OAuth-Antworttyp sollte auf true gesetzt sein.",
    "auth/invalid-session-cookie-duration": "Die Dauer des Session-Cookies muss eine gültige Zahl in Millisekunden zwischen 5 Minuten und 2 Wochen sein.",
    "auth/invalid-uid": "Die bereitgestellte UID muss ein nicht leerer String von höchstens 128 Zeichen sein.",
    "auth/invalid-user-import": "Der zu importierende Benutzerdatensatz ist ungültig.",
    "auth/maximum-user-count-exceeded": "Die maximal zulässige Anzahl von zu importierenden Benutzern wurde überschritten.",
    "auth/missing-android-pkg-name": "Ein Android-Paketname muss bereitgestellt werden, wenn die Android-App installiert werden soll.",
    "auth/missing-continue-uri": "Eine gültige Fortsetzungs-URL muss in der Anfrage bereitgestellt werden.",
    "auth/missing-hash-algorithm": "Das Importieren von Benutzern mit Passwort-Hashes erfordert die Bereitstellung des Hashing-Algorithmus und seiner Parameter.",
    "auth/missing-ios-bundle-id": "In der Anfrage fehlt eine Bundle-ID.",
    "auth/missing-uid": "Eine UID ist für den aktuellen Vorgang erforderlich.",
    "auth/missing-oauth-client-secret": "Das Clientgeheimnis der OAuth-Konfiguration ist erforderlich, um den OIDC-Codefluss zu aktivieren.",
    "auth/network-request-failed": "Keine Verbindung",
    "auth/operation-not-allowed": "Der bereitgestellte Anmeldeanbieter ist für dein Firebase-Projekt deaktiviert. Aktiviere ihn im Abschnitt Anmeldemethode in der Firebase-Konsole.",
    "auth/phone-number-already-exists": "Die bereitgestellte Telefonnummer wird bereits von einem bestehenden Benutzer verwendet. Jeder Benutzer muss eine einzigartige Telefonnummer haben.",
    "auth/project-not-found": "Für das Zertifikat, das zur Initialisierung der Admin SDKs verwendet wurde, wurde kein Firebase-Projekt gefunden. Siehe Konfigurieren eines Firebase-Projekts für die Dokumentation zur Generierung eines Zertifikats für dein Projekt und dessen Verwendung zur Authentifizierung der Admin SDKs.",
    "auth/reserved-claims": "Einer oder mehrere der bereitgestellten benutzerdefinierten Benutzeransprüche für setCustomUserClaims() sind reserviert. Zum Beispiel sollten OIDC-spezifische Ansprüche wie (sub, iat, iss, exp, aud, auth_time usw.) nicht als Schlüssel für benutzerdefinierte Ansprüche verwendet werden.",
    "auth/session-cookie-expired": "Das bereitgestellte Firebase-Session-Cookie ist abgelaufen.",
    "auth/session-cookie-revoked": "Das Firebase-Session-Cookie wurde widerrufen.",
    "auth/too-many-requests": "Die Anzahl der Anfragen überschreitet das maximal erlaubte Limit.",
    "auth/uid-already-exists": "Die bereitgestellte UID wird bereits von einem bestehenden Benutzer verwendet. Jeder Benutzer muss eine einzigartige UID haben.",
    "auth/unauthorized-continue-uri": "Das Domain der Fortsetzungs-URL ist nicht auf der Whitelist. Füge das Domain zur Firebase-Konsole hinzu.",
    "auth/user-not-found": "Es gibt keinen Benutzerdatensatz, der dem bereitgestellten Identifikator entspricht.",
    "auth/wrong-password": "Falsches Passwort",

    // other errors
    ok: "Operation erfolgreich",
    cancelled: "Operation abgebrochen",
    unknown: "Unbekannter Fehler",
    "invalid-argument": "Ungültiges Argument",
    "deadline-exceeded": "Frist überschritten",
    "not-found": "Nicht gefunden",
    "already-exists": "Existiert bereits",
    "permission-denied": "Zugriff verweigert",
    unauthenticated: "Benutzer nicht authentifiziert",
    "resource-exhausted": "Ressource erschöpft",
    "failed-precondition": "Fehlgeschlagene Vorbedingung",
    aborted: "Operation abgebrochen",
    "out-of-range": "Ausserhalb des Bereichs",
    unimplemented: "Nicht implementiert",
    internal: "Interner Fehler",
    unavailable: "Nicht verfügbar",
    "data-loss": "Datenverlust",
  },
  email: {
    draft: "Entwurf",
    request: "Anfrage",
    order: "Bestellung",
    salutationFmt_male: "Lieber {firstName}",
    salutationFmt_female: "Liebe {firstName}",
    salutationFmt_div: "Liebe/Lieber {firstName}",
    bestRegards: "Freundliche Grüsse",
    yourCDRTeam: "Dein CDR Team",
    confirmationSubject_request: "Deine CDR Angebotsanfrage",
    confirmationSubject_order: "Deine CDR Bestellung",
    confirmationBodyFmt_request: "<div style=\"font-family: Arial, sans-serif;\"><p>{salutation},</p><p>Vielen Dank für deine Anfrage. Wir kümmern uns sofort darum.</p><p>Anbei findest du eine Kopie der Anfrage und der eingereichten Dokumente.</p></div>{signature}",
    confirmationBodyFmt_order: "<div style=\"font-family: Arial, sans-serif;\"><p>{salutation},</p><p>Vielen Dank für deine Bestellung. Wir kümmern uns sofort darum.</p><p>Anbei findest du eine Kopie der Bestellung und der eingereichten Dokumente.</p></div>{signature}",
    showPricesSubject: "CDR Configurator Preisanzeige",
    showPricesFmt: "<div style=\"font-family: Arial, sans-serif;\"><p>{salutation},</p><p>Alle {company} Konten sind jetzt für die Preisanzeige auf dem <a href=\"{configuratorURL}\">CDR Configurator</a> freigeschaltet</p></div>{signature}",
  },
  faqPage: {
    typeYourQuestion: "Tippe deine Frage ein",
    back: "Zurück",
    foundSingleAnswer: "Eine Antwort gefunden",
    foundMultipleAnswersFmt: "Es wurden {numAnswers} Antworten gefunden",
  },
  datasheet: {
    datasheetForGlassFmt: "Datenblatt zu {}",
    datasheetFmt: "Datenblatt {}",
  },
};
