import { BlockHeading } from 'components/BlockHeading';
import React from 'react';
import { useTranslation } from 'react-i18next';
import DashboardCompanyInfoItem from './DashboardCompanyInfoItem';

const AdminDashboardCompanyInfo = ({
	companyName,
	numberOfConfigurationsByType,
}) => {
	const { t } = useTranslation('commonAppValues');

	const renderCompanyInfoList = () => {
		if (!numberOfConfigurationsByType) return null;

		const {
			openRequests,
			openOrders,
			requests,
			orders,
			companies,
		} = numberOfConfigurationsByType;

		return (
			<ul className="dashboard_company_info__list">
				<DashboardCompanyInfoItem
					title={t('openRequests')}
					value={openRequests}
				/>
				<DashboardCompanyInfoItem
					title={t('openOrders')}
					value={openOrders}
				/>
				<DashboardCompanyInfoItem
					title={t('requests')}
					value={requests}
				/>
				<DashboardCompanyInfoItem
					title={t('orders')}
					value={orders}
				/>
				<DashboardCompanyInfoItem
					title={t('companies')}
					value={companies}
				/>
			</ul>
		);
	};

	return (
		<div className="dashboard_company_info">
			<BlockHeading
				title={companyName}
				iconName="user"
			/>
			{renderCompanyInfoList()}
		</div>
	);
};

export default AdminDashboardCompanyInfo;
