import React, { useContext } from 'react';
import { AppDataContext } from 'context/appData';

const HeaderInfoList = () => {
	const { themeSettings, langApp } = useContext(AppDataContext);

	return (
		<div className="header_info_list_w">
			<ul className="header_info_list">
				{themeSettings && themeSettings.contact_info ? (
					Object.keys(themeSettings.contact_info).map((key, index) => {
						let link = null;

						if (key.includes('phone')) {
							link = `tel:${encodeURIComponent(themeSettings.contact_info[key].trim())}`;
						} else if (key.includes('email')) {
							link = `mailto:${themeSettings.contact_info[key].trim()}`;
						}

						return (
							<li className="header_info_item" key={index}>
								{link ? (
									<a className="header_info_link" href={`${link}`}>{themeSettings.contact_info[key]}</a>
								) : (
									<div className="header_info_item_descr">{themeSettings.contact_info[key][langApp]}</div>
								)}
							</li>
						);
					})
				) : null}
			</ul>
		</div>
	);
};

export default HeaderInfoList;
