import {
	StyleSheet,
} from '@react-pdf/renderer';

const styles = StyleSheet.create({
	blockHeading: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		marginBottom: 5,
	},
	blockHeadingLeft: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
	},
	blockHeadingIcon: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'center',
		alignContent: 'center',
		position: 'relative',
		width: 23,
		height: 23,
		padding: 6,
		marginRight: 8,
		color: '#fff',
		backgroundColor: '#456d86',
		borderRadius: 2,
	},
	blockHeadingIconRight: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'center',
		alignContent: 'center',
		position: 'relative',
		width: 30,
		height: 30,
		padding: 6,
		color: '#fff',
	},
	blockHeadingIconImage: {
		width: '100%',
		height: '100%',
		objectFit: 'contain',
	},
	blockHeadingIconImageRight: {
		width: '100%',
		height: '100%',
		objectFit: 'contain',
		color: '#456d86',
	},
	blockHeadingTitle: {
		fontSize: 14,
		fontWeight: 700,
		textTransform: 'uppercase',
		color: '#456d86',
		lineHeight: 0.9,
	},
	blockHeadingIndex: {
		color: '#fff',
		fontSize: 10,
		lineHeight: 0.9,
		fontWeight: 700,
	},
});

export default styles;
