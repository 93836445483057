import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from 'context/auth';
import { Spinner } from 'components/Spinner';

const PrivateRoute = ({ children }) => {
	const { currentUserAdditionalData, currentUserRole } = useContext(AuthContext);

	if (!currentUserRole || !currentUserAdditionalData) {
		return (
			<section className="section dashboard">
				<div className="section__in">
					<div><Spinner darkTheme /></div>
				</div>
			</section>
		);
	}
	return currentUserAdditionalData ? children : <Navigate to="/" />;
};

export default PrivateRoute;
