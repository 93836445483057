import { Configuration } from 'ohzi-core';

import { Mesh } from 'three';

import { MeshBasicMaterial } from 'three';
import { ConeGeometry, } from 'three';
import { CylinderGeometry, } from 'three';

import GenericStructureElement from './GenericStructureElement';

import OhziLabels from './OhziLabels';

export default class MeasurementArrows extends GenericStructureElement {

  constructor(rt) {
    super();

    this.depth = 1;

    const length = 1;
    // in decimeters
    const cone_thickness = 0.015;
    this.cone_length = 0.07;

    const cable_thickness = 0.002;
    const cable_length = length - this.cone_length * 2;

    const CONE_DETAIL = 64;
    const CABLE_DETAIL = 64;

    if (Configuration['lang'] && Configuration['lang']['MeasurementArrows_label_format']) {
      this.label_format = Configuration['lang']['MeasurementArrows_label_format'];
    } else {
      this.label_format = 'ET {}';
    }

    const line_material = new MeshBasicMaterial({ color: 0x000000 });

    const line_geometry = new CylinderGeometry(cable_thickness, cable_thickness, cable_length, CABLE_DETAIL);
    line_geometry.rotateX(Math.PI * 0.5);

    const cone_geometry = new ConeGeometry(cone_thickness, this.cone_length, CONE_DETAIL);
    cone_geometry.rotateX(Math.PI * 0.5);

    this.cone_left = new Mesh(cone_geometry, line_material);
    this.cone_right = new Mesh(cone_geometry, line_material);
    this.cone_left.rotation.y = Math.PI;

    this.cone_left.position.z = -cable_length * 0.5 - this.cone_length * 0.5;
    this.cone_right.position.z = cable_length * 0.5 + this.cone_length * 0.5;

    this.add(this.cone_left);
    this.add(this.cone_right);


    this.cable = new Mesh(line_geometry, line_material);
    this.add(this.cable);


    this.label = new OhziLabels(this.label_format.replace("{}", '15'), 0.09, 0x92c7b4);
    this.label.position.y = -0.07;
    this.label.position.x = 0.055;
    this.label.rotation.z = 0.6;
    this.label.renderOrder = 1000000;
    this.add(this.label);
  }

  set(width, label_width) {
    if (Configuration['lang'] && Configuration['lang']['MeasurementArrows_label_format']) {
      this.label_format = Configuration['lang']['MeasurementArrows_label_format'];
    }
    let text = this.label_format.replace('{}', label_width.toFixed(0));
    this.label.text = text
    this.label.position.z = width * 0.5 - (text.length * 0.02);
    this.cable.scale.z = width * 1;
    this.cable.position.z = width * 0.5;

    this.cone_left.position.z = -width * 0.5 + this.cone_length * 0.5 + width * 0.5;
    this.cone_right.position.z = width * 0.5 - this.cone_length * 0.5 + width * 0.5;

  }

  get width() {
    return this.depth;
  }

  update() {
    //this.alu_material.update()
  }
}


