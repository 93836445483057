import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { availableLanguages } from 'utils/i18n';
import { ReactComponent as BurgerIcon } from 'i/icons/burger.svg';
import { telFmt, telLink } from 'utils';
import ClientBlockInfo from './ClientBlockInfo';
import ClientBlockDropdown from './ClientBlockDropdown';

const ClientBlock = ({
	showPrices,
	setShowPrices,
	customerReference,
	setCustomerReference,
	address,
	city,
	email,
	id,
	name,
	tel,
	zip,
	language,
	numAccounts,
	notifyCompanyDeleted,
	handleSort,
}) => {
	const { t } = useTranslation('contactCompanyInformation');
	const [isDropdownOpen, setDropdownOpen] = useState(false);
	const menuButtonRef = useRef(null);

	const toggleDropdownPopup = () => {
		setDropdownOpen(!isDropdownOpen);
	};

	const hideDropdownPopup = () => {
		setTimeout(() => {
			setDropdownOpen(false);
		}, 200);
	};

	const checkDropdownFoldingUpwards = () => {
		if (!menuButtonRef.current) {
			return false;
		}
		const viewportHeight = window.innerHeight;
		const { bottom } = menuButtonRef.current.getBoundingClientRect();

		const spaceBelow = viewportHeight - bottom;
		// Check if the space below the button is less than 10% of the viewport height
		return (spaceBelow < viewportHeight * 0.10);
	};

	const oneLineAddr = `${address}, ${zip} ${city}`;
	const gmapLink = `https://www.google.ch/maps/search/${encodeURIComponent(oneLineAddr)}`;
	// eslint-disable-next-line object-curly-newline

	const foldUp = checkDropdownFoldingUpwards();

	return (
		<div className="client_block">
			<div className="client_block__col client_block__col--company_state">
				<ClientBlockInfo
					id={`company_${id}`}
					title={t('company')}
					text={name}
					block="company"
					handleSort={handleSort}
				/>
			</div>
			<div className="client_block__col">
				<ClientBlockInfo
					id={`language_${id}`}
					title={t('i18n:language')}
					text={availableLanguages[language || 'de'].flag}
					block="language"
					handleSort={handleSort}
				/>
			</div>
			<div className="client_block__col client_block__col--customer_ref">
				<ClientBlockInfo
					id={`customer_ref_${id}`}
					type="formfield"
					text={customerReference}
					title={t('customerReference')}
					onBlur={setCustomerReference}
					block="customer_ref"
					handleSort={handleSort}
				/>
			</div>
			<div className="client_block__col client_block__col--show_prices">
				<ClientBlockInfo
					id={`prices_${id}`}
					clientId={id}
					title={t('showPrices')}
					onChange={setShowPrices}
					type="checkbox"
					checkboxChecked={showPrices}
					block="showPrices"
					handleSort={handleSort}
				/>
			</div>
			<div className="client_block__col client_block__col--company_address">
				<ClientBlockInfo
					id={`address_${id}`}
					title={t('address')}
					text={address}
					link={gmapLink}
					linkTarget="_blank"
					type="link"
					block="address"
					handleSort={handleSort}
				/>
			</div>
			<div className="client_block__col">
				<ClientBlockInfo
					id={`zip_${id}`}
					title={t('zip')}
					text={zip}
					block="zip"
					handleSort={handleSort}
				/>
			</div>
			<div className="client_block__col client_block__col--city">
				<ClientBlockInfo
					id={`city_${id}`}
					title={t('city')}
					text={city}
					handleSort={handleSort}
					block="city"
				/>
			</div>
			<div className="client_block__col client_block__col--tel">
				<ClientBlockInfo
					id={`tel_${id}`}
					title={t('tel')}
					text={telFmt(tel)}
					link={telLink(tel)}
					type="link"
					block="tel"
					handleSort={handleSort}
				/>
			</div>
			<div className="client_block__col client_block__col--email">
				<ClientBlockInfo
					id={`email_${id}`}
					title={t('email')}
					text={email}
					link={`mailto:${email}`}
					linkTitle={email}
					type="link"
					block="email"
					handleSort={handleSort}
				/>
			</div>
			<div className="client_block__col">
				<ClientBlockInfo
					id={`numAccounts_${id}`}
					title={t('numAccounts')}
					text={numAccounts}
					block="numAccounts"
					handleSort={handleSort}
				/>
			</div>
			<div className="client_block__col client_block__col--dropdown_state">
				<div className="client_block__col_in">
					<button
						className="client_block__btn_dropdown configuration_block_controls__button--menu_state"
						onClick={toggleDropdownPopup}
						onBlur={hideDropdownPopup}
						type="button"
					>
						<BurgerIcon ref={menuButtonRef} className="icon icon-burger" />
					</button>
					{isDropdownOpen ? (
						<ClientBlockDropdown clientCompanyId={id} notifyCompanyDeleted={notifyCompanyDeleted} foldUp={foldUp} />
					) : null}
				</div>

			</div>
			{/* <div className="data_icons_list v2_mod">
				<div className="data_item_options_w">
					<svg className="icon icon-burger ">
						<use xlinkHref="i/sprite/sprite.svg#burger" />
					</svg>
					<ul className="options_list">
						<li className="options_list_item">
							<div className="options_list_text">complete</div>
						</li>
						<li className="options_list_item">
							<div className="options_list_text">delete</div>
						</li>
					</ul>
				</div>
			</div> */}
		</div>

	);
};

export default ClientBlock;
