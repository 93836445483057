import {
	StyleSheet,
} from '@react-pdf/renderer';

const styles = StyleSheet.create({
	totalInfoHead: {
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
		alignItems: 'center',
		textAlign: 'center',
		overflow: 'hidden',
		paddingHorizontal: 20,
		paddingVertical: 8,
		backgroundColor: '#F2F2F2',
		borderTopLeftRadius: 2,
		borderTopRightRadius: 2,
	},
	totalInfoBody: {
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
		alignItems: 'center',
		textAlign: 'center',
		overflow: 'hidden',
		paddingHorizontal: 20,
		paddingVertical: 12,
		backgroundColor: "#fff",
		borderBottomLeftRadius: 2,
		borderBottomRightRadius: 2,
	},
	totalInfoItemHead: {
		fontWeight: 700,
	},
	totalInfoItem: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'flex-start',
		justifyContent: 'center',
		textAlign: 'center',
		width: 100,
		fontSize: 10,
		textTransform: 'uppercase',
		lineHeight: 1.4,
		paddingHorizontal: 20,
	},
	totalInfoItemSup: {
		fontSize: 7,
	},
});

export default styles;
