import React from 'react';

import { useTranslation } from 'react-i18next';
import { BlockHeading } from 'components/BlockHeading';

const SupportedDevicesPopup = () => {
	const { t } = useTranslation('supportedDevicesPopup');

	return (
		<div className="popup">
			<div className="supported_devices popup_in">
				<div className="supported_devices__in">
					<BlockHeading title={t('title')} iconName="warning" offsetMod />
					<div className="supported_devices__text">{t('description')}</div>
				</div>
			</div>
		</div>
	);
};

export default SupportedDevicesPopup;
