import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

const GlassConfigDetailItem = ({
	title,
	categoryLabel,
	selected,
	secondLayoutOption,
}) => {
	const { t } = useTranslation('configurationCategory');

	const detailItemClasses = classNames('glass_config__item', {
		'glass_config__item--short_state': secondLayoutOption,
	});

	const detailItemInClasses = classNames('glass_config_block', {
		'glass_config_block--sm-state': secondLayoutOption,
		'glass_config_block--lg-state': !secondLayoutOption,
	});

	return (
		<li className={detailItemClasses}>
			<div className={detailItemInClasses}>
				<div className="glass_config_block__content">
					{title ? (
						<div className="glass_config_block__title">{t(title)}</div>
					) : null}
					{categoryLabel ? (
						<div className="glass_config_block__type">{t(categoryLabel)}</div>
					) : null}
					{selected ? (
						<div className="glass_config_block__selected">{selected}</div>
					) : null}
				</div>
			</div>
		</li>
	);
};

export default GlassConfigDetailItem;
