import FireResistantGlass from "./Glass/FireResistantGlass";
import GenericGlass from "./Glass/GenericGlass";
import GlassDBView from "./GlassDBView";

export default class GlassDB {
  static PricedFRGGlasses = {
    GENERIC_EI30: 101,
    FSF30_15: 103,
    PYB16: 113,
    FRAMESTAR16: 116,
    GENERIC_EI60: 121,
    FSF60_23: 123,
    PYB25: 131,
    FRAMESTAR25: 135,
  }

  constructor() {
    this.loaded = false; // toggled by GlassDbLoader
    this.db = {
      'frg': [], 'mid': [], 'out': [],
      'uValue': { '1-glazed': [] },
      'glassTranslations': {},
      'glassPrices': { '1-glazed': {}, '2-glazed': {}, '3-glazed': {} },
      'datasheets': {
        'frg': [],
        'mid': [],
        'out': [],
        loaded: false,
      },
    };
  }

  addRow(glassLabel, data) {
    if (glassLabel === 'price') {
      return this.addPriceRow(data);
    }
    const glassType = (glassLabel === 'frg' ? FireResistantGlass : GenericGlass);
    const aGlass = new glassType(data);
    aGlass.dbIndex = this.db[glassLabel].length;
    this.db[glassLabel].push(aGlass);

    if (glassType === FireResistantGlass && aGlass.u_value) {
      this.db['uValue']['1-glazed'].push(aGlass);
    }
  }

  addTranslation(translation) {
    const intlDb = {};
    this.db['glassTranslations'] = intlDb;
    translation.forEach(o => {
      intlDb[o.id] = o;
    });
  }

  addPriceRow(pricerow) {
    if (!pricerow[0]) {
      return; // skip entries with nothing in first column
    }
    const outGlassName = pricerow[0];
    const max = (a, b) => (a >= b ? a : b);
    if (outGlassName === 'MONO') {
      pricerow[3] = parseInt(pricerow[3]);
      pricerow[6] = parseInt(pricerow[6]);
      pricerow[9] = parseInt(pricerow[9]);
      pricerow[12] = parseInt(pricerow[12]);
      const price = {
        [GlassDB.PricedFRGGlasses.GENERIC_EI30]: max(pricerow[3], pricerow[6]),
        [GlassDB.PricedFRGGlasses.FSF30_15]: pricerow[3],
        [GlassDB.PricedFRGGlasses.PYB16]: pricerow[6],
        [GlassDB.PricedFRGGlasses.FRAMESTAR16]: pricerow[6],
        [GlassDB.PricedFRGGlasses.GENERIC_EI60]: max(pricerow[9], pricerow[12]),
        [GlassDB.PricedFRGGlasses.FSF60_23]: pricerow[9],
        [GlassDB.PricedFRGGlasses.PYB25]: pricerow[12],
        [GlassDB.PricedFRGGlasses.FRAMESTAR25]: pricerow[12],
      };
      this.db['glassPrices']['1-glazed'] = price;
      return;
    }
    const outGlassId = parseInt(pricerow[1]);
    if (isNaN(outGlassId)) {
      return;
    }
    const OFFSET_2GLAZED = 3;
    const OFFSET_3GLAZED_4MM_EW = OFFSET_2GLAZED + 12;
    const OFFSET_3GLAZED_5MM = OFFSET_3GLAZED_4MM_EW + 13;
    const OFFSET_3GLAZED_5MM_EW = OFFSET_3GLAZED_5MM + 13;
    const struct_mid_glass_offsets = [OFFSET_2GLAZED, OFFSET_3GLAZED_4MM_EW, OFFSET_3GLAZED_5MM, OFFSET_3GLAZED_5MM_EW];
    const OFFSET_FSF30_15 = 0;
    const OFFSET_PYB16 = 3;
    const OFFSET_FSF60_23 = 6;
    const OFFSET_PYB25 = 9;
    const frg_glass_offsets = {
      [OFFSET_FSF30_15]: GlassDB.PricedFRGGlasses.FSF30_15,
      [OFFSET_PYB16]: GlassDB.PricedFRGGlasses.PYB16,
      [OFFSET_FSF60_23]: GlassDB.PricedFRGGlasses.FSF60_23,
      [OFFSET_PYB25]: GlassDB.PricedFRGGlasses.PYB25
    };
    const OFFSET_ARGON = 0;
    const OFFSET_MIXED = 1;
    const OFFSET_KRYPTON = 2;
    const gas_offsets = { [OFFSET_ARGON]: 'argon', [OFFSET_MIXED]: 'mixedGas', [OFFSET_KRYPTON]: 'krypton' };
    for (const s of struct_mid_glass_offsets) {
      for (var f of Object.keys(frg_glass_offsets)) {
        f = parseInt(f);
        for (var g of Object.keys(gas_offsets)) {
          g = parseInt(g);
          if (s === OFFSET_2GLAZED) {
            const iso2 = this.db['glassPrices']['2-glazed'];
            if (iso2[outGlassId] === undefined) {
              iso2[outGlassId] = [];
            }
            const outGlass = iso2[outGlassId];
            const price = parseInt(pricerow[s + f + g]);
            outGlass.push({ gas: gas_offsets[g], frgId: frg_glass_offsets[f], price: price });
            if (f === OFFSET_FSF30_15) {
              const price_pyb16 = parseInt(pricerow[s + OFFSET_PYB16 + g]);
              outGlass.push({ gas: gas_offsets[g], frgId: GlassDB.PricedFRGGlasses.GENERIC_EI30, price: max(price, price_pyb16) });
            }
          } else {
            const iso3 = this.db['glassPrices']['3-glazed'];
            if (iso3[outGlassId] === undefined) {
              iso3[outGlassId] = [];
            }
            const glassIds = pricerow[s].split(',');
            const outGlass = iso3[outGlassId];
            const price = parseInt(pricerow[s + 1 + f + g]);
            for (var mgid of glassIds) {
              mgid = parseInt(mgid);
              outGlass.push({ midGlassId: mgid, gas: gas_offsets[g], frgId: frg_glass_offsets[f], price });
              if (f === OFFSET_FSF30_15) {
                const price_pyb16 = parseInt(pricerow[s + 1 + OFFSET_PYB16 + g]);
                outGlass.push({ midGlassId: mgid, gas: gas_offsets[g], frgId: GlassDB.PricedFRGGlasses.GENERIC_EI30, price: max(price, price_pyb16) });
              }
            }
          }
        }
      }
    }
  }

  translateGlass(glass, lang) {
    let intlDb = this.db['glassTranslations'];
    let id = parseInt(glass.id);
    return (intlDb[id] && intlDb[id][lang]) || glass.glasstype;
  }

  getGlassById(glassId, glassLabel) {
    return this.db[glassLabel].filter((g) => g.id === glassId)[0];
  }

  getGlassPrice(glassStruct) {
    const frgId = parseInt(glassStruct.fireResistantGlass.id);
    if (Object.values(GlassDB.PricedFRGGlasses).indexOf(frgId) < 0) {
      return null;
    }

    const nullret = (ret) => ret === undefined ? null : ret;
    const prices = this.db['glassPrices'];
    const struct = glassStruct.getGlassStructure();
    if (struct === '1-glazed') {
      return nullret(prices['1-glazed'][frgId]);
    }
    let outGlassId = parseInt(glassStruct.outerGlass.id);
    if (struct === '2-glazed') {
      return nullret(prices['2-glazed'][parseInt(outGlassId)]?.find(g => (
        g.frgId === frgId &&
        g.gas === glassStruct.gasOutside)
      )?.price);
    }
    return nullret(prices['3-glazed'][outGlassId]?.find(g => (
      g.frgId === frgId &&
      g.gas === glassStruct.gasOutside &&
      g.midGlassId === parseInt(glassStruct.middleGlass.id))
    )?.price);
  }

  getDatasheet(flatGlassStructure, preferredLanguage = null) {
    if (flatGlassStructure.structure !== '1-glazed') return; // only return DS on MONO structures
    const frgId = flatGlassStructure.fireResistantGlassId;
    return this.getGlassById(frgId, 'frg')?.getDatasheet(preferredLanguage);
  }

  createView() {
    return new GlassDBView(this);
  }
}
