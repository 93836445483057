/* eslint-disable class-methods-use-this */
/* eslint-disable no-undef */
import React from 'react';
import { toast } from 'react-toastify';
import { ReactComponent as CloseIcon } from 'i/icons/close_circle.svg';

class ToastifyHandler {
	constructor() {
		this.toastRef = null;

		this.autoCloseDuration = 1000;
	}

	setAutoCloseDuration(duration = 1000) {
		this.autoCloseDuration = duration;
	}

	pending(message = 'Promise is pending') {
		this.toastRef = toast.loading(message);
	}

	success(message = 'Promise resolved 👌') {
		if (!this.toastRef) return;

		toast.update(this.toastRef, {
			render: message,
			type: 'success',
			isLoading: false,
			autoClose: this.autoCloseDuration,
		});
	}

	rejected(message = 'Promise rejected 🤯') {
		if (!this.toastRef) return;

		toast.update(this.toastRef, {
			render: message,
			type: 'error',
			isLoading: false,
			autoClose: this.autoCloseDuration,
			icon: <CloseIcon />,
		});
	}

	error(message = 'error') {
		toast.error(message, {
			autoClose: 2000,
			hideProgressBar: true,
			closeOnClick: false,
			closeButton: false,
			icon: <CloseIcon />,
		});
	}
}

export default ToastifyHandler;
