import React, { useContext } from 'react';

import classNames from 'classnames';
import i18n from 'utils/i18n';
import { ReactComponent as KeyIcon } from 'i/icons/key.svg';
import { ReactComponent as ConfigCodeIcon } from 'i/icons/config_code.svg';
import { ReactComponent as DocumentIcon } from 'i/icons/document.svg';
import { ReactComponent as EditIcon } from 'i/icons/edit.svg';
import { ReactComponent as OrdersIcon } from 'i/icons/orders.svg';
import { ReactComponent as PlusIcon } from 'i/icons/plus.svg';
import { ReactComponent as UserIcon } from 'i/icons/user.svg';
import { ReactComponent as PencilIcon } from 'i/icons/pencil.svg';
import { ReactComponent as DubleArrowIcon } from 'i/icons/duble_arrow.svg';
import { ReactComponent as QuestionIcon } from 'i/icons/question.svg';
import { ReactComponent as WarningIcon } from 'i/icons/warning.svg';
import { ReactComponent as EqualsIcon } from 'i/icons/equals.svg';
import { ConfigurationDataContext, GlassStructureContext } from 'context/configuration';
import { UiContext } from 'context/ui';
import GlassConfigEditOptions from 'components/GlassConfig/GlassConfigEditOptions';
import DatasheetBox from 'components/GlassProduct/DatasheetBox';
import { useTranslation } from 'react-i18next';

const BASE_ICON_CLASS = 'icon size_mod';

const BlockHeading = ({
	title,
	optionalTitle,
	iconName,
	showGlassConfigButtons = false,
	parent,
	attrKey,
	glassData,
	blockIndex,
	offsetMod,
	showPrice, // only needed with showGlassConfigButtons = true
}) => {
	const { uiHandler } = useContext(GlassStructureContext);
	const { editConfiguration } = useContext(ConfigurationDataContext);
	const { t } = useTranslation('commonConfigurationAction');
	const { showPopupByKey } = useContext(UiContext);
	const blockHeadingClasses = classNames('block_heading', {
		'block_heading--offset_mod': offsetMod,
	});

	const datasheetUrl = glassData
		&& uiHandler.glassDB.getDatasheet(
			glassData.flatGlassStructure,
			i18n.language,
		);
	const renderHeadingIcon = () => {
		if (iconName === 'config_code') {
			return <ConfigCodeIcon className={BASE_ICON_CLASS} />;
		}

		if (iconName === 'document') {
			return <DocumentIcon className={BASE_ICON_CLASS} />;
		}

		if (iconName === 'plus') {
			return <PlusIcon className={BASE_ICON_CLASS} />;
		}

		if (iconName === 'orders') {
			return <OrdersIcon className={BASE_ICON_CLASS} />;
		}

		if (iconName === 'user') {
			return <UserIcon className={BASE_ICON_CLASS} />;
		}

		if (iconName === 'pencil') {
			return <PencilIcon className={BASE_ICON_CLASS} />;
		}

		if (iconName === 'duble_arrow') {
			return <DubleArrowIcon className={BASE_ICON_CLASS} />;
		}

		if (iconName === 'question') {
			return <QuestionIcon className={BASE_ICON_CLASS} />;
		}

		if (iconName === 'warning') {
			return <WarningIcon className={BASE_ICON_CLASS} />;
		}

		if (iconName === 'equals') {
			return <EqualsIcon className={BASE_ICON_CLASS} />;
		}

		return <KeyIcon className="icon icon-key size_mod" />;
	};

	const editGlassItem = (e) => {
		// editing item {glassData} with index {attrKey}
		e.preventDefault();
		e.stopPropagation();
		if (parent.constructor === Array) {
			showPopupByKey('genericWarning', {
				title: t('notifications:confirmationOfGlassEdit'),
				confirmDialog: isConfirmed => isConfirmed && editConfiguration(glassData),
			});
		}
	};

	return (
		<div className={blockHeadingClasses}>
			<div className="block_heading_start">
				<div className="block_heading__icon">
					{blockIndex ? (
						<div className="block_heading__index">{`${blockIndex}.`}</div>
					) : (
						renderHeadingIcon()
					)}
				</div>
				<h2 className="block_heading__title">{title}</h2>
				{optionalTitle && (
					<div className="block_heading__subtitle">{optionalTitle}</div>
				)}
				{showGlassConfigButtons && (
					<button type="button" onClick={editGlassItem} className="block_heading__title_edit_btn">
						<EditIcon className="icon" />
					</button>
				)}
			</div>
			<div className="block_heading_end">
				{showGlassConfigButtons && (
					<GlassConfigEditOptions
						attrKey={attrKey}
						glassData={glassData}
						parent={parent}
						showPrice={showPrice}
					/>
				)}
				{datasheetUrl && (
					<DatasheetBox datasheetUrl={datasheetUrl} />
				)}
			</div>
		</div>
	);
};

export default BlockHeading;
