import React from 'react';

import { Spinner } from 'components/Spinner';
import { useNavigate } from 'react-router-dom';

const BackButton = ({
	buttonText,
	isDisabled,
	navigateToUrl,
	handleClick,
	classNames,
}) => {
	const navigate = useNavigate();

	// eslint-disable-next-line no-underscore-dangle
	const _handleClick = () => {
		navigate(navigateToUrl);
	};

	const className = classNames ? `btn_v3 ${classNames}` : 'btn_v3';
	return (
		<button
			className={className}
			type="button"
			disabled={isDisabled}
			onClick={handleClick || _handleClick}
		>
			{isDisabled ? (
				<Spinner />
			) : buttonText}
		</button>
	);
};

export default BackButton;
