import { Mesh } from 'three';
import { BoxGeometry } from 'three';
import { Shape } from 'three';
import { ExtrudeGeometry } from 'three';
import { MeshPhysicalMaterial } from 'three';

import GenericStructureElement from './GenericStructureElement';

export default class SpacerCorner extends GenericStructureElement {

	constructor() {
		super();

		this.depth = 1;

		const thickness = 0.1;
		// in decimeters
		const alu_thickness = 0.01;
		const alu_vertical = 0.04 - alu_thickness;
		const alu_sides = 0.0192;
		const alu_suplement = 0.02 - 0.0192;

		const plastic_material = new MeshPhysicalMaterial({
			roughness: 0.5,
			metalness: 0.1,
			reflectivity: 0.12,
			clearcoat: 0.59,
			clearcoatRoughness: 0.44,
			color: 0x505050
		});

		const alu_material = new MeshPhysicalMaterial({
			roughness: 0.9,
			metalness: 0.1,
			reflectivity: 0.20,
			clearcoat: 0.20,
			clearcoatRoughness: 0.5,
			color: 0x0f0f0f
		});

		const geometry_alu = new BoxGeometry(thickness * 0.4 + alu_suplement, thickness * 0.4 + alu_suplement, this.depth);
		geometry_alu.translate(thickness * 0.8 - alu_suplement * 0.5, thickness * 0.8 - alu_suplement * 0.5, 0)
		this.alu = new Mesh(geometry_alu, alu_material);
		//this.spacer_horizontal.receiveShadow = true;
		this.alu.castShadow = true;
		this.alu.renderOrder = 1001;
		this.add(this.alu);
		// small plastic
		const small_plastic_top_geo = new BoxGeometry(thickness * 0.6 - alu_suplement, thickness * 0.4 + alu_suplement, this.depth);
		small_plastic_top_geo.translate((thickness * 0.6 - alu_suplement) * 0.5, thickness * 0.8 - alu_suplement * 0.5, this.depth * 0.5)
		small_plastic_top_geo.computeVertexNormals();

		this.small_plastic_top = new Mesh(small_plastic_top_geo, plastic_material);
		this.small_plastic_top.castShadow = true;
		this.small_plastic_top.renderOrder = 1001;
		this.add(this.small_plastic_top);
		//
		const shape_main_plastic = new Shape();
		shape_main_plastic.moveTo(0, 0);
		shape_main_plastic.lineTo(0, thickness * 0.4 + alu_sides);
		shape_main_plastic.lineTo(this.depth, thickness * 0.4 + alu_sides);
		shape_main_plastic.lineTo(this.depth, 0);

		const number_of_cuts = 32;
		for (let i = 0; i < number_of_cuts; i++) {
			let alpha = i / number_of_cuts;
			shape_main_plastic.lineTo(this.depth * (1 - alpha), Math.sin(alpha * Math.PI) * 0.02);
		}

		const extrude_settings_main_plastic = {
			steps: 1,
			depth: thickness,
			bevelEnabled: false,
		};

		const geometry_plastic_below = new ExtrudeGeometry(shape_main_plastic, extrude_settings_main_plastic);
		geometry_plastic_below.rotateY(Math.PI * 0.5);
		geometry_plastic_below.translate(0, 0, this.depth);
		geometry_plastic_below.computeVertexNormals();

		this.plastic = new Mesh(geometry_plastic_below, plastic_material);
		//this.spacer_plastic_below.receiveShadow = true;
		this.plastic.castShadow = true;
		this.plastic.renderOrder = 1001;
		this.add(this.plastic);
	}

	set(width) {
		width *= .01;
		this.depth = width;
		if (width === 0) {
			this.visible = false;
		} else {
			//let ratio =  (((this.depth * 0.9 )) * width) * 50   0.05 / ;
			this.visible = true;

			this.alu.scale.z = width;
			this.plastic.scale.z = width;
			this.alu.position.z = width * 0.5;
			this.small_plastic_top.scale.z = width;
		}
	}

	get width() {
		return this.depth;
	}

	update() {
	}
}


