import FireResistantGlass from './../Glass/FireResistantGlass.js';

export default class AbstractFilter {
  constructor(filterName, has_default_option = true) {
    this.hasDefaultOption = has_default_option;
    this.filterName = filterName;
    /// Prefix for the translation key of the filterOptions value
    this.i18n_prefix = '';
    /// Map of option -> translation key
    this.filterOptions = {};
    this.selectedFilter = null;
  }

  setFilter(filterOption) {
    this.selectedFilter = this.filterOptions[filterOption] === undefined ? null : filterOption;
  }

  applyFilter(dbView) {
    if (this.selectedFilter) {
      dbView.filters[this.filterName] = this.selectedFilter;
    }
  }
}