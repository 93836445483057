import React, { useContext } from 'react';

import { ReactComponent as InfoIcon } from 'i/icons/info.svg';
import { useTranslation } from 'react-i18next';
import { generateUniqueId } from 'utils';
import jsonAppData from 'utils/jsonAppData';
import { AuthContext } from 'context/auth';

const GlassConfigTableHeadingRow = () => {
	const { currentUser } = useContext(AuthContext);
	const { t } = useTranslation('configurationTableHeading');
	const { glassConfigTableHeadingRow } = jsonAppData.cartPage;

	return (
		<li className="glass_config_table__row glass_config_table__row--heading_state">
			{glassConfigTableHeadingRow && glassConfigTableHeadingRow.length ? (
				glassConfigTableHeadingRow.filter((v) => v.label !== 'notes').map(({
					label,
				}, index) => {
					if (label === 'price' && (currentUser === null)) {
						return null;
					}
					return (
						// eslint-disable-next-line react/jsx-fragments
						<React.Fragment key={index}>
							{index === 0 ? (
								// no header for the position index e.g. "1.1"
								<div className="glass_config_table__cell glass_config_table__position_idx" />
							) : null}
							<div className="glass_config_table__cell" key={label + generateUniqueId(10)}>
								{(() => {
									switch (label) {
										case 'form':
											return (
												<div className="glass_config_table__cell_title">
													<div className="glass_config_table__btn">
														<InfoIcon className="icon icon-info size_mod" />
														<div className="glass_config_table__tooltip">{t('formNote')}</div>
													</div>
													{t(label)}
												</div>
											);

										case 'cantonalInsurance':
											return (
												<div className="glass_config_table__cell_title">
													<div className="glass_config_table__btn">
														<InfoIcon className="icon icon-info size_mod" />
														<div className="glass_config_table__tooltip"><a href={t('cantonalInsuranceLink')} target="_blank" rel="noreferrer">{t('cantonalInsuranceLink')}</a></div>
													</div>
													{t(label)}
												</div>
											);

										case 'kg':
											return (
												<>
													{t(label)}
													/
													{t('pc')}
												</>
											);

										case 'pc':
										case 'pos':
											return (
												<div>
													m
													<sup>2</sup>
													/
													{t(label)}
												</div>
											);

										case 'length':
										case 'width':
											return (
												<>
													{t(label)}
													<span> (mm)</span>
												</>
											);

										case 'price':
										// fallthrough

										default:
											return (
												<>
													{t(label)}
												</>
											);
									}
								})()}
							</div>
						</React.Fragment>
					);
				})
			) : null}
		</li>
	);
};

export default GlassConfigTableHeadingRow;
