import React from 'react';
import { useTranslation } from 'react-i18next';

import {
	Text,
	View,
} from '@react-pdf/renderer';

import styles from './styles';
import { PdfBlockHeading } from '../PdfBlockHeading';

const PdfYourReference = ({
	reference,
	buildingReference,
}) => {
	const { t } = useTranslation('cartPage');

	return (
		<View>
			<PdfBlockHeading
				title={t('yourReference')}
				iconName="duble_arrow"
			/>
			<View style={styles.yourReferenceContainer}>
				<View style={styles.yourReferenceWrap}>
					<View style={styles.yourReferenceItem}>
						<View style={styles.yourReferenceItemInner}>
							<View style={styles.formField}>
								<Text style={styles.formFieldLabel}>{t('yourReferenceInputLabel')}</Text>
								<View style={styles.formFieldInput}>
									<Text style={styles.formFieldInputLabel}>{reference || '-'}</Text>
								</View>
							</View>
						</View>
					</View>
					<View style={styles.yourReferenceItem}>
						<View style={styles.yourReferenceItemInner}>
							<View style={styles.formField}>
								<Text style={styles.formFieldLabel}>{t('buildingReferenceInputLabel')}</Text>
								<View style={styles.formFieldInput}>
									<Text style={styles.formFieldInputLabel}>{buildingReference || '-'}</Text>
								</View>
							</View>
						</View>
					</View>
				</View>
			</View>
		</View>
	);
};

export default PdfYourReference;
