import {
	StyleSheet,
} from '@react-pdf/renderer';

const styles = StyleSheet.create({
	yourReferenceContainer: {
		marginBottom: 15,
		borderBottomStyle: 'dotted',
		borderBottomColor: '#e0e0e0',
	},
	yourReferenceWrap: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		alignContent: 'center',
		marginRight: -23,
		marginBottom: -23,
	},
	yourReferenceItem: {
		width: '50%',
		paddingRight: 23,
		paddingBottom: 23,
	},
	yourReferenceItemInner: {
		paddingTop: 8,
		paddingBottom: 17,
		paddingHorizontal: 23,
		borderRadius: 2,
		backgroundColor: '#fff',
	},
	formField: {
		position: 'relative',
		display: 'block',
		width: '100%',
	},
	formFieldLabel: {
		position: 'relative',
		color: '#707070',
	},
	formFieldInput: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		alignContent: 'center',
		height: 30,
		paddingHorizontal: 11,
		fontSize: 10,
		fontFamily: 'DINNextLTPro',
		borderRadius: 2,
		backgroundColor: '#fbfbfb',
	},
	formFieldInputLabel: {
		color: '#575757',
	},
});

export default styles;
