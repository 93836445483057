import React, {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';

import { getCurrentDate, generateUniqueId, cloneObjectWithoutReference } from 'utils';
import { CONFIGURATIONS_API } from 'api';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AuthContext } from 'context/auth';
import { resetFilters } from 'components/ThreeJs/assets/main';
import { CONFIGURATION_TYPE, CONFIGURATION_STATUS } from 'utils/constant';
import ToastifyHandler from 'utils/ToastifyHandler';
import jsonAppData from 'utils/jsonAppData';
import { DEFAULT_GLASS_SIZES } from 'domain/glass';
import UiContext from './ConfigurationDataContext';
import GlassStructureContext from './GlassStructureContext';

const ConfigurationDataState = ({ children }) => {
	const {
		currentUser, currentUserAdditionalData, guestUser, setGuestUser,
	} = useContext(AuthContext);
	const { uiHandlerRef } = useContext(GlassStructureContext);
	const [configuration, setConfiguration] = useState(null);
	const [errorFields, setErrorFields] = useState([]);
	const [showErrors, setShowErrors] = useState(false);
	const [showEmptyReferenceWarning, setShowEmptyReferenceWarning] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [totalGlassSizes, setTotalGlassSizes] = useState([]);
	const [totalGlassSizesSum, setTotalGlassSizesSum] = useState(DEFAULT_GLASS_SIZES);
	const [isSizesFilled, setIsSizesFilled] = useState(true);
	const [userCodeConfiguration, setUserCodeConfiguration] = useState(null);
	const { t } = useTranslation(['notifications', 'firebaseErrorMessages']);
	const navigate = useNavigate();
	const editingGlassIdRef = useRef(null);
	const notificationsHandler = useRef(new ToastifyHandler());
	const isUserAuthorized = useRef(false);

	const resetConfiguration = useCallback(() => {
		setConfiguration(null);
		resetFilters();

		if (guestUser) {
			setGuestUser(null);
		}

		navigate('/');
	}, [guestUser, navigate, setConfiguration, setGuestUser]);

	const loadConfigurationToFirebase = useCallback(async (newConfiguration, showNotification = true) => {
		try {
			if (showNotification) notificationsHandler.current.pending(t('loadConfiguration'));
			setIsLoading(true);

			await CONFIGURATIONS_API.addNewConfiguration(newConfiguration);

			if (showNotification) notificationsHandler.current.success(t('configurationUpdatedSuccessfully'));
		} catch (error) {
			const { code } = error;
			notificationsHandler.current.rejected(t(code || 'unknown', { ns: 'firebaseErrorMessages' }));
			if (!code) {
				// eslint-disable-next-line no-console
				console.error(error);
			}
		} finally {
			setIsLoading(false);
		}
	}, [t, setIsLoading, notificationsHandler]);

	const addNewConfiguration = useCallback(async newGlassData => {
		const currentDate = getCurrentDate();
		const glassPositions = [...jsonAppData.defaultGlassPositionsData];
		glassPositions[0].id = generateUniqueId();
		const uniqueGlassId = generateUniqueId();
		let config = null;

		if (!configuration) {
			const currentDateInMilliseconds = Date.now();
			const uniqueId = generateUniqueId();

			config = {
				code: uniqueId,
				publishedAt: currentDateInMilliseconds,
				created: currentDate,
				lastEdited: currentDate,
				lastEditedDateInMilliseconds: currentDateInMilliseconds,
				reference: '',
				createdUserId: null,
				lastEditedUserId: null,
				lastEditedUserEmail: null,
				companyId: null,
				status: CONFIGURATION_STATUS.open,
				type: CONFIGURATION_TYPE.drafts,
				buildingReference: '',
				glassTypes: [{
					...newGlassData,
					id: uniqueGlassId,
					positions: [...glassPositions],
				}],
				amountGlassTypes: 1,
				uploadedDocuments: [],
			};

			if (currentUserAdditionalData) {
				config.createdUserId = currentUser.uid;
				config.lastEditedUserId = currentUser.uid;
				config.lastEditedUserEmail = currentUserAdditionalData.email;
				config.companyId = currentUserAdditionalData.companyId;
			}
		} else {
			config = cloneObjectWithoutReference(configuration);
			const updatedGlassData = cloneObjectWithoutReference(newGlassData);

			updatedGlassData.id = uniqueGlassId;
			updatedGlassData.positions = [...glassPositions];

			config.glassTypes.push(updatedGlassData);
			config.amountGlassTypes = config.glassTypes.length;
			config.lastEdited = currentDate;
			config.lastEditedUserId = currentUser?.uid;
			config.lastEditedUserEmail = currentUserAdditionalData?.email;
		}
		setConfiguration(config);
		await loadConfigurationToFirebase(config);
		navigate('/cart', { state: { scrollTo: uniqueGlassId } });
		document.activeElement.blur();
	}, [configuration, currentUser?.uid, currentUserAdditionalData, navigate, setConfiguration, loadConfigurationToFirebase]);

	const editConfiguration = useCallback(editGlassData => {
		if (!configuration) {
			return;
		}

		editingGlassIdRef.current = editGlassData.id;
		uiHandlerRef.current.applyFlatSnapshot(editGlassData.flatGlassStructure);
		navigate('/');
	}, [configuration, navigate, uiHandlerRef]);

	const saveEditedConfiguration = useCallback(async saveEditedGlassData => {
		if (!configuration) {
			return;
		}

		const currentDate = getCurrentDate();
		const updatedConfiguration = cloneObjectWithoutReference(configuration);
		const updatedGlassData = cloneObjectWithoutReference(saveEditedGlassData);

		let glassDataIdx = updatedConfiguration.glassTypes.findIndex(o => o.id === editingGlassIdRef.current);
		editingGlassIdRef.current = null;
		if (glassDataIdx < 0) {
			return;
		}
		const outdatedGlassData = updatedConfiguration.glassTypes[glassDataIdx];
		const glassPositions = [...outdatedGlassData.positions];
		updatedGlassData.positions = [...glassPositions];
		updatedGlassData.id = outdatedGlassData.id;
		updatedConfiguration.glassTypes[glassDataIdx] = updatedGlassData;
		updatedConfiguration.lastEdited = currentDate;

		setConfiguration(updatedConfiguration);
		await loadConfigurationToFirebase(updatedConfiguration);
		navigate('/cart', { state: { scrollTo: updatedGlassData.id } });
	}, [configuration, loadConfigurationToFirebase, navigate]);

	const saveGuestConfigurationToCompany = async () => {
		const newConfiguration = cloneObjectWithoutReference(configuration);

		newConfiguration.createdUserId = currentUser.uid;
		newConfiguration.lastEditedUserId = currentUser.uid;
		newConfiguration.lastEditedUserEmail = currentUserAdditionalData.email;
		newConfiguration.companyId = currentUserAdditionalData.companyId;

		try {
			notificationsHandler.current.pending(t('loadConfiguration'));
			setIsLoading(true);

			await CONFIGURATIONS_API.addNewConfiguration(newConfiguration);

			setConfiguration(newConfiguration);
			notificationsHandler.current.success(t('configurationIsSavedInTheCompany'));
		} catch (error) {
			const { code } = error;
			notificationsHandler.current.rejected(t(code, { ns: 'firebaseErrorMessages' }));
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		if (currentUser && currentUserAdditionalData) {
			if (!isUserAuthorized.current && configuration) {
				saveGuestConfigurationToCompany();
			}
			isUserAuthorized.current = true;
		} else {
			isUserAuthorized.current = false;
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentUser, currentUserAdditionalData]);

	const providerValues = useMemo(() => {
		return {
			isLoading,
			configuration,
			setConfiguration,
			addNewConfiguration,
			editConfiguration,
			editingGlassIdRef,
			saveEditedConfiguration,
			userCodeConfiguration,
			setUserCodeConfiguration,
			loadConfigurationToFirebase,
			totalGlassSizes,
			setTotalGlassSizes,
			totalGlassSizesSum,
			setTotalGlassSizesSum,
			isSizesFilled,
			setIsSizesFilled,
			errorFields,
			setErrorFields,
			showErrors,
			setShowErrors,
			showEmptyReferenceWarning,
			setShowEmptyReferenceWarning,
			resetConfiguration,
		};
	}, [
		isLoading,
		configuration,
		setConfiguration,
		addNewConfiguration,
		editConfiguration,
		editingGlassIdRef,
		saveEditedConfiguration,
		userCodeConfiguration,
		setUserCodeConfiguration,
		loadConfigurationToFirebase,
		totalGlassSizes,
		setTotalGlassSizes,
		totalGlassSizesSum,
		setTotalGlassSizesSum,
		isSizesFilled,
		setIsSizesFilled,
		errorFields,
		setErrorFields,
		showErrors,
		setShowErrors,
		showEmptyReferenceWarning,
		setShowEmptyReferenceWarning,
		resetConfiguration,
	]);

	return (
		<UiContext.Provider value={providerValues}>
			{children}
		</UiContext.Provider>
	);
};

export default ConfigurationDataState;
