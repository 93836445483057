import { ResourceContainer } from 'ohzi-core';

import { Sprite } from 'three';
import { SpriteMaterial } from 'three';
import { Object3D } from 'three';
import { LineDashedMaterial } from 'three';
import { BufferGeometry } from 'three';
import { Float32BufferAttribute } from 'three';
import { Line } from 'three';
import { PlaneGeometry } from 'three';
import { MeshBasicMaterial } from 'three';
import { Mesh } from 'three';

export default class Sun extends Object3D {
  constructor() {
    super();

    const height = 1.5;

    const sun_map = ResourceContainer.get_resource('sun');
    const sun_material = new SpriteMaterial({
      transparent: true, map: sun_map, color: 0xffffff, alphaTest: 0.5
    });
    sun_material.toneMapped = false;
    const sun = new Sprite(sun_material);
    sun.scale.set(0.35, 0.35, 1);
    sun.position.y = height;

    const line_material = new LineDashedMaterial({
      color: 0xfcda02,
      linewidth: 1,
      scale: 1,
      dashSize: 0.1,
      gapSize: 0.1,
    });

    // line
    const line_geometry = new BufferGeometry();
    const position = [];
    const x_pos = -0.1;

    position.push(
      0, height - .35, x_pos * 0.1,
      0, 0, x_pos,
    );

    line_geometry.setAttribute('position', new Float32BufferAttribute(position, 3));
    const line = new Line(line_geometry, line_material);
    line.computeLineDistances();
    //his.add(line);
    this.add(sun);

    const base_geo = new PlaneGeometry(.45, .45);
    base_geo.rotateX(-Math.PI * 0.5);
    const base_material = new MeshBasicMaterial({
      transparent: true,
      color: 0x777777,
      opacity: 0.5,
      map: ResourceContainer.get_resource('blured_circle')
    });

    const base = new Mesh(base_geo, base_material);
    base.position.z = x_pos;
    //this.add(base);
  }
}


