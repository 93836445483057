import React from 'react';
import { AccountCompanyForm, UpdateUserNameForm, UpdateUserPasswordForm } from 'components/Form/Account';
import { CompanyUsers } from 'components/CompanyUsers';
import { BlockHeading } from 'components/BlockHeading';
import { useTranslation } from 'react-i18next';

const AccountPage = ({ adminCompanyData }) => {
	const { t } = useTranslation('accountPage');

	return (
		<section className="section">
			<div className="section__in">
				<div className="auth_list">
					<div className="auth_block">
						<AccountCompanyForm adminCompanyData={adminCompanyData} />
					</div>
					{adminCompanyData
						? null
						: (
							<div className="auth_block">
								<div className="account_form">
									<BlockHeading
										title={t('userFormTitle')}
									/>
									<div className="account_form__in">
										<UpdateUserNameForm />
										<UpdateUserPasswordForm />
									</div>
								</div>
							</div>
						)}
					<div className="auth_block">
						<CompanyUsers adminCompanyData={adminCompanyData} />
					</div>
				</div>
			</div>
		</section>
	);
};

export default AccountPage;
