import React from 'react';

import { View, Text } from '@react-pdf/renderer';
import styles from './styles';

const PdfUploadedDocumentsList = ({
	documentsList,
}) => {
	return (
		<View>
			{documentsList && documentsList.map(({
				name,
				size,
			}, index) => {
				return (
					<View style={styles.uploadedDocListItem} key={index}>
						<View style={styles.uploadedDocBlock}>
							<Text style={styles.uploadedDocBlockSize}>{size}</Text>
							<Text style={styles.uploadedDocBlockName}>{name}</Text>
						</View>
					</View>
				);
			})}
		</View>
	);
};

export default PdfUploadedDocumentsList;
