import React, { useContext } from "react";

import { useTranslation } from "react-i18next";
import { BlockHeading } from "components/BlockHeading";
import { resetFilters } from "components/ThreeJs/assets/main";

import { AuthContext } from "context/auth";
import { useNavigate } from "react-router-dom";
import { clearAllBrowserUrlParameter } from "utils";
import usePopup from "./usePopup";

const ResetGlassPopup = ({ isOpen }) => {
	const { closePopup, popupFrame } = usePopup({
		isOpen, onPopupExit: clearAllBrowserUrlParameter, extraClassName: "reset_glass_popup",
	});
	const { t } = useTranslation("resetGlassForm");
	const { guestUser, setGuestUser } = useContext(AuthContext);
	const navigate = useNavigate();

	const resetGlass = () => {
		resetFilters();

		if (guestUser) {
			setGuestUser(null);
		}

		navigate("/");
		closePopup();
	};

	return popupFrame(
		<div className="popup_content">
			<div className="reset_glass">
				<BlockHeading title={t("title")} iconName="pencil" />
				<div className="popup_note">{t("text")}</div>
				<div className="reset_glass_btn	">
					<button type="button" className="btn_base" onClick={resetGlass}>
						{t("resetButton")}
					</button>
				</div>
			</div>
		</div>,
	);
};

export default ResetGlassPopup;
