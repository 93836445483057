import React from 'react';
import classNames from 'classnames';

const Spinner = ({
	darkTheme,
}) => {
	const spinnerClasses = classNames('spinner', { 'spinner--dark_theme': darkTheme });

	return (
		<div className={spinnerClasses}>
			<div className="spinner__in">
				<div className="spinner__border" role="status">
					<span className="sr-only">Loading...</span>
				</div>
			</div>
		</div>
	);
};

export default Spinner;
