import {
	StyleSheet,
} from '@react-pdf/renderer';

const styles = StyleSheet.create({
	configurationCodeIn: {
		// paddingVertical: 12,
		paddingTop: 16,
		paddingBottom: 8,
		paddingHorizontal: 30,
		backgroundColor: '#fff',
		// boxShadow: '0 10px 10px rgba(0, 0, 0, .02)',
	},
	configurationCodeHead: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		alignContent: 'center',
		marginBottom: 5,
	},
	configurationCodeTitle: {
		marginRight: 8,
		fontSize: 10,
		fontWeight: 700,
		textTransform: 'uppercase',
		color: '#575757',
	},
	configurationCodeValue: {
		fontSize: 10,
		fontWeight: 700,
		// textTransform: 'uppercase',
		color: '#5486a4',
	},
	configurationCodeDescr: {
		fontSize: 9,
		display: 'block',
	},
});

export default styles;
