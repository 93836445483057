export default class CameraMovementMode
{
  constructor()
  {

  }

  on_enter(camera_controller)
  {

  }

  on_exit(camera_controller)
  {

  }

  update(TIME, camera_controller)
  {

  }
}
