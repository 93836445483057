import FireResistantGlass from "./Glass/FireResistantGlass";
import GenericGlass from "./Glass/GenericGlass";

export default class GlassDBView {
  constructor(glassDb) {
    if (glassDb instanceof GlassDBView) {
      // clone
      this.db = glassDb.db;
      this.dbView = {
        frg: [...glassDb.dbView['frg']], frgFiltered: [...glassDb.dbView['frgFiltered']],
        mid: [...glassDb.dbView['mid']], midFiltered: [...glassDb.dbView['midFiltered']],
        out: [...glassDb.dbView['out']], outFiltered: [...glassDb.dbView['outFiltered']],
        uValue: {
          '1-glazed': [...glassDb.dbView['uValue']['1-glazed']],
          '2-glazed': [...glassDb.dbView['uValue']['2-glazed']],
          '3-glazed': [...glassDb.dbView['uValue']['3-glazed']],
        },
        uValueFiltered: {
          '1-glazed': [...glassDb.dbView['uValueFiltered']['1-glazed']],
          '2-glazed': [...glassDb.dbView['uValueFiltered']['2-glazed']],
          '3-glazed': [...glassDb.dbView['uValueFiltered']['3-glazed']],
        },
      };
      this.filters = {...glassDb.filters};

    } else {
      this.db = glassDb;
      this.dbView = {
        frg: [...glassDb.db['frg']], frgFiltered: [],
        mid: [...glassDb.db['mid']], midFiltered: [],
        out: [...glassDb.db['out']], outFiltered: [],
        uValue: {
          '1-glazed': [...glassDb.db['uValue']['1-glazed']],
          '2-glazed': [...glassDb.db['uValue']['2-glazed']],
          '3-glazed': [...glassDb.db['uValue']['3-glazed']],
        },
        uValueFiltered: {
          '1-glazed': [],
          '2-glazed': [],
          '3-glazed': [],
        },
      };
      this.filters = {}; // Hashmap of filter.filterName: filter.selectedFilter
    }
  }

  clone() {
    return new GlassDBView(this);
  }

  get frg() {
    return this.dbView['frg'];
  }

  get frgFiltered() {
    return this.dbView['frgFiltered'];
  }

  get mid() {
    return this.dbView['mid'];
  }

  get midFiltered() {
    return this.dbView['midFiltered'];
  }

  get out() {
    return this.dbView['out'];
  }

  get outFiltered() {
    return this.dbView['outFiltered'];
  }

  get uValue() {
    return this.dbView['uValue'];
  }

  get uValueFiltered() {
    return this.dbView['uValueFiltered'];
  }
}
