#define REF_WAVELENGTH 579.0
#define RED_WAVELENGTH 650.0
#define GREEN_WAVELENGTH 525.0
#define BLUE_WAVELENGTH 440.0
uniform vec2 resolution;
uniform sampler2D backNormals;
uniform sampler2D noise;
uniform sampler2D uLayersTexture;
uniform samplerCube envMap;
uniform float refractionIndex;
uniform vec3 color;
uniform float dispersion;
uniform float roughness;
uniform float opacity;
uniform float fesnel;
uniform float timer;

varying vec3 vWorldCameraDir;
varying vec3 vWorldNormal;
varying vec3 vViewNormal;
varying vec3 vLightViewDirection;
varying vec3 vViewPosition;
varying vec2 vUV;

vec4 refractLight(float wavelength, vec3 backFaceNormal, float noise) {
	float index = 1.0 / mix(refractionIndex, refractionIndex * REF_WAVELENGTH / wavelength, dispersion + noise * 0.2);
	vec3 dir = vWorldCameraDir;
	dir = refract(dir, vWorldNormal, index);
	dir = refract(dir, backFaceNormal, index);
	return textureCube(envMap, dir);
}
vec3 fresnelSchlick(float cosTheta, vec3 F0)
{
	return F0 + (1.0 - F0) * pow(1.0 + cosTheta, 5.0);
}
void main() {

	vec3 backFaceNormal = texture2D(backNormals, gl_FragCoord.xy / resolution).rgb;
	float noise = texture2D(noise, vec2(vUV.x * 2.0 , vUV.y) * 2.0  ).x;
	vec3 color3 = texture2D(uLayersTexture, vec2(vUV.x, vUV.y / (260.0 / 1024.0) ) ).rgb;

	//float noise = texture2D(noise, (gl_FragCoord.xy / resolution) * 0.5 ).x;
	backFaceNormal = backFaceNormal + noise * 0.01 - 0.01;

	float r = refractLight(RED_WAVELENGTH, backFaceNormal, noise).r;
	float g = refractLight(GREEN_WAVELENGTH, backFaceNormal, noise).g;
	float b = refractLight(BLUE_WAVELENGTH, backFaceNormal, noise).b;

	vec3 fresnel = fresnelSchlick(dot(vec3(0.0,0.0,-1.0), vViewNormal), vec3(fesnel));

	vec3 reflectedColor = textureCube(envMap, reflect(vWorldCameraDir, vWorldNormal)).rgb * clamp((1.0 - roughness) + fresnel, 0.0, 1.0);
	
	float diffuse = clamp(dot(vViewNormal, vLightViewDirection), 0.0, 1.0);

	vec3 view_dir = - normalize(vViewPosition);
	float specular = clamp(dot(normalize(view_dir + vLightViewDirection), vViewNormal), 0.0, 1.0);
	float blinn = pow(specular, 50.0);

	gl_FragColor.rgb = vec3(r,g,b) * color3 + reflectedColor * 0.3 + blinn * 0.4;
    gl_FragColor.a = opacity + noise * 0.05 + (1.0 - vUV.x) * 0.1 + blinn * 0.3 ;
    //gl_FragColor.a = gl_FragColor.a * opacity;

}