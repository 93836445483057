import React, { useContext, useRef } from 'react';

import { ReactComponent as UserAdminIcon } from 'i/icons/user_icon_admin.svg';
import { ReactComponent as CloseIcon } from 'i/icons/close.svg';
import { getFunctions, httpsCallable } from 'firebase/functions';
import ToastifyHandler from 'utils/ToastifyHandler';
import { useTranslation } from 'react-i18next';
import { STATUS_RESPONSE } from 'utils/constant';
import { UiContext } from 'context/ui';

const CompanyUsersBlock = ({
	email,
	itsMyself,
	getAllCompanyUsers,
}) => {
	const notificationsHandler = useRef(new ToastifyHandler());
	const { t } = useTranslation(['notifications', 'firebaseErrorMessages']);
	const { showPopupByKey } = useContext(UiContext);

	const deleteUser = async () => {
		const functions = getFunctions();
		const deleteUserFromCompany = httpsCallable(functions, 'deleteUserFromCompany');

		notificationsHandler.current.pending(t('pleaseWait'));

		try {
			const deleteUserRes = await deleteUserFromCompany({
				userEmail: email,
			});

			if (deleteUserRes.data === STATUS_RESPONSE.ok) {
				notificationsHandler.current.success(t('userHasBeenSuccessfullyRemovedFromTheCompany'));
			} else {
				notificationsHandler.current.rejected(t('anErrorHasOccurred'));
			}

			getAllCompanyUsers();
		} catch (error) {
			const { code } = error;
			notificationsHandler.current.rejected(t(code, { ns: 'firebaseErrorMessages' }));
		}
	};

	const handleClick = (e) => {
		e.preventDefault();
		e.stopPropagation();
		showPopupByKey('genericWarning', {
			title: t('confirmationOfUserDeletion'),
			confirmDialog: isConfirmed => isConfirmed && deleteUser(),
		});
	};

	return (
		<div className={`company_users__block${itsMyself ? ' company_users__myself' : ''}`}>
			<div className="company_users__block__icon">
				<UserAdminIcon className="icon icon-user_2 size_mod current_mod" />
			</div>
			<div className="company_users__block__field">{email}</div>
			{
				!itsMyself ? (
					<button className="company_users__block__button" type="button" onClick={handleClick}>
						<CloseIcon className="icon icon-close size_mod" />
					</button>
				)
					: null
			}
		</div>
	);
};

export default CompanyUsersBlock;
