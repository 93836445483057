
#define REF_WAVELENGTH 579.0
#define RED_WAVELENGTH 650.0
#define GREEN_WAVELENGTH 525.0
#define BLUE_WAVELENGTH 440.0
uniform vec2 resolution;
uniform sampler2D backNormals;
uniform sampler2D noise;
uniform samplerCube envMap;
uniform float refractionIndex;
uniform vec3 color;
uniform float dispersion;
uniform float roughness;
uniform float opacity;
uniform float fesnel;
uniform float timer;
uniform float glassThickness;
varying vec3 vWorldCameraDir;
varying vec3 vWorldNormal;
varying vec3 vViewNormal;
varying vec3 vLightViewDirection;
varying vec3 vViewPosition;
varying vec2 vUV;

vec4 refractLight(float wavelength, vec3 backFaceNormal) {
	float index = 1.0 / mix(refractionIndex, refractionIndex * REF_WAVELENGTH / wavelength, dispersion);
	vec3 dir = vWorldCameraDir;
	dir = refract(dir, vWorldNormal, index);
	dir = refract(dir, backFaceNormal, index);

	float absorptionCoefficient = 0.1;  // Adjust based on desired effect
	float absorptionFactor = absorptionCoefficient * glassThickness;
	vec4 refractedColor = textureCube(envMap, dir);
	vec4 attenuatedColor = refractedColor * exp(-absorptionFactor);
	return attenuatedColor;
}
vec3 fresnelSchlick(float cosTheta, vec3 F0)
{
	return F0 + (1.0 - F0) * pow(1.0 + cosTheta, 5.0);
}
void main() {

	vec3 backFaceNormal = texture2D(backNormals, gl_FragCoord.xy / resolution).rgb;
	float noise = texture2D(noise, vUV.xy * 10.0 ).x;
	//float noise = texture2D(noise, (gl_FragCoord.xy / resolution) * 0.05 ).x;
	backFaceNormal = backFaceNormal + noise * 0.01 - 0.01;

	float r = refractLight(RED_WAVELENGTH, backFaceNormal).r;
	float g = refractLight(GREEN_WAVELENGTH, backFaceNormal).g;
	float b = refractLight(BLUE_WAVELENGTH, backFaceNormal).b;

	vec3 fresnel = fresnelSchlick(dot(vec3(0.0,0.0,-1.0), vViewNormal), vec3(fesnel));

	vec3 reflectedColor = textureCube(envMap, reflect(vWorldCameraDir, vWorldNormal)).rgb * clamp((1.0 - roughness) + fresnel, 0.0, 1.0);

	// float specular = vViewNormal * vLightViewDirection
	float diffuse = clamp(dot(vViewNormal, vLightViewDirection), 0.0, 1.0);

	vec3 view_dir = - normalize(vViewPosition);
	float specular = clamp(dot(normalize(view_dir + vLightViewDirection), vViewNormal), 0.0, 1.0);
	float blinn = pow(specular, 10.0);

	gl_FragColor.rgb = vec3(r,g,b) * color + reflectedColor;
	//gl_FragColor.rgb = vec3(vUV.xy, 0.0);
	//gl_FragColor.rgb = vec3(noise);
    //gl_FragColor.a = opacity;
    gl_FragColor.a = opacity + noise * 0.01 + (1.0 - vUV.x) * 0.1 + blinn * 0.7;
    //gl_FragColor.a = opacity * gl_FragColor.a;

}
