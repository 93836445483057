import GenericStructureElement from './GenericStructureElement';

import SpacerSegment from './SpacerSegment';
import SpacerCorner from './SpacerCorner';

export default class Spacer extends GenericStructureElement {

  constructor(rt) {
    super();

    this.depth = 1;

    const length = 2, thickness = 0.1;
    const length_horizontal = length - thickness;
    // in decimeters
    const alu_thickness = 0.01;
    // const alu_vertical = 0.04 - alu_thickness;
    // const alu_sides = 0.0192;
    // const alu_variable = 1.0;


    this.corner_segment = new SpacerCorner();
    this.add(this.corner_segment);

    this.bottom_segment = new SpacerSegment(length - thickness);
    this.bottom_segment.position.x = thickness;
    this.add(this.bottom_segment);

    this.top_segment = new SpacerSegment(length - thickness);
    this.top_segment.rotation.z = -Math.PI * 0.5;
    this.top_segment.rotation.x = Math.PI;
    this.top_segment.position.y = thickness;
    this.top_segment.position.z = this.depth * 0.5;
    this.add(this.top_segment);

    //this.top_segment.receiveShadow = true;
    //this.top_segment.castShadow = true;

    //this.bottom_segment.receiveShadow = true;
    //this.bottom_segment.castShadow = true;
    this.set(0.0001);

  }

  set(width) {
    width *= .01;
    this.depth = width;

    if (width < 0.01) {
      this.visible = false;
    }
    else {
      //let ratio =  (((this.depth * 0.9 )) * width) * 50   0.05 / ;
      this.visible = true;
      this.bottom_segment.set(width * 100);
      this.top_segment.set(width * 100);
      this.corner_segment.set(width * 100);
      this.top_segment.position.z = this.depth;
    }
  }

  get width() {
    return this.depth;
  }

  update() {
    //this.alu_material.update()
  }
}
