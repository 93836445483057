import React, {
	forwardRef,
	useContext, useEffect, useImperativeHandle, useRef, useState,
} from 'react';
import { FormCheckbox } from 'components/Form';
import classNames from 'classnames';
import { ConfigurationDataContext } from 'context/configuration';
import { checkIsFieldError } from 'domain/glass';
import { formatNumberToFixed } from 'utils';
import GlassConfigTableInput from '../GlassConfigTableInput';
import { POSITION_INCREASE } from './constant';

const GlassConfigTableRowCell = forwardRef((props, ref) => {
	const {
		cellRefs,
		cellData,
		cellIndex,
		lastPositionIndex,
		rowIndex,
		positionAction,
		formElementIdRef,
		isInactive,
		handleRemoveInactive,
		handleInputBlur,
		isFocus,
		handleRemoveFocus,
		rowId,
		setActiveRowIndex,
	} = props;
	const { errorFields, showErrors } = useContext(ConfigurationDataContext);

	const { name, value } = cellData;
	const [isError, setIsError] = useState(false);
	const [isInputOpened, setInputOpened] = useState(false);
	const [inputValueState, setInputValueState] = useState(value);
	const [storedInputValueState, setStoredInputValueState] = useState(value);
	const [moveOn, setMoveOn] = useState(false);
	const inputRef = useRef(null);

	const glassConfigTableCellClasses = classNames('glass_config_table__cell');

	const hideInput = () => {
		setInputOpened(false);
		inputRef.current.blur();
	};

	const showInput = () => {
		setMoveOn(false);
		setInputOpened(true);
		if (isInactive && handleRemoveInactive) {
			handleRemoveInactive();
		}
	};

	useImperativeHandle(ref, () => ({
		showInput,
	}));

	useEffect(() => {
		if (value !== storedInputValueState) {
			let trans = cellData.transact();
			trans.value = storedInputValueState;
			cellData.run();
		}
	}, [cellData, value, storedInputValueState]);

	const setInputValue = e => {
		const { value: inputValue } = e.target;

		if (inputValue !== value) {
			setStoredInputValueState(inputValue);
		}

		hideInput();
	};

	const handleInputKeyDown = e => {
		if (e.which === 13 /* enter */ || e.which === 9 /* tab */) {
			e.preventDefault();
			setInputValue(e);
			if (e.shiftKey) {
				if (cellIndex > 0) {
					cellRefs.current[cellIndex - 1].current.showInput();
				} else if (rowIndex > 0) {
					setActiveRowIndex(rowIndex - 1);
				}
			} else {
				// not handling overflow since the last cell is always a GlassConfigTableRowNotesCell
				cellRefs.current[cellIndex + 1].current.showInput();
			}
			setMoveOn(true);
		}
	};

	const handleCheckboxKeyDown = e => {
		if (e.which === 9 /* tab */) {
			e.preventDefault();
			// not handling cellIndex > 0 since checkboxes are never in first place
			cellRefs.current[cellIndex + (e.shiftKey ? -1 : 1)].current.showInput();
		}
	};

	const onInputChangeHandler = e => {
		const { value: inputValue } = e.target;

		let num = inputValue.replace(/\D/g, '');
		if (name === 'cantonalInsurance') {
			num = num.slice(0, 6);
		}
		setInputValueState(num);
	};

	const onBlurHandler = e => {
		setInputValue(e);

		let ev = (e?.target?.value.length > 0);
		if (isError && ev) {
			setIsError(false);
		}

		if (handleInputBlur && ev) {
			handleInputBlur();
		}
	};

	const onChangeCheckboxHandler = e => {
		const { checked } = e.target;
		const preparedValue = checked ? 'checked' : 'unchecked';

		if (preparedValue !== value) {
			let trans = cellData.transact();
			trans.value = preparedValue;
			cellData.run();
			setStoredInputValueState(preparedValue);
		}
	};

	const onFocusHandler = () => {
		showInput();
	};

	useEffect(() => {
		if (isFocus && handleRemoveFocus && cellIndex === 0) {
			if (!moveOn) {
				showInput();
			}
			handleRemoveFocus();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isFocus]);

	useEffect(() => {
		if (isInputOpened && inputRef.current) {
			inputRef.current.select();
		}
	}, [isInputOpened]);

	useEffect(() => {
		if (lastPositionIndex !== null
			&& lastPositionIndex >= 0
			&& lastPositionIndex === rowIndex
			&& cellIndex === 0
			&& positionAction === POSITION_INCREASE
			&& !isInactive) {
			showInput();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [lastPositionIndex, cellIndex, rowIndex, isInactive, positionAction]);

	useEffect(() => {
		if (errorFields && rowId && name) {
			setIsError(checkIsFieldError(errorFields, rowId, name) && showErrors);
		}
	}, [errorFields, rowId, name, showErrors]);

	return (
		<div className={glassConfigTableCellClasses}>
			{name === 'form' ? (
				<FormCheckbox
					ref={inputRef}
					id={formElementIdRef.current}
					onChange={onChangeCheckboxHandler}
					onKeyDown={handleCheckboxKeyDown}
					defaultChecked={value === 'checked'}
					noLabel
				/>
			) : (
				<div className="glass_config_table__cell_value">
					{!isInputOpened ? (
						<button
							className={classNames("glass_config_table__cell_value_in", {
								"glass_config_table__cell_value_in--invalid_state": isError,
							})}
							onClick={showInput}
							onFocus={onFocusHandler}
							type="button"
						>
							{value === ''
								? '-'
								: (name === 'cantonalInsurance' ? value : formatNumberToFixed(value, 0))}
						</button>
					) : (
						<GlassConfigTableInput
							ref={inputRef}
							id={formElementIdRef.current}
							name={formElementIdRef.current}
							value={inputValueState}
							onBlur={onBlurHandler}
							onChange={onInputChangeHandler}
							onKeyDown={handleInputKeyDown}
							type="number"
							min="1"
						/>
					)}
				</div>
			)}
		</div>
	);
});

export default GlassConfigTableRowCell;
