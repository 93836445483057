import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as PlusIcon } from "i/icons/plus.svg";
import { useTranslation } from "react-i18next";

import { generateUniqueId } from "utils";
import { DEFAULT_GLASS_SIZES, replaceZerosWithDash } from 'domain/glass';
import GlassConfigTableRow from "./GlassConfigTableRow";
import GlassConfigTableHeadingRow from "../GlassConfigTableHeadingRow";
import GlassConfigTotal from './GlassConfigTotal';
import { POSITION_DECREASE, POSITION_INCREASE } from "./constant";

const GlassConfigTable = (
	{
		parent, totalValues, glassIndex, showPrice,
	},
) => {
	const { t } = useTranslation("commonAppValues");
	const [total, setTotal] = useState(DEFAULT_GLASS_SIZES);
	const [lastPositionIndex, setLastPositionIndex] = useState(null);
	const lastPositionIndexRef = useRef(null);
	const parentRef = useRef(parent);
	const [positionAction, setPositionAction] = useState();
	const [activeRowIndex, setActiveRowIndex] = useState(null);
	const [parentInvalidated, setParentInvalidated] = useState(false);

	const increasePositionIndex = () => {
		const updatedPositionIndex = lastPositionIndexRef.current === null
			? parent.positions.length
			: lastPositionIndexRef.current + 1;

		setPositionAction(POSITION_INCREASE);
		setLastPositionIndex(updatedPositionIndex);
	};

	const decreasePositionIndex = () => {
		const updatedPositionIndex = lastPositionIndexRef.current === null
			? parent.positions.length - 2
			: lastPositionIndexRef.current - 1;
		const updatedLastPositionIndex = updatedPositionIndex === 0 ? 0 : updatedPositionIndex;

		setPositionAction(POSITION_DECREASE);
		setLastPositionIndex(updatedLastPositionIndex);
	};

	const addNewPosition = ({ inactive = false }) => {
		const trans = parent.transact();

		trans.positions.push({
			id: generateUniqueId(),
			inactive,
			row: [
				{
					name: "length",
					value: "",
				},
				{
					name: "width",
					value: "",
				},
				{
					name: "quantity",
					value: "",
				},
				{
					name: "form",
					value: "unchecked",
				},
				{
					name: "cantonalInsurance",
					value: "",
				},
				{
					name: "notes",
					value: "",
				},
			],
		});

		parent.run();
		setParentInvalidated(true);
		increasePositionIndex();
	};

	const handleAddInactivePosition = () => {
		addNewPosition({ inactive: true });
	};

	const handleAddNewPosition = () => {
		const { positions } = parent;

		if (positions && positions.length > 0) {
			const lastItem = positions[positions.length - 1].transact();

			if (lastItem.inactive) {
				lastItem.inactive = false;
				lastItem.focus = true;
				handleAddInactivePosition();
				return;
			}
		}

		addNewPosition({ inactive: false });
	};

	useEffect(() => {
		lastPositionIndexRef.current = lastPositionIndex;
	}, [lastPositionIndex]);

	useEffect(() => {
		parentRef.current = parent;
		setParentInvalidated(false);
	}, [parent]);

	useEffect(() => {
		if (totalValues?.length) {
			const totalSum = totalValues.reduce((acc, {
				quantity, m2, linearMeterPos, kgPos, price,
			}) => {
				// totals for this glass type
				return {
					quantity: acc.quantity + quantity,
					m2: acc.m2 + m2,
					linearMeter: acc.linearMeter + linearMeterPos,
					kg: acc.kg + kgPos,
					price: (price !== null ? acc.price + price : null),
				};
			}, {
				quantity: 0, m2: 0, linearMeter: 0, kg: 0, price: 0,
			});

			setTotal(replaceZerosWithDash(totalSum));
		} else {
			setTotal(DEFAULT_GLASS_SIZES);
		}
	}, [totalValues]);

	useEffect(() => {
		if (parentInvalidated || activeRowIndex === null) {
			return;
		}
		if (activeRowIndex < 0) {
			setActiveRowIndex(0);
			return;
		} else if (activeRowIndex > lastPositionIndex) {
			handleAddNewPosition();
			return;
		}
		parent.positions[activeRowIndex].set('focus', true);
		setActiveRowIndex(null);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeRowIndex, lastPositionIndex, parent, parentInvalidated]);

	return (
		<div className="glass_config_table">
			{parent.positions && parent.positions.length ? (
				<>
					<ul className="glass_config_table__list offset_mod">
						<GlassConfigTableHeadingRow />
						{parent.positions.map((positionRow, rowIndex) => {
							const positionIndex = `${glassIndex}.${rowIndex + 1}`;
							const isLastItem = rowIndex === parent.positions.length - 1;
							const matchingObject = totalValues?.find((item) => item.index === rowIndex);

							return (
								<GlassConfigTableRow
									key={positionRow.id}
									rowId={positionRow.id}
									parent={parent.positions}
									value={positionRow}
									attrKey={rowIndex}
									rowIndex={rowIndex}
									positionIndex={positionIndex}
									lastPositionIndex={lastPositionIndex}
									decreasePositionIndex={decreasePositionIndex}
									positionAction={positionAction}
									totalValues={matchingObject}
									handleInputBlur={isLastItem && handleAddInactivePosition}
									showPrice={showPrice}
									setActiveRowIndex={setActiveRowIndex}
								/>
							);
						})}
					</ul>
					<GlassConfigTotal {...total} showPrice={showPrice} />
				</>
			) : null}
			<div className="glass_config_add_position">
				<button
					className="glass_config_add_position__btn"
					type="button"
					onClick={handleAddNewPosition}
				>
					<div className="glass_config_add_position__icon">
						<PlusIcon className="icon icon-plus size_mod" />
					</div>
					<div className="glass_config_add_position__title">
						{t("addPosition")}
					</div>
				</button>
			</div>
		</div>
	);
};

export default GlassConfigTable;
