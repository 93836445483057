import React, {
	useContext, useEffect, useRef,
} from 'react';
import { UiContext } from 'context/ui';
import { ReactComponent as CloseIcon } from 'i/icons/close.svg';
import { CSSTransition } from 'react-transition-group';
import classNames from 'classnames';

const usePopup = ({
	isOpen,
	onPopupExit,
	extraClassName,
}) => {
	const $popupRef = useRef(null);
	const { showPopupByKey, popupData } = useContext(UiContext);
	const ignoreEventState = useRef(true);

	const closePopup = () => {
		showPopupByKey();
	};

	const handleClickOutside = (event) => {
		const { target } = event;
		if (isOpen
			&& $popupRef.current
			&& !$popupRef.current.firstChild.contains(target)
			// fix a case where selecting an option from <Select> would close the popup bc
			// the option is not part of the document by the time the event reaches this handler
			&& document.contains(target)
		) {
			event.stopPropagation();
			showPopupByKey();
		}
	};

	useEffect(() => {
		ignoreEventState.current = false;
	}, []);

	// eslint-disable-next-line no-underscore-dangle
	const _onPopupEntering = () => {
		document.body.classList.add('filter_mod');
		if (!ignoreEventState.current) {
			document.addEventListener('click', handleClickOutside);
		}
	};

	// eslint-disable-next-line no-underscore-dangle
	const _onPopupExit = () => {
		document.body.classList.remove('filter_mod');
		document.removeEventListener('click', handleClickOutside);
		if (onPopupExit) onPopupExit();
	};

	const popupFrame = (children) => {
		return (
			<CSSTransition
				in={isOpen}
				timeout={{
					enter: 500,
					exit: 350,
				}}
				classNames="popup"
				onEntering={_onPopupEntering}
				onExit={_onPopupExit}
				mountOnEnter
				nodeRef={$popupRef}
				unmountOnExit
			>
				<div ref={$popupRef} className="popup">
					<div className={classNames('popup_in', extraClassName)}>
						<button className="popup_close popupClose" type="button" onClick={closePopup}>
							<CloseIcon className="icon icon-close size_mod" />
						</button>
						{children}
					</div>
				</div>
			</CSSTransition>
		);
	};

	return { closePopup, popupFrame, popupData };
};

export default usePopup;
