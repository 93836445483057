import React from 'react';

import { useTranslation } from 'react-i18next';
import { BlockHeading } from 'components/BlockHeading';
import { useNavigate } from 'react-router-dom';
import { CONFIGURATION_TYPE } from 'utils/constant';

const OrderConfirmationSection = ({ orderType, email }) => {
	const { t } = useTranslation('orderConfirmationPage');
	const navigate = useNavigate();

	const handleClick = () => {
		navigate("/");
	};

	return (
		<section className="section order_confirmation">
			<div className="section__in">
				<BlockHeading
					title={t(orderType === CONFIGURATION_TYPE.order ? 'orderConfirmationTitle' : 'quoteConfirmationTitle')}
					iconName="orders"
				/>
				<div className="order_confirmation__message">
					<p>
						{t(orderType === CONFIGURATION_TYPE.order ? "notifications:orderSuccessful" : "notifications:quoteSuccessful")}
					</p>
					<br />
					<p>
						{t('emailConfirmationSentToFmt').replace('{email}', email)}
					</p>
				</div>

				<button
					type="button"
					className="btn_v3 back_to_start_btn"
					onClick={() => handleClick()}
				>
					{t('backToStart')}
				</button>
			</div>
		</section>
	);
};

export default OrderConfirmationSection;
