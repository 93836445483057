import { MeshPhysicalMaterial } from 'three';
import { ResourceContainer } from 'ohzi-core';

export default class AluminiumTapeMaterial extends MeshPhysicalMaterial {
  constructor() {
    super({
      color: 0xa2a2a2,
      envMap: ResourceContainer.get_resource('cube-blur'),
      map: ResourceContainer.get_resource('alu-diffuse'),
      clearcoatNormalMap: ResourceContainer.get_resource('alu-normal'),
      roughnessMap: ResourceContainer.get_resource('alu-specular'),
      refractionRatio: 0.22,
      roughness: 0.8,
      metalness: 0.6,
      reflectivity: 0.1,
      clearcoat: 0.85,
      clearcoatRoughness: 0.8,
    });
  }

  update() {
    //this.color.setHex(DatGui.settings.color);
    //this.refractionRatio = DatGui.settings.refractionRatio;
    //this.roughness = DatGui.settings.roughness;
    //this.metalness = DatGui.settings.metalness;
    //this.reflectivity = DatGui.settings.reflectivity;
    //this.clearcoat = DatGui.settings.clearcoat;
    //this.clearcoatRoughness = DatGui.settings.clearcoatRoughness;
    //this.needsUpdate = true;
  }
}
