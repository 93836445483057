uniform sampler2D difuseTexture;
uniform samplerCube envMap;
uniform float alphaExponent;
varying vec2 vUV;

void main() {
	vec3 texture_color = texture2D(difuseTexture, vUV * 2.0).rgb;

	gl_FragColor.rgb = texture_color;
	//gl_FragColor.rgb = vec3(1.0, 0.0, 0.0);
	//gl_FragColor.a = 0.95;
	// The brighter a color, the less transparent it becomes. Anything above 2.5 is opaque (~0xb0b0b0)
	gl_FragColor.a = pow((gl_FragColor.r + gl_FragColor.g + gl_FragColor.b) * 0.4, alphaExponent);
}