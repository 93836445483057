export default class SoundDampening {
    static genericFRGs = {} // loaded by configuratorDate.sound_dampening.generic_frgs
    static soundDampeningTable = {
       loaded: false, // loaded by configuratorData.sound_dampening.sound_dampening_table  loaded: false
    }

    static getCanonicalSoundValueString(val) {
        // Canonical values are ASCII-sortable (last is best, first is guaranteed minimum)
        return `Rw${val.Rw}C${val.C === 0 ? '-0' : val.C}Ctr${val.Ctr === 0 ? '-0' : val.Ctr}`;
    }

    static displayFromCanonical(canonical) {
        if (canonical === 'NPD') {
            return { displayVal: 'NPD', orBetter: false };
        }
        const regex = /Rw(\d+)C(-?\d+)Ctr(-?\d+)(OrBetter)?/;
        const match = canonical.match(regex);
        if (match) {
            const rw = match[1];
            const c = match[2] === '-0' ? '0' : match[2];
            const ctr = match[3] === '-0' ? '0' : match[3];
            const orBetter = match[4] === 'OrBetter';
            return {
                displayVal: `${rw} (${c}; ${ctr})`,
                orBetter,
            };
        } else {
            console.error(`Invalid canonical sound value ${canonical}`);
        }
    }

    static getAllUniqueValues() {
        let unique_vals = new Set();
        SoundDampening.soundDampeningTable['1-glazed'].map(val => unique_vals.add(this.getCanonicalSoundValueString(val)));
        SoundDampening.soundDampeningTable['2-glazed'].map(val => unique_vals.add(this.getCanonicalSoundValueString(val)));
        SoundDampening.soundDampeningTable['3-glazed'].map(val => unique_vals.add(this.getCanonicalSoundValueString(val)));
        return [...unique_vals].sort(/*(a, b) => a - b*/);
    }

    static getCanonicalSoundValue(glassStructureObj) {
        const frgId = parseInt(glassStructureObj.fireResistantGlass.id, 10)
        const genId = SoundDampening.genericFRGs[frgId];
        const struct = glassStructureObj.getGlassStructure();
        if (genId) {
            const vals = genId.map(possibleFrgId => this._getCanonicalSoundValue(glassStructureObj, struct, possibleFrgId));
            const uniqueVals = new Set(vals);
            const sortedVals = [...uniqueVals].filter(o => o !== 'NPD').sort();
            if (sortedVals.length === 0) return 'NPD';
            if (sortedVals.length === 1) return sortedVals[0];
            return `${sortedVals[0]}OrBetter`;
        }
        return this._getCanonicalSoundValue(glassStructureObj, struct, frgId);
    }

    static _getCanonicalSoundValue(glassStructureObj, struct, frgId) {
        let dampening = SoundDampening.soundDampeningTable[struct]?.filter(val => {
            if (!val.glassIdsFRG.includes(frgId)) {
                return false;
            }
            if (struct === '1-glazed') {
                return true;
            }
            if (!(glassStructureObj.spacerOutside.thickness == val.spacer1 && val.glassIdsOut.includes(parseInt(glassStructureObj.outerGlass.id, 10)))) {
                return false;
            }
            if (struct === '2-glazed') {
                return true;
            }
            return (glassStructureObj.spacerMiddle.thickness == val.spacer2 && val.glassIdsMid.includes(parseInt(glassStructureObj.middleGlass.id, 10)));
        });
        if (!dampening || dampening.length === 0) {
            return 'NPD';
        }
        return this.getCanonicalSoundValueString(dampening[0]);
    }
}
