import React from 'react';
import { useTranslation } from 'react-i18next';
import {
	View,
} from '@react-pdf/renderer';
import UploadedDocumentsList from './PdfUploadedDocumentsList';
import { PdfBlockHeading } from '../PdfBlockHeading';

const PdfUploadedDocuments = ({
	documentsList,
}) => {
	const { t } = useTranslation('cartPage');

	return (
		<View>
			<PdfBlockHeading
				title={t('uploadedDocuments')}
				iconName="document"
			/>
			{documentsList && documentsList.length ? (
				<UploadedDocumentsList
					documentsList={documentsList}
				/>
			) : null}
		</View>
	);
};

export default PdfUploadedDocuments;
