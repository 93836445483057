uniform vec2 uSize;
uniform sampler2D uMap;
uniform samplerCube envMap;
varying vec3 vWorldNormal;
varying vec3 vWorldCameraDir;
varying vec3 vViewNormal;
varying vec2 vUV;


void main() {
  	vec4 worldPosition = modelMatrix * vec4( position, 1.0);
  	vWorldNormal = (modelMatrix * vec4(normal, 0.0)).xyz;
  	vWorldNormal = normalize(vec3(-vWorldNormal.x, vWorldNormal.yz));
  	vWorldCameraDir = worldPosition.xyz - cameraPosition;
    vWorldCameraDir = normalize(vec3(-vWorldCameraDir.x, vWorldCameraDir.yz));
	vViewNormal = normalize( modelViewMatrix * vec4(normal, 0.0)).xyz;
  	gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
  	vUV = uv * uSize;
}