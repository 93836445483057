import { BaseShaderMaterial } from 'ohzi-core';
import fragment from '../shaders/InsulationShader.frag'
import vertex from '../shaders/InsulationShader.vert'
import { ResourceContainer } from 'ohzi-core';

import { Vector2 } from 'three';

export default class InsulationMaterial extends BaseShaderMaterial
{
  constructor(rt)
  {
    super(vertex, fragment, {
      uSize: { value :
        new Vector2(1, 1)}
      ,
      uMap: {value : ResourceContainer.get_resource("styrofoam-diffuse3")},
      envMap: {value : ResourceContainer.get_resource("cube")},
     });
    this.transparent = true;
    this.needsUpdate = true;  }

  update() {
  }
   
  set_size(width, height) {
    this.uniforms.uSize.value.set(width, height);
    this.needsUpdate = true;
  }
};
