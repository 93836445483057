/* eslint-disable */
import React, { createRef, useContext, useRef, useState } from 'react';
import { AppDataContext } from 'context/appData';
import { Transition } from 'react-transition-group';
import { useLocation } from 'react-router-dom';
import { UiContext } from 'context/ui';
import { gsap } from 'gsap';
import MorphSVGPlugin from 'gsap/dist/MorphSVGPlugin';

import { MENU_SVG_FINISH_PATH } from './constant';
import MainMenuOpeningDecor from './MainMenuOpeningDecor';
import MainMenuClosingDecor from './MainMenuClosingDecor';
import { MENU_CLOSED_STATE, MENU_OPEN_STATE, TABLET_MEDIA_POINT } from 'utils/constant';
import MainMenuLangList from './MainMenuLangList';
import { SideLogoList } from 'components/SideLogoList';
import { Crisp } from 'crisp-sdk-web';
import { VideoBlock } from 'components/VideoBlock';

gsap.registerPlugin(MorphSVGPlugin);

const MainMenu = () => {
	const { mainMenuData, langApp, themeSettings } = useContext(AppDataContext);

	const {
		isMainMenuOpen,
		setMainMenuOpen,
		setMainMenuState,
		setMainMenuAnimationActive,
		actionBannerAnimTl,
		signUpBannerAnimTl,
		faqBlockAnimTl,
		windowWidth,
	} = useContext(UiContext);
	const mainMenuItems = useRef([]);
	const isWeGoingToNextPage = useRef(false);
	const location = useLocation();
	const [isAnimationComplete, setAnimationComplete] = useState(false);

	const $menuBgOpeningPath = useRef();
	const $menuBgClosingPath = useRef();
	const $mainMenuInfoLink = useRef();
	const $mainMenuLangList = useRef();
	const $sideLogoListEl = useRef();
	const $videoBlockEl = createRef();

	const handleOnClick = url => {
		isWeGoingToNextPage.current = true;
		setMainMenuOpen(false);
		window.location.replace(url);
	};

	const onEntering = () => {
		const tlAnim = gsap.timeline();
		setMainMenuAnimationActive(true);

		Crisp.chat.hide();

		document.body.classList.add('menu_open_mod');

		tlAnim
			.addLabel('anim')
			.to($menuBgOpeningPath.current, {
				morphSVG: MENU_SVG_FINISH_PATH,
				duration: 1,
				ease: 'power2.out',
			}, 'anim')
			.to(document.documentElement, {
				'--global_el_color': '#000',
			}, 'anim+=.3')
			// .set(document.body, {
			// 	className: '+=menu_open_mod',
			// }, 'anim+=.3')
			.fromTo(
				mainMenuItems.current,
				{
					opacity: 0,
					x: '-20vw',
				},
				{
					x: 0,
					opacity: 1,
					stagger: .15,
					ease: 'power1.out',
					duration: .7,
				}, 'anim+=.5')
			.fromTo($mainMenuInfoLink.current, {
				x: 50,
				opacity: 0,
			}, {
				x: 0,
				opacity: 1,
				onComplete: () => {
					setMainMenuState(MENU_OPEN_STATE);
					setMainMenuAnimationActive(false);

					setTimeout(() => {
						setAnimationComplete(true);
					}, 600);
				}
			}, 'anim+=.5')

		if (windowWidth < TABLET_MEDIA_POINT) {
			if ($mainMenuLangList.current) {
				tlAnim
					.fromTo($mainMenuLangList.current, {
						opacity: 0,
					}, {
						opacity: 1,
					}, 'anim+=.5');
			}
			if ($sideLogoListEl.current) {
				tlAnim
					.fromTo($sideLogoListEl.current, {
						opacity: 0,
					}, {
						opacity: 1,
					}, 'anim+=.5');
			}
		}
	};

	const onExit = node => {
		const tlAnim = gsap.timeline({
			onComplete: () => {
				isWeGoingToNextPage.current = false;

				// DELAY FOR MENU TRIGGER ANIMATION
				gsap.delayedCall(1, () => {
					setMainMenuState(MENU_CLOSED_STATE);
					setMainMenuAnimationActive(false);
					setAnimationComplete(false);
				});
			},
		});

		setMainMenuAnimationActive(true);
		Crisp.chat.show();

		document.body.classList.remove('menu_open_mod');

		tlAnim
			.addLabel('anim')
			.to(document.documentElement, {
				'--global_el_color': '#fff',
			}, 'anim+=.3')
		// .set(document.body, {
		// 	className: '',
		// }, 'anim+=.3')

		if (isWeGoingToNextPage.current) {
			actionBannerAnimTl.seek('onload_anim_start');
			signUpBannerAnimTl.seek('onload_anim_start');
			faqBlockAnimTl.seek('onload_anim_start');

			tlAnim
				.set('.menu_bg_w_svg.closing_mod', {
					opacity: 1,
				}, 'anim')
				.to($menuBgClosingPath.current, {
					morphSVG: MENU_SVG_FINISH_PATH,
					duration: 1,
					ease: 'power2.out',
				}, 'anim')
				.fromTo($videoBlockEl.current, {
					opacity: 1,
				}, {
					opacity: 0,
				}, 'anim')
		} else {
			tlAnim
				.fromTo(node, {
					opacity: 1,
				}, {
					opacity: 0,
				}, 'anim')
		}
	};

	const decors = (
		<div className="menu_bg">
			<div className="menu_bg_in" />
		</div>
	);

	if (!mainMenuData) {
		return decors;
	}
	return (
		<>
			<Transition
				in={isMainMenuOpen}
				timeout={1200}
				onExit={onExit}
				onEntering={onEntering}
				mountOnEnter
				unmountOnExit
			>
				<div className="main_menu">
					{/* fix: add to mobile <MainMenuLangList /> */}
					{windowWidth < TABLET_MEDIA_POINT ? (
						<MainMenuLangList ref={$mainMenuLangList} />
					) : null}
					{themeSettings && themeSettings.main_menu_videos && isAnimationComplete ? (
						<VideoBlock
							videos={themeSettings.main_menu_videos}
							ref={$videoBlockEl}
						/>
					) : null}
					<MainMenuOpeningDecor ref={$menuBgOpeningPath} />
					<nav className="main_menu_list_w">
						<ul className="main_menu_list">
							{Object.keys(mainMenuData).map((key, index) => {
								const { item_title: linkTitle, item_url: linkUrl } = mainMenuData[key];

								return (
									<li className={`main_menu_item ${linkUrl === location.pathname ? 'active_mod' : ''}`} key={index}>
										<div
											className="main_menu_item_in"
											ref={ref => { mainMenuItems.current[index] = ref; }}
										>
											{linkTitle && linkUrl && (
												<button
													className="main_menu_link"
													type="button"
													onClick={() => handleOnClick(process.env.REACT_APP_MAIN_WEBSITE + linkUrl)}
												>
													{linkTitle[langApp]}
												</button>
											)}
										</div>
									</li>
								);
							})}
						</ul>
					</nav>
					<div className="main_menu_info_link" ref={$mainMenuInfoLink}>EHC Biel</div>
					{windowWidth < TABLET_MEDIA_POINT ? (
						<SideLogoList ref={$sideLogoListEl} />
					) : null}
					<MainMenuClosingDecor
						ref={$menuBgClosingPath}
					/>
				</div>
			</Transition>
			{decors}
		</>
	);
};

export default MainMenu;
