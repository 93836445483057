import React from 'react';

import { ReactComponent as PrevIcon } from 'i/icons/prev.svg';
import { ReactComponent as NextIcon } from 'i/icons/next.svg';

const GlassProductSliderControl = ({
	type,
	currentSlide,
	slideCount,
	...props
}) => (
	<button
		{...props}
		className={`glass_product_control ${type === 'next' ? 'next_mod' : 'prev_mod'} 
		${type === 'next' && currentSlide === slideCount - 1 ? 'slick-disabled' : ''} 
		${type === 'prev' && currentSlide === 0 ? 'slick-disabled' : ''}`}
		type="button"
	>
		<div className="glass_product_control_icon">
			{type === 'next' && <NextIcon className="icon icon-next size_mod" />}
			{type === 'prev' && <PrevIcon className="icon icon-next size_mod" />}
		</div>
	</button>
);

export default GlassProductSliderControl;
