/* eslint-disable indent */
export const TRANSLATION_FR = {
  i18n: {
    language: "Langue",
    languageName: "Français",
  },
  faq: {
    instruction: "Instructions d'utilisation",
  },
  visualizerComponent: {
    measurementArrowsLabelFormat: "EP {}", // Épaisseur
  },
  highlightComponent: {
    next: "Continuer",
  },
  baseFormPlaceholders: {
    userEmailPlaceholder: "Email",
    userPasswordPlaceholder: "Mot de passe",
    repeatPasswordPlaceholder: "Répéter le mot de passe",
    newPasswordPlaceholder: "Nouveau mot de passe",
    repeatNewPasswordPlaceholder: "Répéter le nouveau mot de passe",
    userGenderPlaceholder: "Genre",
    userCompanyPlaceholder: "Entreprise",
    userCompanyVATNumberPlaceholder: "Numéro de TVA suisse",
    userFirstNamePlaceholder: "Prénom",
    userLastNamePlaceholder: "Nom de famille",
    userFunctionPlaceholder: "Fonction",
    userCompanyEmailPlaceholder: "Email",
    userStreetPlaceholder: "Rue et numéro",
    userZIPPlaceholder: "Code Postal",
    userCityPlaceholder: "Ville",
    userPhonePlaceholder: "Téléphone",
    searchField: "Recherche",
    userGenderMale: "Homme",
    userGenderFemale: "Femme",
    userGenderDiv: "Divers",
    userFunctionOwner: "Propriétaire",
    userFunctionCoOwner: "Co-propriétaire",
    userFunctionManagement: "Gestion",
    userFunctionExecutiveManagement: "Direction exécutive",
    userFunctionPurchasingProcurement: "Achats / Approvisionnement",
    userFunctionSales: "Ventes",
    userFunctionProjectManagement: "Gestion de projet",
    userFunctionCalculationCosting: "Calcul / Coûts",
    userFunctionAdministrativeProcessing: "Traitement administratif",
    userFunctionOther: "Autre",
  },
  commonAppValues: {
    types: "# types",
    created: "Créé",
    lastEdited: "dernière modification",
    configuration: "Configuration",
    company: "Entreprise",
    user: "Utilisateur",
    orders: "Commandes",
    openOrders: "Commandes ouvertes",
    openRequests: "Demandes ouvertes",
    drafts: "Brouillons",
    requests: "Demandes",
    addPosition: "Ajouter une position",
    companies: "entreprises",
    configurationCodeLabel: "code",
  },
  notifications: {
    loadConfiguration: "Chargement de la configuration...",
    configurationUpdatedSuccessfully: "Configuration sauvegardée.",
    configurationIsSavedInTheCompany: "La configuration a été sauvegardée dans ton entreprise",
    configurationUpdatedError: "Une erreur s'est produite, la configuration n'a pas été mise à jour",
    configurationNotFound: "La configuration n'a pas été trouvée",
    confirmationOfPositionDeletion: "Es-tu sûr de vouloir supprimer cette position ?",
    confirmationOfGlassDeletion: "Es-tu sûr de vouloir supprimer cette configuration de verre ?",
    confirmationOfGlassEdit: "Veux-tu changer cette configuration de verre ?",
    errorAddingFileWrongFormat: "Tu ne peux ajouter que des images ou des fichiers PDF",
    confirmationOfDocumentDeletion: "Es-tu sûr de vouloir supprimer ce document ?",
    confirmationOfConfigurationDeletion: "Es-tu sûr de vouloir supprimer cette configuration ?",
    errorLargeFileSizeFmt: "Taille maximale du fichier {}",
    accountCreationInProgress: "Création de compte en cours",
    accountCreatedSuccessfully: "Tu t'es inscrit avec succès",
    orderSuccessful: "Ta commande a été envoyée. Merci de ton attention",
    quoteSuccessful: "Ta demande a été envoyée. Merci.",
    emailSendingInProgress: "Veuillez patienter, le message est en cours d'envoi",
    configurationDeletionInProgress: "La suppression de la configuration est en cours",
    configurationWasDeletedSuccessfully: "La configuration a été supprimée avec succès",
    passwordResetInProgress: "Réinitialisation du mot de passe en cours",
    passwordHasBeenSuccessfullyChanged: "Le mot de passe a été modifié avec succès",
    pleaseWait: "Veuillez patienter...",
    companyDataWasUpdated: "Les données de l'entreprise ont été mises à jour",
    emailWithInstuctionsToResetPasswordHasBeenSent: "Un e-mail contenant des instructions pour réinitialiser ton mot de passe t'a été envoyé",
    confirmationOfChangeTypeConfiguration: "Es-tu sûr de vouloir changer le type de cette configuration ?",
    configurationTypeChangeInProgress: "Changement de type de configuration en cours",
    configTypeChangedSuccessfully: "Le type de configuration a été modifié avec succès",
    configurationStatusChangeInProgress: "Changement d'état de la configuration en cours",
    configurationStatusChangedSuccessfully: "L'état de la configuration a été modifié avec succès",
    duplicatingConfigWithAllFilesInProgress: "Veuillez patienter. Duplication d'une configuration avec tous ses fichiers en cours",
    configurationDuplicationCompletedSuccessfully: "La duplication de la configuration a été effectuée avec succès",
    confirmationOfClientCompanyDeletion: "Es-tu sûr de vouloir supprimer ce client avec ses utilisateurs et ses configurations ?",
    clientCompanyDeletionInProgress: "La suppression de l'entreprise cliente est en cours",
    clientCompanyWasDeletedSuccessfully: "L'entreprise cliente a été supprimée avec succès",
    companyNotFound: "Entreprise non trouvée",
    loadingCompanyData: "Chargement des données de l'entreprise...",
    dataLoadedSuccessfully: "Les données ont été chargées avec succès",
    dataHasBeenSuccessfullyUpdated: "Les données ont été mises à jour avec succès !",
    userHasBeenSuccessfullyRemovedFromTheCompany: "L'utilisateur a été supprimé avec succès de l'entreprise",
    confirmationOfUserDeletion: "Es-tu sûr de vouloir supprimer l'utilisateur de l'entreprise ?",
    invitationLinkHasBeenCopied: "Le lien a été copié dans le presse-papiers",
    anErrorHasOccurred: "Une erreur s'est produite",
    loadingDataOfAllCompaniesInProgress:
      "Chargement des données de toutes les entreprises en cours...",
    uploadDocAreaDragAndDrop: "Glissez-déposez les fichiers ici",
    uploadDocAreaOr: "ou",
    uploadDocAreaBrowseFiles: "Parcourir les fichiers sur ton ordinateur",
    uploadDocAreaMaxFileSizeFmt: "Taille maximale du fichier {}",
    attachmentDeletionInProgress: "Suppression de la pièce jointe en cours...",
    attachmentWasDeletedSuccessfully: "Pièce jointe supprimée",
  },
  formNotifications: {
    emailRequired: "L'email est obligatoire",
    enterValidEmail: "Veuillez entrer un email valide",
    VATNumberRequired: "Numéro TVA est obligatoire",
    enterValidVATNumber: "Entrez un numéro TVA suisse valide (CHE-000.000.000)",
    fieldIsRequired: "est obligatoire",
    nameFieldInvalidCharacters: "Caractères non valides",
    nameFieldCharactersLength: "Doit comporter au moins trois caractères",
    passwordFieldCharactersLong: "Le mot de passe doit comporter 8 caractères",
    passwordFieldMustContainNumber:
      "Mot de passe incorrect. Doit contenir un chiffre",
    passwordNotMatched: "Le mot de passe ne correspond pas",
  },
  configurationCategory: {
    glassType: "Type de verre",
    fireResistant: "Verre coupe-feu",
    spacer1: "Intercalaire",
    gas1: "Gaz",
    middleGlass: "Verre intermédiaire (Silverstar EN2plus)",
    spacer2: "Intercalaire",
    gas2: "Gaz",
    outsideGlass: "Verre extérieur (Silverstar EN2plus)",
    structure: "Structure",
    structure_info: "",
    manufacturer: "Fabricant",
    manufacturer_info: "",
    fireResistanceClass: "Résistance au feu",
    fireResistanceClass_info: "Selon la norme EN 13501-2",
    insulation: "Coefficient U",
    insulation_info: "En [W/m²K]\nSelon la norme EN 673",
    application: "Application",
    application_info: "",
    whiteGlass: "Extrablanc",
    whiteGlass_info: "",
    resistanceClass: "Classe de résistance",
    resistanceClass_info: "Selon la norme EN 1627",
    buttJoints: "Bords à bords",
    buttJoints_info: "",
    sound: "Affaiblissement acoustique Rw",
    sound_info: "Selon la norme EN 140-3",
    thickness: "Épaisseur",
  },
  filterName: {
    StructureFilter: "Structure",
    ManufacturerFilter: "Fabricant",
    FireClassFilter: "Résistance au feu",
    InsulationFilter: "Coefficient U",
    ApplicationFilter: "Application",
    WhiteGlassFilter: "Extrablanc",
    ResistanceClassFilter: "Classe de résistance",
    ButtJointsFilter: "Bords à bords",
    SoundFilter: "Affaiblissement acoustique",
  },
  commonConfigurationAction: {
    recordPositions: "Enregistrer les positions",
    updateGlassType: "Actualiser le type de verre",
    resetGlass: "Réinitialiser le verre",
    resetConfiguration: "Réinitialiser toutes les positions",
    saveAsPdf: "Enregistrer en PDF",
    creatingPdf: "Création du PDF...",
    glassDotPdfFmt: "CDR_Verre_{date}.pdf",
    configurationDotPdfFmt: "CDR_Configuration_{date}.pdf",
    backToPositionsEntry: "Retour à l'enregistrement des positions",
    continue: "Continuer",
    continueWithoutReference: "Continuer sans Commission",
    open: "Ouvrir",
    reopen: "Rouvrir",
    delete: "Supprimer",
    complete: "Compléter",
    duplicate: "Dupliquer",
    edit: "Éditer",
    order: "Commander",
    request: "Demande d'offre",
    duplicateAsDraft: "Dupliquer comme brouillon",
    firstSelectProperties: "Choisir les propriétés",
    secondCustomize: "Personnaliser",
    sizeValidationError: "Veuillez t'assurer de remplir tous les champs requis : largeur, hauteur et quantité.",
    selectProductToContinue: "Choisis un produit pour continuer.",
    priceInfoBox: "Prix quotidien incl. livraison d'entrepôt & surtaxe de coûts énergétiques (SCE)\nexcl. redevance poids lourds (RPLP) & TVA\nSurface de facturation minimale par pièce : 0,5 m²\nPrix valable jusqu'à 25 m², pour plus de quantité veuillez demander.\nVerres formés +50%.",
    priceNotAvailableInfoBox: "Le prix pour cette position est actuellement disponible sur demande uniquement",
    loginForPriceInfoBox: "Veuillez te connecter pour voir les prix",
  },
  configurationTableHeading: {
    length: "largeur",
    width: "hauteur",
    linearMeterAbbrev: "ML",
    quantity: "quantité",
    price: "prix",
    form: "Module",
    formNote:
      "Pour les verres formés, veuillez cocher la case. Pour les commandes, joignez un dessin ci-dessous. Pour les formes circulaires, une note suffit. Pour les demandes de renseignements seulement, la case à cocher sans dessin est suffisante.",
    notes: "notes",
    cantonalInsurance: "N° AEAI",
    cantonalInsuranceLink:
      "https://www.bsronline.ch/fr/recherche-dans-le-repertoire/",
    total: "total",
    totalCart: "Toutes les Positions",
    pos: "pos",
    pc: "pièces",
    kg: "kg",
  },
  registrationForm: {
    title: "Enregistrement",
    registerButton: "Inscription",
    alreadyHaveAccount: "As-tu déjà un compte? ",
    logIn: "Se connecter",
  },
  loginForm: {
    title: "Connexion",
    dontHaveAccount: "N'as-tu pas de compte?",
    loginButton: "Connexion",
    lostPassword: "Mot de passe perdu",
    signUp: "S'inscrire",
    successfullyLoggedIn: "Tu es connecté!",
    loggingInProgress: "Connexion en cours",
    successfullyLoggedOut: "Tu as réussi à te déconnecter",
  },
  lostPasswordForm: {
    dontHaveAccount: "N'as-tu pas de compte ?",
    title: "Mot de passe perdu",
    submitButton: "réinitialiser le mot de passe",
    signUp: "s'inscrire",
    loggingInProgress: "Réinitialisation en cours",
    successfullyLoggedIn: "Réinitialisation réussie",
  },
  resetConfigurationForm: {
    title: "Réinitialiser la configuration",
    text: "Veux-tu démarrer une nouvelle configuration et conserver la configuration actuelle? Le code de configuration est valable pendant 30 jours",
    configurationCode: "Code de configuration",
    resetButton: "Réinitialiser",
    or: "ou",
    textNew: "Veux-tu démarrer une nouvelle configuration et supprimer tous les contenus et fichiers?",
    resetDeleteButton: "Réinitialiser et supprimer",
    noConfigurationDefinedResetGlass: "Aucune configuration définie. Réinitialisez uniquement le verre actuel.",
  },
  resetGlassForm: {
    title: "Réinitialiser le verre",
    text: "Veux-tu réinitialiser le verre et les filtres actuels?",
    resetButton: "Réinitialisation",
  },
  guestForm: {
    title: "Invité",
    additionalInfo: "Infos supplémentaires",
    continue: "continuer",
  },
  accountCompanyForm: {
    title: "Entreprise",
  },
  accountPage: {
    companyFormTitle: "Entreprise",
    userFormTitle: "Utilisateur",
    usersInCompanyTitle: "Utilisateurs dans l'entreprise",
    registerNewUser: "Enregistre un nouvel utilisateur pour cette entreprise",
    copyInviteLinkFmt: "Copie le lien pour l'auto-inscription avec {company} dans le presse-papiers",
    saveButton: "Enregistrer",
  },
  configurationCodeForm: {
    title: "Code de configuration",
    enterYourCodePlaceholder: "Entre ton code",
    overwriteConfigurationCheckbox: "Ceci écrasera ta configuration actuelle",
    submitButton: "charger",
  },
  resetPasswordForm: {
    title: "Mot de passe perdu",
    submitButton: "Réinitialiser le mot de passe",
  },
  homePage: {
    allStatementsWithoutGuarantee: "Toutes informations sans garantie",
    disclaimer: "Avertissement",
    disclaimerLink: "https://admin.cdr.ch/wp-content/uploads/2024/05/Disclaimer-Configurator-FR.pdf",
    termsAndConditions: "Conditions générales",
    termsAndConditionsLink: "https://admin.cdr.ch/wp-content/uploads/2024/05/CGV-FR.pdf",
    cookieSettings: "Paramètres des Cookies",
  },
  cartPage: {
    configurationCodeTitle: "Code de configuration",
    configurationCodeLabel: "code",
    configurationCodeDescription: "Ceci est le code de ta configuration, si tu veux le récupérer plus tard sans connexion. Il est valable pendant 30 jours",
    yourReference: "Ta Commission / Référence",
    yourReferenceInputLabel: "Commission 1",
    yourReferenceInputLabelPlaceholder: "",
    yourReferenceOptionalTitle: "",
    buildingReferenceInputLabel: "Commission 2",
    buildingReferenceInputLabelPlaceholder: "",
    uploadedDocuments: "Documents téléchargés",
    addNewTypeTitle: "Ajouter un nouveau type de verre",
    createFirstConfiguration: "Crée ta première configuration",
    addNewTypeBtn: "ajouter",
    cartIsEmpty: "Aucune position ajoutée",
    termsText:
      "J'accepte les <a href=\"{termsURL}\" target=\"_blank\">conditions générales</a> et la clause de <a href=\"{disclaimerURL}\" target=\"_blank\">non-responsabilité</a>",
  },
  pageNavigation: {
    login: "S'inscrire",
    myCode: "Mon code",
    dashboard: "Tableau de bord",
    account: "Compte",
    logout: "Déconnexion",
    adminDashboard: "Tableau d.B. Admin",
    configurator: "Configurateur",
  },
  workflowNavigation: {
    configure: "Configurer",
    cart: "Enregistrer les Positions",
    inquireOrder: "Demande d'offre / Commander",
  },
  requestOrOrderPage: {
    request: "Demande d'offre",
    order: "Commander",
    preferredQuoteDate: "Offre jusqu'à",
    preferredOrderDeliveryDate: "Livraison le",
  },
  orderConfirmationPage: {
    orderConfirmationTitle: "Confirmation de commande",
    quoteConfirmationTitle: "Confirmation de demande de devis",
    emailConfirmationSentToFmt: "Une confirmation par e-mail a été envoyée à {email}.",
    backToStart: "Retour au début",
  },
  adminDashboardPage: {
    clientsTitle: "Clients",
  },
  contactCompanyInformation: {
    showPrices: "Prix affichés",
    customerReference: "N° Client",
    company: "société",
    address: "adresse",
    zip: "CP",
    city: "localité",
    tel: "Tel",
    email: "Email",
    numAccounts: "Utenti",
  },
  genericWarningPopup: {
    warning: "Attention",
    confirm: "Confirmer",
  },
  deletePositionPopup: {
    title: "Supprimer le poste",
    description: "Es-tu sûr de vouloir supprimer ce poste?",
    deleteButton: "Supprimer",
  },
  deleteAttachmentPopup: {
    title: "Supprimer la pièce jointe",
    description: "Es-tu sûr de vouloir supprimer cette pièce jointe?",
    deleteButton: "Supprimer",
  },
  supportedDevicesPopup: {
    title: "Mobile",
    description:
      "Ce configurateur n'est actuellement pas pris en charge par les appareils mobiles. Veuillez utiliser un ordinateur de bureau ou redimensionner le navigateur",
  },
  glassCharacteristics: {
    glassStructure: "Structure",
    fireResistance: "Résistance au feu",
    uValue: "Coefficient U",
    thickness: "Épaisseur",
    clearGlass: "Verre clair",
    resistanceClass: "Classe de résistance",
    buttJoints: "Convient pour les assemblages bout à bout",
    application: "Applications appropriées",
    sound: "Amortissement du bruit",
    valid: "Configuration valable",
    glassMaxSizeWarningFormat: "Verre limité à {max_width_mm} x {max_height_mm} mm",
    filteredCategoryLabel: "Indisponible à cause des filtres",
  },
  filters: {
    changesUValue: "Change le coefficient U",
    gas_argon: "Argon",
    gas_krypton: "Krypton",
    gas_mixedGas: "Gaz mixte",
    gas_expensive: "Le prix de ce gaz est actuellement très volatil et cher",
    spacer_format: "{} mm",
    structure_1Glazed: "Simple (MONO)",
    structure_2Glazed: "Double (ISO)",
    structure_3Glazed: "Triple (ISO)",
    manufacturer_indep: "Indépendant",
    manufacturer_AGC: "AGC",
    manufacturer_GlasTroesch: "Glas Trösch",
    fireResistanceClass_EI30: "EI30",
    fireResistanceClass_EI60: "EI60",
    fireResistanceClass_EI90: "EI90",
    insulation_UValueFormat: "{}",
    application_in: "intérieur",
    application_inOut: "intérieur/extérieur",
    application_outOut: "extérieur/extérieur",
    whiteGlass_yes: "Oui",
    whiteGlass_no: "Non",
    resistanceClass_none: "Sans",
    resistanceClass_rc2: "RC2 (P4A)",
    resistanceClass_rc3: "RC3 (P5A)",
    resistanceClass_rc4: "RC4 (P6B)",
    buttJoints_yes: "Oui",
    buttJoints_no: "Non",
    sound_format: "{} dB",
    sound_format_better_than: "min {} dB",
    sound_format_npd: "NPD",
  },
  pdfPage: {
    pageFooterFmt: "Page {pageNum} de {numPages}",
    drafts: "Brouillon",
    order: "Commande",
    orderDeliveryDateFmt: "Livraison le {}",
    request: "Demande d'offre",
    requestDueDateFmt: "Offre jusqu'à {}",
  },
  firebaseErrorMessages: {
    // errors for Cloud Storage
    "storage/unknown": "Une erreur inconnue s'est produite.",
    "storage/object-not-found": "Aucun objet n'existe à la référence souhaitée.",
    "storage/bucket-not-found": "Aucun seau n'est configuré pour le stockage Cloud.",
    "storage/project-not-found": "Aucun projet n'est configuré pour le stockage Cloud.",
    "storage/quota-exceeded": "Le quota sur ton seau de stockage Cloud a été dépassé.",
    "storage/unauthenticated": "L'utilisateur n'est pas authentifié, veuille t'authentifier et réessayer.",
    "storage/unauthorized":
      "Tu n'es pas autorisé à effectuer l'action souhaitée, vérifie tes règles de sécurité pour t'assurer qu'elles sont correctes.",
    "storage/retry-limit-exceeded":
      "La limite de temps maximale sur une opération (téléchargement, téléchargement, suppression, etc.) a été dépassée. Essaye de télécharger à nouveau.",
    "storage/invalid-checksum":
      "Le fichier sur le client ne correspond pas à la somme de contrôle du fichier reçu par le serveur. Essaye de télécharger à nouveau.",
    "storage/canceled": "L'utilisateur a annulé l'opération.",
    "storage/invalid-event-name": "Nom d'événement invalide fourni. Doit être l'un des [`running`, `progress`, `pause`]",
    "storage/invalid-url":
      "URL invalide fournie à refFromURL(). Doit être de la forme: gs://bucket/object ou https://firebasestorage.googleapis.com/v0/b/bucket/o/object?token=<TOKEN>",
    "storage/invalid-argument":
      "L'argument passé à put() doit être un `File`, un `Blob` ou un tableau `UInt8`. L'argument passé à putString() doit être une chaîne brute, `Base64` ou `Base64URL`.",
    "storage/no-default-bucket":
      "Aucun seau n'a été défini dans la propriété storageBucket de ta configuration.",
    "storage/cannot-slice-blob":
      "Se produit fréquemment lorsque le fichier local a changé (supprimé, enregistré à nouveau, etc.). Essaye de télécharger à nouveau après avoir vérifié que le fichier n'a pas changé.",
    "storage/server-file-wrong-size":
      "Le fichier sur le client ne correspond pas à la taille du fichier reçu par le serveur. Essaye de télécharger à nouveau.",

    // authentication API Errors
    "auth/claims-too-large":
      "La charge utile des revendications fournie à setCustomUserClaims() dépasse la taille maximale autorisée de 1000 octets.",
    "auth/email-already-exists":
      "L'e-mail fourni est déjà utilisé par un utilisateur existant. Chaque utilisateur doit avoir une adresse e-mail unique.",
    "auth/id-token-expired": "Le jeton d'ID Firebase fourni a expiré.",
    "auth/id-token-revoked": "Le jeton d'ID Firebase a été révoqué.",
    "auth/insufficient-permission":
      "Le certificat utilisé pour initialiser le SDK Admin n'a pas les permissions nécessaires pour accéder à la ressource d'authentification demandée. Consulte Configurer un projet Firebase pour obtenir la documentation sur la génération d'un certificat avec les autorisations appropriées et utilise-le pour authentifier les SDK Admin.",
    "auth/internal-error":
      "Le serveur d'authentification a rencontré une erreur inattendue lors de la tentative de traitement de la demande. Le message d'erreur devrait contenir la réponse du serveur d'authentification contenant des informations supplémentaires. Si l'erreur persiste, veuille signaler le problème à notre canal de support Bug Report.",
    "auth/invalid-argument":
      "Un argument invalide a été fourni à une méthode d'authentification. Le message d'erreur devrait contenir des informations supplémentaires.",
    "auth/invalid-claims": "Les attributs de revendication personnalisés fournis à setCustomUserClaims() sont invalides.",
    "auth/invalid-continue-uri": "L'URL de continuation doit être une chaîne URL valide.",
    "auth/invalid-creation-time": "Le temps de création doit être une chaîne de date UTC valide.",
    "auth/invalid-credential":
      "Le certificat utilisé pour authentifier les SDK Admin ne peut pas être utilisé pour effectuer l'action souhaitée. Certaines méthodes d'authentification telles que createCustomToken() et verifyIdToken() nécessitent que le SDK soit initialisé avec un certificat en tant que token de rafraîchissement ou un jeton d'application par défaut. Consulte la documentation sur l'initialisation du SDK pour savoir comment authentifier les SDK Admin avec un certificat.",
    "auth/invalid-disabled-field":
      "La valeur fournie pour la propriété d'utilisateur désactivé est invalide. Elle doit être un booléen.",
    "auth/invalid-display-name":
      "La valeur fournie pour la propriété d'utilisateur displayName est invalide. Elle doit être une chaîne non vide.",
    "auth/invalid-dynamic-link-domain":
      "Le domaine de lien dynamique fourni n'est pas configuré ou autorisé pour le projet actuel.",
    "auth/invalid-email":
      "La valeur fournie pour la propriété d'utilisateur email est invalide. Elle doit être une adresse e-mail.",
    "auth/invalid-email-verified":
      "La valeur fournie pour la propriété d'utilisateur emailVerified est invalide. Elle doit être un booléen.",
    "auth/invalid-hash-algorithm":
      "L'algorithme de hachage doit correspondre à l'une des chaînes de la liste des algorithmes pris en charge.",
    "auth/invalid-hash-block-size": "La taille du bloc de hachage doit être un nombre valide.",
    "auth/invalid-hash-derived-key-length": "La longueur de la clé dérivée du hachage doit être un nombre valide.",
    "auth/invalid-hash-key": "La clé de hachage doit être un tampon d'octets valide.",
    "auth/invalid-hash-memory-cost": "Le coût de la mémoire de hachage doit être un nombre valide.",
    "auth/invalid-hash-parallelization": "La parallélisation de hachage doit être un nombre valide.",
    "auth/invalid-hash-rounds": "Le nombre de tours de hachage doit être un nombre valide.",
    "auth/invalid-hash-salt-separator":
      "Le champ de séparateur de sel d'algorithme de hachage doit être un tampon d'octets valide.",
    "auth/invalid-id-token": "Le jeton d'ID fourni n'est pas un jeton d'ID Firebase valide.",
    "auth/invalid-last-sign-in-time": "L'heure de la dernière connexion doit être une chaîne de date UTC valide.",
    "auth/invalid-page-token":
      "Le jeton de page suivante fourni dans listUsers() est invalide. Il doit être une chaîne valide non vide.",
    "auth/invalid-password":
      "La valeur fournie pour la propriété d'utilisateur password est invalide. Elle doit être une chaîne d'au moins six caractères.",
    "auth/invalid-password-hash": "La hachage du mot de passe doit être un tampon d'octets valide.",
    "auth/invalid-password-salt": "Le sel de mot de passe doit être un tampon d'octets valide.",
    "auth/invalid-phone-number":
      "La valeur fournie pour le numéro de téléphone est invalide. Elle doit être une chaîne conforme à la norme E.164.",
    "auth/invalid-photo-url":
      "La valeur fournie pour la propriété d'utilisateur photoURL est invalide. Elle doit être une chaîne URL.",
    "auth/invalid-provider-data": "providerData doit être un tableau valide d'objets UserInfo.",
    "auth/invalid-provider-id": "providerId doit être une chaîne valide d'identifiant de fournisseur pris en charge.",
    "auth/invalid-oauth-responsetype": "Seul un type de réponse OAuth doit être défini sur true.",
    "auth/invalid-session-cookie-duration":
      "La durée du cookie de session doit être un nombre valide en millisecondes entre 5 minutes et 2 semaines.",
    "auth/invalid-uid": "L'UID fourni doit être une chaîne non vide d'au plus 128 caractères.",
    "auth/invalid-user-import": "L'enregistrement utilisateur à importer est invalide.",
    "auth/maximum-user-count-exceeded":
      "Le nombre maximum autorisé d'utilisateurs à importer a été dépassé.",
    "auth/missing-android-pkg-name":
      "Un nom de package Android doit être fourni si l'application Android doit être installée.",
    "auth/missing-continue-uri": "Une URL de continuation valide doit être fournie dans la demande.",
    "auth/missing-hash-algorithm":
      "L'importation d'utilisateurs avec des hachages de mot de passe nécessite la fourniture de l'algorithme de hachage et de ses paramètres.",
    "auth/missing-ios-bundle-id": "La demande manque d'un ID de bundle.",
    "auth/missing-uid": "Un identifiant UID est requis pour l'opération en cours.",
    "auth/missing-oauth-client-secret":
      "Le secret client de configuration OAuth est requis pour activer le flux de code OIDC.",
    "auth/network-request-failed": "Pas de connexion",
    "auth/operation-not-allowed":
      "Le fournisseur de connexion fourni est désactivé pour ton projet Firebase. Active-le depuis la section Méthode de connexion de la console Firebase.",
    "auth/phone-number-already-exists":
      "Le numéro de téléphone fourni est déjà utilisé par un utilisateur existant. Chaque utilisateur doit avoir un numéro de téléphone unique.",
    "auth/project-not-found":
      "Aucun projet Firebase n'a été trouvé pour le certificat utilisé pour initialiser les SDK Admin. Consulte Configurer un projet Firebase pour obtenir la documentation sur la génération d'un certificat pour ton projet et utilise-le pour authentifier les SDK Admin.",
    "auth/reserved-claims":
      "Une ou plusieurs revendications utilisateur personnalisées fournies à setCustomUserClaims() sont réservées. Par exemple, des revendications spécifiques à OIDC telles que (sub, iat, iss, exp, aud, auth_time, etc.) ne doivent pas être utilisées comme clés pour des revendications personnalisées.",
    "auth/session-cookie-expired": "Le cookie de session Firebase fourni a expiré.",
    "auth/session-cookie-revoked": "Le cookie de session Firebase a été révoqué.",
    "auth/too-many-requests": "Le nombre de demandes dépasse le maximum autorisé.",
    "auth/uid-already-exists":
      "L'UID fourni est déjà utilisé par un utilisateur existant. Chaque utilisateur doit avoir un UID unique.",
    "auth/unauthorized-continue-uri":
      "Le domaine de l'URL de continuation n'est pas sur la liste blanche. Ajoute le domaine dans la console Firebase.",
    "auth/user-not-found": "Il n'existe aucun enregistrement d'utilisateur correspondant à l'identifiant fourni.",
    "auth/wrong-password": "Mauvais mot de passe",

    // other errors
    ok: "Opération réussie",
    cancelled: "Opération annulée",
    unknown: "Erreur inconnue",
    "invalid-argument": "Argument non valide",
    "deadline-exceeded": "Délai dépassé",
    "not-found": "Non trouvé",
    "already-exists": "Déjà existant",
    "permission-denied": "Permission refusée",
    unauthenticated: "Utilisateur non authentifié",
    "resource-exhausted": "Ressource épuisée",
    "failed-precondition": "Précondition échouée",
    aborted: "Opération annulée",
    "out-of-range": "Hors de portée",
    unimplemented: "Non implémenté",
    internal: "Erreur interne",
    unavailable: "Indisponible",
    "data-loss": "Perte de données",
  },
  email: {
    draft: "Brouillon",
    request: "Demande d'offre",
    order: "Commande",
    salutationFmt_male: "Cher {firstName}",
    salutationFmt_female: "Chère {firstName}",
    salutationFmt_div: "Cher/Chère {firstName}",
    bestRegards: "Cordialement,",
    yourCDRTeam: "Ton équipe CDR",
    confirmationSubject_request: "Ta demande de devis CDR",
    confirmationSubject_order: "Ta commande CDR",
    confirmationBodyFmt_request: "<div style=\"font-family: Arial, sans-serif;\"><p>{salutation},</p><p>Merci pour ta demande. Nous allons y donner suite immédiatement.</p><p>Tu trouveras ci-joint une copie de la demande et des documents soumis.</p></div>{signature}",
    confirmationBodyFmt_order: "<div style=\"font-family: Arial, sans-serif;\"><p>{salutation},</p><p>Merci pour ta commande. Nous allons y donner suite immédiatement.</p><p>Tu trouveras ci-joint une copie de la commande et des documents soumis.</p></div>{signature}",
    showPricesSubject: "Affichage des prix du CDR Configurator",
    showPricesFmt: "<div style=\"font-family: Arial, sans-serif;\"><p>{salutation},</p><p>Tous les comptes de {company} sont maintenant activés pour l'affichage des prix sur le <a href=\"{configuratorURL}\">CDR Configurator</a></p></div>{signature}",
  },
  faqPage: {
    typeYourQuestion: "Tape ta question",
    back: "Retour",
    foundSingleAnswer: "Une réponse trouvée",
    foundMultipleAnswersFmt: "{numAnswers} réponses trouvées",
  },
  datasheet: {
    datasheetForGlassFmt: "Fiche technique pour {}",
    datasheetFmt: "Fiche technique {}",
  },
};
