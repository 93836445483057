import React from 'react';

export const TotalInfoItem = ({ show, value, unit }) => {
	return show ? (
		<div className="total_info__item">
			{value}
			{unit}
		</div>
	) : null;
};
