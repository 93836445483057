import { BaseShaderMaterial } from 'ohzi-core';
import fragment from '../shaders/GlassBackShader.frag'
import vertex from '../shaders/GlassBackShader.vert'
import { BackSide } from 'three';


export default class GlassBackMaterial extends BaseShaderMaterial
{
  constructor()
  {
    super(vertex, fragment, {});
    this.side = BackSide;
  };
    
};
