import React from 'react';

const DashboardCompanyInfoItem = ({
	title,
	value = '0',
}) => {
	return (
		<li className="dashboard_company_info__item">
			<div className="dashboard_company_info__block">
				{title && (
					<div className="dashboard_company_info__title">{title}</div>
				)}
				{value !== null && value !== undefined ? (
					<div className="dashboard_company_info__value">{value}</div>
				) : null}
			</div>
		</li>
	);
};

export default DashboardCompanyInfoItem;
