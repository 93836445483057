import { selectStyles } from 'utils/react-select-styles.js';
import AbstractUIElement from '../elementsUI/AbstractUIElement.js'

export default class FilterUIElement extends AbstractUIElement {
    constructor(props) {
        super(props, { i18nSection: 'filters' });
        this.filter = null;
        this.selectStyles = selectStyles;
    }

    setFilterHandler(filterHandler, selectedValue = null) {
        this.filter = filterHandler;
        this.options = filterHandler.filterOptions;
        if (!filterHandler.hasDefaultOption) {
            this.defaultOption = null;
        }
        this.recreate(selectedValue || this.state.selectedValue);
    }

    t(str, i18n_section = null) {
        return super.t(this.filter.i18n_prefix + str, i18n_section);
    }

    handleSelectChange = (selectedOption) => {
        if (selectedOption.value !== this.state.selectedValue) {
            this.set(selectedOption.value);
        }
    }

    generateOption(optKey) {
        const maybeCb = (maybeFn, ...params) => typeof (maybeFn) === 'function' ? maybeFn(...params) : maybeFn;
        const value = maybeCb(this.options[optKey], optKey);
        const displayVal = value.displayVal ?? optKey;
        const selectKey = value.key ?? optKey;
        const selectLabel = this.t(value.translationKey ?? value).replace('{}', displayVal);
        return { value: selectKey, label: selectLabel };
    }

    generateOptions() {
        return [
            ...(this.defaultOption !== null ? [this.defaultOption] : []),
            ...Object.keys(this.options).map(k => this.generateOption(k)),
        ];
    }

    recreate(select_value = undefined) {
        super.recreate(select_value);
        let options = this.generateOptions();
        if (select_value && options.find(o => o.value === select_value) === undefined) {
            console.warn(options);
            console.warn(`Selecting ${select_value} that is not a valid option from above`);
        }
        this.updateState({ options, selectedValue: select_value });
    }

    selectValue(selectedValue) {
        if (selectedValue !== this.state.selectedValue) {
            if (this.state.options.findIndex(o => o.value === selectedValue) === -1) {
                console.warn(this.state.options);
                console.warn(`Selecting ${selectedValue} that is not a valid option from aboved`);
            } else {
                this.setState({ selectedValue });
            }
        }
    }

    set(select_value) {
        if (select_value === this.filter.selectedFilter) return;
        let previouslySet = this.filter.selectedFilter !== null;
        this.filter.setFilter(select_value);
        this.props.uiHandlerRef.current.onFilterChanged(this.filter, previouslySet);
    }
}