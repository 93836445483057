uniform vec2 uSize;
uniform sampler2D uMap;
uniform samplerCube envMap;
varying vec3 vWorldNormal;
varying vec3 vViewNormal;
varying vec2 vUV;
varying vec3 vWorldCameraDir;


void main() {

	vec4 reflectedColor = textureCube(envMap, reflect(vWorldCameraDir, vWorldNormal)) * 0.1;
	
	vec4 diffuse = texture2D(uMap, vUV.xy + vec2(0.0, .1) );

	gl_FragColor = diffuse + reflectedColor;
}