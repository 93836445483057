import InsulationFilter from './filters/InsulationFilter.js';
import FireClassFilter from './filters/FireClassFilter.js';
import SoundFilter from './filters/SoundFilter.js';
import WhiteGlassFilter from './filters/WhiteGlassFilter.js';
import ButtJointsFilter from './filters/ButtJointsFilter.js';
import ManufacturerFilter from './filters/ManufacturerFilter.js';
import StructureFilter from './filters/StructureFilter.js';
import ApplicationFilter from './filters/ApplicationFilter.js';
import ResistanceClassFilter from './filters/ResistanceClassFilter.js';

export default class FilterHandler {
  constructor() {
    this.filters = [];
  }

  initFilters() {
    // Filters glass structures, not glasses
    this.structureFilter = new StructureFilter();
    this.filters.push(this.structureFilter);

    this.fireClassFilter = new FireClassFilter();
    this.filters.push(this.fireClassFilter);

    this.resistanceClassFilter = new ResistanceClassFilter(); // Depends on whether structure is mono/iso
    this.filters.push(this.resistanceClassFilter);

    this.whiteGlassFilter = new WhiteGlassFilter();
    this.filters.push(this.whiteGlassFilter);

    this.buttJointsFilter = new ButtJointsFilter(); // Requires compatible 1-glazed
    this.filters.push(this.buttJointsFilter);

    this.manufacturerFilter = new ManufacturerFilter();
    this.filters.push(this.manufacturerFilter);

    this.soundFilter = new SoundFilter();
    this.filters.push(this.soundFilter);

    this.applicationFilter = new ApplicationFilter(); // Outside/Outside needs 2-glazed
    this.filters.push(this.applicationFilter);

    // Filters gas/spacer, not glasses
    this.insulationFilter = new InsulationFilter();
    this.filters.push(this.insulationFilter);
  }

  /// Run either only the passed filters or all if none passed
  run(dbView, filters=null) {
    (filters || this.filters).forEach((filter) => {
      filter.applyFilter(dbView);
    });
  }

  getFilterByName(filterName) {
    return this.filters.find(filter => filter.filterName === filterName);
  }

  getActiveFilters() {
    return this.filters.filter(f => f.selectedFilter !== null);
  }

  getFilterSnapshot() {
    return this.filters.reduce((acc, f) => { acc[f.filterName] = f.selectedFilter; return acc; }, []);
  }

  applyFilterSnapshot(snapshot, glass_structure) {
    Object.keys(snapshot).map(fn => {
      let filter = this.filters.find(f => f.filterName === fn);
      filter.selectedFilter = snapshot[fn];
      if (Object.keys(filter).indexOf('glassStructure') >= 0) {
        filter.glassStructure = glass_structure;
      }
    });
  }
}
