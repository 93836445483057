import React, {
	useCallback,
	useContext,
	useEffect, useRef, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { COMPANIES_API, USERS_API } from 'api';
import { AppDataContext } from 'context/appData';
import { BlockHeading } from 'components/BlockHeading';
import { ClientBlock } from 'components/ClientBlock';
import { FormNumEntriesSelector, FormSearchField } from 'components/Form';
import { getHtmlEmailSignature, sendGenericEmail } from 'components/Form/EmailNotifications';
import { PaginationBlock } from 'components/PaginationBlock';
import { Spinner } from 'components/Spinner';
import { chunkArray, cloneObjectWithoutReference } from 'utils';
import ToastifyHandler from 'utils/ToastifyHandler';

const AdminClientsList = ({
	setTotalNumberClientsCompany,
}) => {
	const { configuratorData, themeSettings } = useContext(AppDataContext);
	const { t } = useTranslation('baseFormPlaceholders');
	const [allCompanies, setAllCompanies] = useState(null);
	const [pagedFilteredSortedCompanies, setPagedFilteredSortedCompanies] = useState(null);
	const [chunkedCompaniesData, setChunkedCompaniesData] = useState(null);
	const [isSearchApplied, setSearchApplied] = useState(null);
	const [isSortingCriteriaApplied, setSortingCriteria] = useState('company');
	const [isSortingOrderAscending, setSortingOrderAscending] = useState(true);
	const [isPaginationShown, setPaginationShown] = useState(false);
	const [activePaginationPage, setActivePaginationPage] = useState(1);
	const [totalClientsCompanyItemsCount, setClientsCompanyItemsCount] = useState(1);
	const notificationsHandler = useRef(new ToastifyHandler());
	const [clientCompanyCountPerPage, setClientCompanyCountPerPage] = useState(10);
	const entriesPerPageOptions = [
		{ value: 10, label: '10' },
		{ value: 15, label: '15' },
		{ value: 25, label: '25' },
		{ value: 50, label: '50' },
	];
	const numCompaniesRef = useRef(0);

	const updateCurrentActivePaginationPage = () => {
		const activePageData = cloneObjectWithoutReference(chunkedCompaniesData[activePaginationPage - 1]);

		setPagedFilteredSortedCompanies(activePageData);
	};

	const sendNotificationOfPricesToCustomer = async (company) => {
		let person = await USERS_API.getUser(company.adminUserId);
		const lang = company.language || person.language;
		const lngOpts = lang ? { lng: lang } : {};
		const tUser = (s) => t(s, lngOpts);
		person.firstName = person.name;
		const salutation = tUser(`email:salutationFmt_${person.gender}`).replace(/\{(\w+)\}/g, (_match, key) => person[key] || '');
		const signature = await getHtmlEmailSignature({ configuratorData, themeSettings, t: tUser });
		const vars = {
			salutation,
			company: company.name,
			configuratorURL: "https://configurator.cdr.ch",
			signature,
		};
		const htmlEmailBody = tUser('email:showPricesFmt').replace(/\{(\w+)\}/g, (_match, key) => vars[key] || '');
		const emailProps = {
			subject: tUser('email:showPricesSubject'),
			html_body: htmlEmailBody,
			from_name: 'CDR Configurator',
			from_email: 'configurator@cdr.ch',
			to_email: company.creatorCompanyEmail,
			bcc_email: 'info@cdr.ch',
			reply_to: 'info@cdr.ch',
		};

		await sendGenericEmail(emailProps);
	};

	const handleOnChangeSearchInput = (e) => {
		const inputValueLowerCase = e.target.value.toLowerCase();

		if (inputValueLowerCase.trim() !== '') {
			const filteredArray = allCompanies.filter(({
				address,
				city,
				creatorCompanyEmail,
				name,
				tel,
				zip,
			}) => {
				const addressLowerCase = address ? address.toLowerCase() : '';
				const cityReferenceLowerCase = city ? city.toLowerCase() : '';
				const emailLowerCase = creatorCompanyEmail ? creatorCompanyEmail.toLowerCase() : '';
				const nameEditedLowerCase = name ? name.toLowerCase() : '';
				const telEditedLowerCase = tel ? tel.toLowerCase() : '';
				const zipEditedLowerCase = zip ? zip.toLowerCase() : '';

				return (
					addressLowerCase.includes(inputValueLowerCase)
					|| cityReferenceLowerCase.includes(inputValueLowerCase)
					|| emailLowerCase.includes(inputValueLowerCase)
					|| nameEditedLowerCase.includes(inputValueLowerCase)
					|| telEditedLowerCase.includes(inputValueLowerCase)
					|| zipEditedLowerCase.includes(inputValueLowerCase)
				);
			});

			setPagedFilteredSortedCompanies(filteredArray);
			setSearchApplied(true);
		} else {
			if (isPaginationShown) {
				updateCurrentActivePaginationPage();
			} else {
				const clonedFilteredCompanies = cloneObjectWithoutReference(allCompanies);
				setPagedFilteredSortedCompanies(clonedFilteredCompanies);
			}
			setSearchApplied(false);
		}
	};

	const splitCompaniesByPage = useCallback((clientsCompanyListData) => {
		if (!clientsCompanyListData) return;

		const chunkedData = chunkArray(clientsCompanyListData, clientCompanyCountPerPage);
		let page = activePaginationPage;
		if (activePaginationPage > chunkedData.length) {
			page = chunkedData.length;
			setActivePaginationPage(page);
		}
		const activePageData = cloneObjectWithoutReference(chunkedData[page - 1]);

		setClientsCompanyItemsCount(clientsCompanyListData.length);
		setPagedFilteredSortedCompanies(activePageData);
		setChunkedCompaniesData(chunkedData);
		setPaginationShown(chunkedData.length > 1);
	}, [activePaginationPage, clientCompanyCountPerPage,
		setActivePaginationPage, setClientsCompanyItemsCount, setPagedFilteredSortedCompanies, setChunkedCompaniesData, setPaginationShown]);

	const setShowPrices = async (event) => {
		const { target } = event;
		const companyId = target.dataset.clientId;
		const showPrices = target.checked;
		if (!companyId) return;

		const company = allCompanies.find(o => o.id === companyId);
		if (company.showPrices === showPrices) return;

		target.disabled = true;
		notificationsHandler.current.pending(t('notifications:pleaseWait'));
		try {
			await COMPANIES_API.updateCompanyData(companyId, { showPrices });
			notificationsHandler.current.success(t('notifications:companyDataWasUpdated'));
			if (showPrices) {
				sendNotificationOfPricesToCustomer(company);
			}
			company.showPrices = showPrices;
			setAllCompanies(allCompanies); // we updated showPrices
		} catch (error) {
			const { code } = error;
			notificationsHandler.current.rejected(t(code, { ns: 'firebaseErrorMessages' }));
		} finally {
			target.disabled = false;
		}
	};

	const setCustomerReference = async (event) => {
		const { target } = event;
		const companyId = target.id.substr('customer_ref_'.length);
		if (!companyId) return;
		const customerReference = target.value;

		const company = allCompanies.find(o => o.id === companyId);
		if (company.customerReference === customerReference) return;

		target.disabled = true;
		notificationsHandler.current.pending(t('notifications:pleaseWait'));
		try {
			await COMPANIES_API.updateCompanyData(companyId, { customerReference });
			company.customerReference = customerReference;
			setAllCompanies(allCompanies); // we updated customer reference
			notificationsHandler.current.success(t('notifications:companyDataWasUpdated'));
		} catch (error) {
			const { code } = error;
			notificationsHandler.current.rejected(t(code, { ns: 'firebaseErrorMessages' }));
		} finally {
			target.disabled = false;
		}
	};

	const setNewActivePaginationPage = (pageNumber) => {
		let page = pageNumber;
		if (page > chunkedCompaniesData.length) {
			page = chunkedCompaniesData.length;
		}
		const activePageData = cloneObjectWithoutReference(chunkedCompaniesData[page - 1]);

		setActivePaginationPage(page);
		setPagedFilteredSortedCompanies(activePageData);
	};

	const handlePaginationPageChange = (pageNumber) => {
		setNewActivePaginationPage(pageNumber);
	};

	const sortClientsByProp = (array, prop, asc = true, defaultValue = undefined) => {
		if (asc) {
			array.sort((a, b) => (a[prop] || defaultValue)?.localeCompare((b[prop] || defaultValue)) ?? -1);
		} else {
			array.sort((a, b) => (b[prop] || defaultValue)?.localeCompare((a[prop] || defaultValue)) ?? -1);
		}
	};

	const sortClientsByNumericProp = (array, prop, asc = true) => {
		const undefVal = (o) => ((o === undefined) ? -1 : o);
		if (asc) {
			array.sort((a, b) => undefVal(a[prop]) - undefVal(b[prop]));
		} else {
			array.sort((a, b) => undefVal(b[prop]) - undefVal(a[prop]));
		}
	};

	const handleSort = (e) => {
		const { sortBy } = e.target.dataset;
		if (isSortingCriteriaApplied === sortBy) {
			setSortingOrderAscending(!isSortingOrderAscending);
		} else {
			setSortingCriteria(sortBy);
			setSortingOrderAscending(true);
		}
	};

	const fetchAllClientsCompanies = async () => {
		const res = await COMPANIES_API.getAllCompaniesWithNumAccounts();

		if (res?.data) {
			setAllCompanies(res.data);
		}
	};

	const notifyCompanyDeleted = (companyId) => {
		const remainingCompanies = allCompanies.filter((o) => o.companyId !== companyId);
		setAllCompanies(remainingCompanies);
	};

	useEffect(() => {
		fetchAllClientsCompanies();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (!allCompanies) {
			return;
		}
		const numCompanies = allCompanies.length;
		if (numCompaniesRef.current !== numCompanies) {
			numCompaniesRef.current = numCompanies;
			if (setTotalNumberClientsCompany) {
				setTotalNumberClientsCompany(numCompaniesRef.current);
			}
		}

		const clonedFilteredCompanies = cloneObjectWithoutReference(allCompanies);
		switch (isSortingCriteriaApplied) {
			case "company": sortClientsByProp(clonedFilteredCompanies, 'name', isSortingOrderAscending); break;
			case "language": sortClientsByProp(clonedFilteredCompanies, 'language', isSortingOrderAscending, 'de'); break;
			case "customer_ref": sortClientsByProp(clonedFilteredCompanies, 'customerReference', isSortingOrderAscending); break;
			case "email": sortClientsByProp(clonedFilteredCompanies, 'creatorCompanyEmail', isSortingOrderAscending); break;
			case "address": sortClientsByProp(clonedFilteredCompanies, 'address', isSortingOrderAscending); break;
			case "city": sortClientsByProp(clonedFilteredCompanies, 'city', isSortingOrderAscending); break;
			case "zip": sortClientsByProp(clonedFilteredCompanies, 'zip', isSortingOrderAscending); break;
			case "numAccounts": sortClientsByNumericProp(clonedFilteredCompanies, 'numAccounts', isSortingOrderAscending); break;
			case "showPrices": sortClientsByNumericProp(clonedFilteredCompanies, 'showPrices', isSortingOrderAscending); break;
			default:
		}
		if (numCompanies > clientCompanyCountPerPage) {
			splitCompaniesByPage(clonedFilteredCompanies);
		} else {
			if (isPaginationShown) {
				setPaginationShown(false);
			}
			setPagedFilteredSortedCompanies(clonedFilteredCompanies);
		}
	}, [allCompanies, clientCompanyCountPerPage, isPaginationShown, isSortingCriteriaApplied,
		isSortingOrderAscending, setTotalNumberClientsCompany, splitCompaniesByPage]);

	return (
		<div className="dashboard_block">
			<div className="dashboard_block__heading">
				<BlockHeading
					title={t('adminDashboardPage:clientsTitle')}
					iconName="user"
				/>
				<div className="dashboard_block__header_container">
					<div className="dashboard_block__field">
						<FormNumEntriesSelector
							id="clientsNumEntries"
							defaultValue={entriesPerPageOptions.find((o) => o.value === clientCompanyCountPerPage)}
							options={entriesPerPageOptions}
							onChange={(selectedOption) => {
								setClientCompanyCountPerPage(selectedOption.value);
							}}
						/>
					</div>
					<div className="dashboard_block__field">
						<FormSearchField
							id="clientSearchField"
							placeholder={t('searchField')}
							name="clientSearchField"
							onChange={handleOnChangeSearchInput}
						/>
					</div>
				</div>
			</div>
			{pagedFilteredSortedCompanies && pagedFilteredSortedCompanies.length > 0 ? (
				<ul className="clients_list">
					{pagedFilteredSortedCompanies.map(({
						showPrices,
						customerReference,
						address,
						city,
						creatorCompanyEmail,
						id,
						name,
						tel,
						zip,
						language,
						numAccounts,
					}) => {
						return (
							<li className="clients_list__item" key={id}>
								<ClientBlock
									showPrices={showPrices || false}
									setShowPrices={setShowPrices}
									customerReference={customerReference}
									setCustomerReference={setCustomerReference}
									address={address}
									city={city}
									email={creatorCompanyEmail}
									id={id}
									name={name}
									tel={tel}
									zip={zip}
									language={language}
									numAccounts={numAccounts}
									notifyCompanyDeleted={notifyCompanyDeleted}
									handleSort={handleSort}
								/>
							</li>
						);
					})}
				</ul>
			) : (allCompanies ? null : <Spinner darkTheme />)}
			{pagedFilteredSortedCompanies && isPaginationShown && !isSearchApplied ? (
				<PaginationBlock
					activePaginationPage={activePaginationPage}
					itemsCountPerPage={clientCompanyCountPerPage}
					totalItemsCount={totalClientsCompanyItemsCount}
					handlePaginationPageChange={handlePaginationPageChange}
				/>
			) : null}
		</div>
	);
};

export default AdminClientsList;
