import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { WorkflowNavigation } from 'components/WorkflowNavigation';
import { PageNavigation } from 'components/PageNavigation';

const PageNavHeading = () => {
	const location = useLocation();
	const pn = location.pathname;

	const isWorkflowNavigationVisible = useMemo(() => !(
		pn === '/account'
		|| pn === '/admin-dashboard'
		|| pn.startsWith('/client-dashboard/')
		|| pn === '/dashboard'
	), [pn]);

	return (
		<div className="page_nav_heading">
			{isWorkflowNavigationVisible
				? <WorkflowNavigation />
				: null}
			<PageNavigation showConfiguration={!isWorkflowNavigationVisible} />
		</div>
	);
};

export default PageNavHeading;
