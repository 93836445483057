import React from 'react';

const FormSearchField = ({
	id,
	placeholder = 'placeholder',
	name,
	type = 'text',
	required,
	onChange,
}) => {
	return (
		<div className="form_search_field">
			<input
				className="form_search_field__input"
				id={id}
				type={type}
				name={name}
				placeholder={placeholder}
				required={required}
				onChange={onChange}
			/>
		</div>
	);
};

export default FormSearchField;
