import AbstractFilter from './AbstractFilter.js'

export default class ApplicationFilter extends AbstractFilter {
	constructor() {
		super("ApplicationFilter", false);

		this.glassStructure = '1-glazed';

		this.i18n_prefix = 'application_';
		this.filterOptions = {
			"Inside": "in",
			"Inside/Outside": "inOut",
			"Outside/Outside": "outOut",
		};
	}

	applyFilter(dbView) {
		super.applyFilter(dbView);
		if (this.selectedFilter === null) {
			return;
		}

		let in_filter = this.selectedFilter;
		let out_filter = this.selectedFilter;
		if (this.glassStructure !== '1-glazed' && this.selectedFilter === 'Inside/Outside') {
			in_filter = 'Inside'; // Force an inside glass on the inside since it's cheaper than a VSG one
		} else if (this.selectedFilter === 'Outside/Outside') {
			// requires two Inside/Outside glasses
			in_filter = 'Inside/Outside';
			out_filter = 'Inside/Outside';
		}

		for (var i = 0; i < dbView['frg'].length; ++i) {
			if (dbView['frg'][i].application !== in_filter) {
				dbView['frgFiltered'].push(dbView['frg'].splice(i, 1)[0]);
				i -= 1;
			}
		}

		for (var i = 0; i < dbView['out'].length; ++i) {
			if (dbView['out'][i].application !== out_filter) {
				dbView['outFiltered'].push(dbView['out'].splice(i, 1)[0]);
				i -= 1;
			}
		}
	}
}

