import {
  Object3D,
  Vector2
} from 'three';

import {Text2D} from 'ohzi-core';


export default class OhziLabels extends Object3D
{

  constructor(text, size, color = 0x999999) 
  {
    super();
    let font = '200px Arial';
    this.label = new Text2D(text, font, color, new Vector2(1,0), false);
    this.label.rotation.y = Math.PI * 0.5;
    this.label.size = size;
    this.add(this.label);
  }

  set text(text) 
  {
    this.label.text = text;
  }

}