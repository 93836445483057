import GlassStructure from './Glass/GlassStructure.js';
import GlassDB from './GlassDB.js';
import GlassDBLoader from './GlassDBLoader.js';
import FilterUIElement from './elementsUI/FilterUIElement.js';
import Insulation from './Glass/InsulationValues.js';
import SoundDampening from './Glass/SoundDampening.js';
import GlassChangeTracker from './Glass/ChangeTracker.js';


export default class UIHandler {
  constructor(filterHandler, webglComponent, context, updateUIGlassStructure) {
    this.glassDB = new GlassDB();
    this.dbView = null; // created when all elements are loaded
    this.structure = new GlassStructure(webglComponent);
    this.onDbLoadedSubscribers = []; // DB is loaded (UI loading phase 1 complete)
    this.onUiLoadedSubscribers = []; // Configurator UI is loaded (UI loading phase 3 complete)
    // Loading Phases:
    // 0: not started,
    // 1: db loaded,
    // 2: ui loaded,
    // 3: external UI params loaded (datasheets, sound dampening)
    this._loadingPhase = 0;

    // Map filters to their UI handlers (mapping is registered when creating the FilterUIElement)
    // filter.name => filterUIElement
    this.uiFilterMap = {};

    this.i18n = {
      language: 'en_US',
      onLanguageChangeSubscribers: [],
      t: (str, section) => {
        try {
          return this.i18n.translation[this.i18n.language][section][str];
        } catch {
          return str;
        }
      },
      translation: {},
      setLanguage: (lang) => {
        if (lang.startsWith('en')) {
          lang = 'en_US';
        }
        if (lang === this.i18n.language) {
          return;
        }
        this.i18n.language = lang;
        this.i18n.onLanguageChangeSubscribers.forEach((sub) => sub.onLanguageChange(lang));
        window.glass_ViewApi.setTranslation({
          MeasurementArrows_label_format: this.i18n.t('measurementArrowsLabelFormat', 'visualizerComponent'),
        });
      },
    };
    Object.keys(context.i18n.store.data).map(lang => this.i18n.translation[lang] = context.i18n.getDataByLanguage(lang));
    this.i18n.setLanguage(context.i18n.language);

    this.changeTracker = new GlassChangeTracker(this);
    this.filterHandler = filterHandler;
    this.webglComponent = webglComponent;

    this.uiElementsToLoad = new Set([
      'frg',
      'mid',
      'out',
      'spacer1',
      'gas1',
      'manufacturer',
      'fire-resistance-class',
      'structure',
      'insulation',
      'application',
      'white-glass',
      'resistance-class',
      'butt-joints',
      'sound',
    ]);

    this.spacer1UIElement = null;
    this.gas1UIElement = null;

    this.uiFireResistant = null;
    this.uiMiddleGlass = null;
    this.uiOutsideGlass = null;

    this.structureFilterUIElement = null;
    this.manufacturerFilterUIElement = null;
    this.fireClassFilterUIElement = null;
    this.insulationFilterUIElement = null;
    this.applicationFilterUIElement = null;
    this.whiteGlassFilterUIElement = null;
    this.buttJointsFilterUIElement = null;
    this.resistanceClassFilterUIElement = null;
    this.soundFilterUIElement = null;

    // this.warningUIElement = null;

    this.updateUIGlassStructure = updateUIGlassStructure;
    this.setLoadingPhase();
  }

  setLoadingPhase() {
    let phaseChange = false;
    if (this._loadingPhase === 0 && this.isDbLoaded) {
      this._loadingPhase = 1;
      phaseChange = true;
    }
    if (this._loadingPhase === 1) {
      // DB Loading Complete
      if (phaseChange) {
        this.onDbLoadedSubscribers.map(o => o(this.glassDB));
        this.recreateGlassUI();
        this.recreateFilterUI();
      }
      if (this.isUiLoaded) {
        this._loadingPhase = 2;
        phaseChange = true;
      }
    }
    if (this._loadingPhase === 2) {
      // UI Loading Complete

      // Check if we're ready for the next phase
      if (this.glassDB.db['datasheets'].loaded
        && SoundDampening.soundDampeningTable.loaded) {
        this._loadingPhase = 3;
        phaseChange = true;
      }
    }
    if (this._loadingPhase === 3) {
      // Extended UI Loading Complete (with datasheets and sound dampening)
      if (phaseChange) {
        this.onUiLoadedSubscribers.map(o => o());
      }
    }
  }

  subscribeConfiguratorUILoaded(cb) {
    if (this._loadingPhase === 3) {
      cb(); // dispatch cb right away if ready
    } else {
      this.onUiLoadedSubscribers.push(cb);
    }
  }

  setGlassUiElement(ui, glassLabel) {
    if (glassLabel === 'frg') {
      this.uiFireResistant = ui;
    } else if (glassLabel === 'mid') {
      this.uiMiddleGlass = ui;
    } else if (glassLabel === 'out') {
      this.uiOutsideGlass = ui;
    }
    ui.setDisabled(false);
    this.uiElementsToLoad.delete(glassLabel);
    this.setUiElement(ui, glassLabel); // ensure that onUiLoaded is called
  }

  /**
   * Register the UI Element with UIHandler
   * This is also where the UI element is initialized by (this) UIHandler
   */
  setUiElement(ui, elementId) {
    if (elementId === 'gas1') {
      this.gas1UIElement = ui;
      this.uiElementsToLoad.delete(elementId);

    } else if (elementId === 'spacer1') {
      let unique_spcr = new Set();
      this.glassDB.db.uValue['2-glazed'].map(v => { unique_spcr.add(v.spacer1); });
      this.glassDB.db.uValue['3-glazed'].map(v => { unique_spcr.add(v.spacer1); });
      let spacers = [...unique_spcr].sort((a, b) => a - b);
      ui.setSpacerOptions(spacers);

      this.spacer1UIElement = ui;
      this.uiElementsToLoad.delete(elementId);
    } // else if (elementId === 'warning') {
    //   this.warningUIElement = ui;
    // }

    if (ui instanceof FilterUIElement) {
      if (ui.props.id === 'manufacturer') {
        ui.setFilterHandler(this.filterHandler.manufacturerFilter)
        this.manufacturerFilterUIElement = ui;
      } else if (ui.props.id === 'fire-resistance-class') {
        ui.setFilterHandler(this.filterHandler.fireClassFilter)
        this.fireClassFilterUIElement = ui;
      } else if (ui.props.id === 'structure') {
        ui.setFilterHandler(this.filterHandler.structureFilter)
        this.structureFilterUIElement = ui;
      } else if (ui.props.id === 'insulation') {
        ui.setFilterHandler(this.filterHandler.insulationFilter)
        this.insulationFilterUIElement = ui;
      } else if (ui.props.id === 'application') {
        ui.setFilterHandler(this.filterHandler.applicationFilter)
        this.applicationFilterUIElement = ui;
      } else if (ui.props.id === 'white-glass') {
        ui.setFilterHandler(this.filterHandler.whiteGlassFilter)
        this.whiteGlassFilterUIElement = ui;
      } else if (ui.props.id === 'resistance-class') {
        ui.setFilterHandler(this.filterHandler.resistanceClassFilter)
        this.resistanceClassFilterUIElement = ui;
      } else if (ui.props.id === 'butt-joints') {
        ui.setFilterHandler(this.filterHandler.buttJointsFilter)
        this.buttJointsFilterUIElement = ui;
      } else if (ui.props.id === 'sound') {
        ui.setFilterHandler(this.filterHandler.soundFilter)
        this.soundFilterUIElement = ui;
      }
      this.uiFilterMap[ui.filter.filterName] = ui;
      if (ui.props.id !== 'sound') {
        // CDR: For sound, UI is always off for now, maybe active for mono later
        // definitely disabled for 2-glazed or 3-glazed
        ui.setDisabled(false);
      }
      this.uiElementsToLoad.delete(ui.props.id);
    }

    this.setLoadingPhase();
  }

  get isUiLoaded() {
    return this.uiElementsToLoad.size === 0;
  }

  get isDbLoaded() {
    return this.glassDB.loaded;
  }

  loadDb() {
    GlassDBLoader.loadDb(this.glassDB, () => {
      // Get all unique U-Values/spacer for the dropdowns
      const glassDB = this.glassDB;
      let unique_uval = new Set();
      glassDB.db.uValue['1-glazed'].map(v => unique_uval.add(v.u_value));
      glassDB.db.uValue['2-glazed'].map(v => { unique_uval.add(v.uValue); });
      glassDB.db.uValue['3-glazed'].map(v => { unique_uval.add(v.uValue); });

      this.filterHandler.insulationFilter.setUValueOptions([...unique_uval].sort((a, b) => b - a)); // reverse sort

      this.resetGlass(false);
      glassDB.loaded = true;

      this.changeTracker.setRefreshSpacerAndGasUValues();
      this.setLoadingPhase();
    });
  }

  setDatasheets(datasheets) {
    const ds = this.glassDB.db['datasheets'];
    ds['frg'] = datasheets.datasheets_frg || [];
    ds['mid'] = datasheets.datasheets_mid || [];
    ds['out'] = datasheets.datasheets_out || [];
    if (this.isDbLoaded) {
      GlassDBLoader.linkDatasheets(this.glassDB);
    } else {
      this.onDbLoadedSubscribers.push(GlassDBLoader.linkDatasheets);
    }
    this.setLoadingPhase();
  }

  setSoundValues(soundDampeningTable, genericFRGs) {
    SoundDampening.soundDampeningTable = soundDampeningTable;
    SoundDampening.genericFRGs = genericFRGs;
    const soundFilter = this.filterHandler.soundFilter;
    soundFilter.setSoundValues(SoundDampening.getAllUniqueValues());
    // set the filter handler again to retrigger option (re)generation
    const sdv = this.structure.fireResistantGlass
      ? SoundDampening.getCanonicalSoundValue(this.structure)
      : null;
    this.uiFilterMap[soundFilter.filterName].setFilterHandler(soundFilter, sdv);
    SoundDampening.soundDampeningTable.loaded = true;
    this.setLoadingPhase();
  }

  translateFilter(filterName) {
    return this.i18n.t(filterName, 'filterName');
  }

  /// Reset the glass configurator to its initial configuration
  /// Also called by popup through main.js function export
  resetGlass(update_ui = true) {
    this.changeTracker.reset(true, true);
    this.changeTracker.setGlassStructure('1-glazed');
    this.changeTracker.setGlass('frg', this.glassDB.db.frg[0]);
    this.changeTracker.setGlass('mid', null); // set the empty glass to trigger the UI refresh
    this.changeTracker.setGlass('out', null); // set the empty glass to trigger the UI refresh
    this.changeTracker.resetAllFilters();
    this.changeTracker.setFilter(this.filterHandler.structureFilter, true);
    this.commitChanges(false);

    if (update_ui) {
      this.uiMiddleGlass.setDisabled(false);
      // disable outside glass on 1-glazed structure
      this.uiOutsideGlass.setDisabled(false);
    }
  }

  applyFlatSnapshot(flatSnapshot) {
    this.resetGlass();
    this.structure.applyFlatSnapshot(flatSnapshot, this.glassDB);

    this.recreateGlassUI();
    this.recreateFilterUI();
    this.changeTracker.reset();
  }

  /// Confirm the pending changes
  confirmFilterChanges() {
    if (this.isUiLoaded) {
      this.recreateGlassUI();
      this.recreateFilterUI();
    }
    this.changeTracker.reset();
    if (this.updateUIGlassStructure) {
      this.updateUIGlassStructure(this.structure.getFlatSnapshot(this.glassDB));
    }
  }

  /// Called by FilterUIElement
  onFilterChanged(filter, previouslySet) {
    this.changeTracker.setUserFilter(filter, previouslySet);
    this.changeTracker.setTriggeringFilter(filter);
    this.commitChanges();
  }

  /// Called by SpacerUIElement
  onSpacerChanged(spacerId, spacer_value_mm) {
    if ((spacer_value_mm === 0 && !this.structure.spacerOutside) || spacer_value_mm === this.structure.spacerOutside.thickness) {
      return false; // no change
    }
    let skipSetSpacer = false;
    let glassStruct = this.structure.getGlassStructure();
    if (this.filterHandler.insulationFilter.selectedFilter) {
      if (!this.dbView['uValue'][glassStruct].find(v => v.spacer1 === spacer_value_mm)) {
        if (glassStruct === '1-glazed') {
          this.changeTracker.setGlassStructure('2-glazed');
          skipSetSpacer = true;
        } else if (glassStruct === '2-glazed') {
          this.changeTracker.setGlassStructure('3-glazed');
          skipSetSpacer = true;
        } else {
          this.changeTracker.resetFilter(this.filterHandler.insulationFilter, true);
        }
      }
    }

    if (!skipSetSpacer) {
      if (spacerId === 'spacer1') {
        this.changeTracker.setSpacer(spacer_value_mm);
        if (glassStruct === '1-glazed') {
          this.changeTracker.setFirstGlass('out');
        }
      } else {
        if (glassStruct === '3-glazed') {
          this.changeTracker.setSpacer(spacer_value_mm);
        } else if (glassStruct === '2-glazed') {
          this.changeTracker.setFirstGlass('mid');
        }
      }
    }

    this.changeTracker.setRefreshSpacerAndGasUValues();
    this.commitChanges();
    return true;
  }

  /// Called by GasUIElement
  onGasChanged(_gasId, gas) {
    if (gas === this.structure.gasOutside) {
      return false; // no change
    }
    let glassStruct = this.structure.getGlassStructure();
    if (this.filterHandler.insulationFilter.selectedFilter) {
      // disable insulation filter if uValue of selected glass is too high
      let simStruct = this.structure.getSnapshot();
      simStruct.setSpacersAndGas({ gas1: gas, gas2: gas }); // we can set both gases since the calculation in ..
      let uValue = simStruct.getInsulationValue(); // .. this step will only use both if it's a 3-glazed setup
      if (uValue > 0 && uValue != this.filterHandler.insulationFilter.uValue) {
        this.changeTracker.resetFilter(this.filterHandler.insulationFilter);
      }
      // A smarter logic to choose spacers from configurations that match the filter
      // let validConfigs = this.dbView['uValue'][glassStruct].find(v => v.gas === gas);
      // if (validConfigs) {
      //   let validSpacers = validConfigs;
      //   if (this.structure.spacerOutside && this.structure.spacerOutside.thickness > 0) {
      //     validSpacers = validSpacers.find(v => v.spacer1 === this.structure.spacerOutside.thickness);
      //   }
      //   if (this.structure.spacerMiddle && this.structure.spacerMiddle > 0) {
      //     validSpacers = validSpacers.find(v => v.spacer2 === this.structure.spacerMiddle.thickness);
      //   }
      //   if (!validSpacers) {
      //     this.spacer1UIElement.set(validConfigs[0].spacer1);
      //   }
      // } else {
      //   this.insulationFilterUIElement.set(null);
      // }
    }

    this.changeTracker.setGas(gas);

    if (glassStruct === '1-glazed') {
      this.changeTracker.setFirstGlass('out');
    }
    this.changeTracker.setRefreshSpacerAndGasUValues();
    this.commitChanges();
    return true;
  }

  /// Called by glass selection UI Element
  onGlassChanged(dbIndex, glassLabel) {
    let glass = this.structure.getGlassByLabel(glassLabel);
    let dbIndexInt = parseInt(dbIndex);
    if ((glass && glass.dbIndex === dbIndexInt) || (!glass && dbIndexInt === -1)) {
      return; // no change
    }
    if (dbIndexInt === -1) {
      // Deselecting a glass changes the glass structure
      if (glassLabel === 'out') {
        this.changeTracker.setGlassStructure('1-glazed');
      } else if (glassLabel === 'mid') {
        this.changeTracker.setGlassStructure('2-glazed');
      }
      let selectedStructFilter = this.filterHandler.structureFilter.selectedFilter;
      if (selectedStructFilter) {
        this.changeTracker.resetFilter(this.filterHandler.structureFilter);
        // this.setWarning("Structure filter removed due to removal of a glass");
      }
      this.commitChanges();
      return;
    }

    let dbo = this.glassDB.db[glassLabel][dbIndex];
    if (dbo === undefined) {
      console.error(`Glass not found: ${glassLabel}@${dbIndex}`);
      return;
    }

    if (!this.dbView[glassLabel].find(g => g === dbo)) {
      // find which filters block the glass selection and remove them
      let clearedFilters = [];

      // Create a new dbView for each filter, filter it and check if the glass is still available. If not, remove that filter.
      this.filterHandler.filters.forEach(f => {
        if (f.selectedFilter === null || f === this.filterHandler.structureFilter) {
          return;
        }
        let dbView = this.glassDB.createView();
        f.applyFilter(dbView);
        if (!dbView[glassLabel].find(g => g === dbo)) {
          this.changeTracker.resetFilter(f);
          clearedFilters.push(f);
        }
      });

      let clearedFilterStr = clearedFilters.reduce((acc, f) => {
        acc.push(f.filterName);
        return acc;
      }, []).join(', ');
      console.log("Reset filters due to incompatible selection: " + clearedFilterStr);
      // this.setWarning("Reset filters due to incompatible selection: " + clearedFilterStr);
    }

    if (glassLabel === 'frg') {
      if (dbo.white_glass && this.structure.getGlassStructure() === '1-glazed') {
        // if we select a white glass while in MONO, we also set the white glass filter
        this.filterHandler.whiteGlassFilter.setFilter('checked');
        this.changeTracker.setFilter(this.filterHandler.whiteGlassFilter);
      }
      // nothing to do, glass is applied below

    } else if (glassLabel === 'mid') {
      let selectedStructFilter = this.filterHandler.structureFilter.selectedFilter;
      if (selectedStructFilter === '1-glazed' || selectedStructFilter === '2-glazed') {
        this.changeTracker.resetFilter(this.filterHandler.structureFilter);
        // this.setWarning("Structure filter removed due to selection of a different glass structure");
      }
      this.changeTracker.setGlassStructure('3-glazed');

    } else if (glassLabel === 'out') {
      let selectedStructFilter = this.filterHandler.structureFilter.selectedFilter;
      if (selectedStructFilter === '1-glazed') {
        this.changeTracker.resetFilter(this.filterHandler.structureFilter);
        // this.setWarning("Structure filter removed due to selection of a different glass structure");
      }
      // if (dbo === undefined) {
      //   if (this.structure.middleGlass) {
      //     this.setWarning("Invalid configuration: Outer glass deselected while a middle glass is still selected.")
      //   }
      // }
    }
    this.changeTracker.setGlass(glassLabel, dbo);
    this.commitChanges();
  }

  /// Called by commitChanges() for changed filters
  _onCommitFilterChanged(filters) {
    // Structure Filter
    if (filters.includes(this.filterHandler.structureFilter)) {
      let filter = this.filterHandler.structureFilter;
      if (filter.selectedFilter) {
        this.changeTracker.setGlassStructure(filter.selectedFilter);
        // if (this.dbView['frg'].length === 0) {
        //   this.setWarning(`${filter.filterName} Filter removes all glass options. Please consider disabling other filters first.`);
        // }

        if (this.changeTracker.isInterIsoStructureChange()) {
          // Refresh spacers and gas when switching between 2- and 3-glazed
          this.changeTracker.setRefreshSpacerAndGasUValues();
        }
      }
    }

    // Manufacturer Filter
    if (filters.includes(this.filterHandler.manufacturerFilter)) {
      // Only FRG is manufacturer-bound
      this.changeTracker.setFirstGlass('frg');
      if (this.filterHandler.resistanceClassFilter.selectedFilter) {
        filters.push(this.filterHandler.resistanceClassFilter);
      }
    }

    // Fire Class Filter
    if (filters.includes(this.filterHandler.fireClassFilter)) {
      // Only FRG is Fire Resistance relevant
      this.changeTracker.setFirstGlass('frg');
    }

    // Insulation Filter
    if (filters.includes(this.filterHandler.insulationFilter)) {
      let filter = this.filterHandler.insulationFilter;
      if (filter.selectedFilter) {
        this.changeTracker.setGas(null);
        this.changeTracker.setSpacer(null);
        this.changeTracker.setFirstGasAndSpacer();
        this.changeTracker.setRefreshSpacerAndGasUValues();

        // let curStruct = this.structure.getGlassStructure(); // structure of the current glass (pre-filtering)
        // let curUval = this.structure.getInsulationValue(); // uValue of the current glass (pre-filtering)
        let reqUval = filter.uValue;
        // find the cheapest structure with that uValue
        // TODO: if a spacer/gas was manually set, use that, otherwise use the default
        let targetStruct = '1-glazed';
        let range = Insulation.getUValueRangeForStructure('1-glazed');
        if (range.min > reqUval) {
          targetStruct = '2-glazed';
          range = Insulation.getUValueRangeForStructure('2-glazed');
          if (range.min > reqUval) {
            targetStruct = '3-glazed';
            // range = Insulation.getUValueRangeForStructure('3-glazed');
          }
        } else {
          // 1-glazed always has U-Value 5.4, otherwise uncomment this:
          // this.changeTracker.setFirstGlass('frg');
        }
        this.changeTracker.setGlassStructure(targetStruct);
      }
    }

    // Application Filter
    if (filters.includes(this.filterHandler.applicationFilter)) {
      let filter = this.filterHandler.applicationFilter;
      this.filterHandler.resistanceClassFilter.application = filter.selectedFilter;
      if (filter.selectedFilter) {
        if (filter.selectedFilter === 'Outside/Outside') {
          if (!this.structure.outerGlass) {
            // Set the outer glass, if we're still mono-glazed
            this.changeTracker.setGlassStructure('2-glazed');
          }

        } else {
          if (!this.filterHandler.structureFilter.selectedFilter &&
            !this.filterHandler.resistanceClassFilter.selectedFilter &&
            !this.filterHandler.insulationFilter.selectedFilter) {
            // If no other filters prevent the change, reduce to 1-glazed if not already
            this.changeTracker.setGlassStructure('1-glazed');
          } // else {
          //   this.setWarning("Unselect Structure Filter, Resistance Class Filter and Insulation Filter to switch");
          // }
        }
      }
    }

    // White Glass Filter
    if (filters.includes(this.filterHandler.whiteGlassFilter)) {
      let glassStruct = this.changeTracker.getGlassStructure();
      switch (glassStruct) {
        // Fallthrough in all cases
        case '3-glazed': this.changeTracker.setFirstGlass('mid');
        case '2-glazed': this.changeTracker.setFirstGlass('out');
        default: this.changeTracker.setFirstGlass('frg');
      }
    }

    // RC Filter
    if (filters.includes(this.filterHandler.resistanceClassFilter)) {
      let filter = this.filterHandler.resistanceClassFilter.selectedFilter;
      if (filter) {
        let struct = this.changeTracker.getGlassStructure();
        let oldStruct = this.changeTracker.getPreviousGlassStructure();
        if ((struct === '1-glazed' && this.dbView['frg'].length === 0) || struct !== '1-glazed') {
          // if struct is not one-glazed and structure filter doesn't prohibit it, switch to MONO
          let manufacturerFilter = this.filterHandler.manufacturerFilter;
          let selectedManufacturerFilter = manufacturerFilter.selectedFilter;
          let insulationFilter = this.filterHandler.insulationFilter;
          if (oldStruct !== '1-glazed'
            && !this.filterHandler.structureFilter.selectedFilter
            // now we're looking for a 1-glazed option that fulfills the filters. If so we switch to MONO.
            && (this.glassDB.db['frg'].findIndex(
              o => (!selectedManufacturerFilter || o.manufacturer === selectedManufacturerFilter)
                && o.resistanceClass === filter
                && (insulationFilter.uValue === 0.0 || o.uValue <= insulationFilter.uValue)
            ) >= 0)) {
            if (selectedManufacturerFilter === 'N/A') {
              this.changeTracker.resetFilter(manufacturerFilter);
            }
            this.changeTracker.setGlassStructure('1-glazed');

          } else {
            this.changeTracker.setFirstGlass('out');
          }
        } // else if (struct === '1-glazed') { // not needed since we do it anyway right below
        // this.changeTracker.setFirstGlass('frg');
        // }

        this.changeTracker.setFirstGlass('frg', false); // override any current glass selection with a new default if no manufacturer filter is set
      }
    }

    // ButtJoint Filter
    if (filters.includes(this.filterHandler.buttJointsFilter)) {
      this.changeTracker.setGlassStructure('1-glazed');
      this.changeTracker.setFirstGlass('frg');
    }
  }

  commitChanges(allowUserPrompt = true) {
    // shortcut to retrigger filtering in the first loop rather than in the second when the structure changes
    if (this.changeTracker.getTriggeringFilter() === this.filterHandler.structureFilter) {
      this._onCommitFilterChanged(this.changeTracker.getNewFilters());
    }

    let numPass = 0;
    let redo;
    do {
      redo = false;

      // TODO: Optimize - if the structure changes, all filtering will happen in every subsequent loop.
      // Only doing it on the first iteration after a change should be enough.
      // The isChangedStructure() is used elsewhere too, so we'd need a separate one that resets itself on every call.
      let isChangedStructure = this.changeTracker.isChangedStructure();
      if (isChangedStructure) {
        let glassStruct = this.changeTracker.getGlassStructure();
        // Update resistance and application filters if the structure changes, but only refilter if it changes between mono and iso glazing
        this.filterHandler.resistanceClassFilter.glassStructure = glassStruct;
        this.filterHandler.applicationFilter.glassStructure = glassStruct;

        if (this.changeTracker.isMonoIsoStructureChange()) {
          this.changeTracker.setFilter(this.filterHandler.resistanceClassFilter, true);
          this.changeTracker.setFilter(this.filterHandler.applicationFilter, true);
          if (glassStruct !== '1-glazed' && this.filterHandler.soundFilter.selectValue) {
            // Reset SoundFilter on ISO
            this.changeTracker.resetFilter(this.filterHandler.soundFilter);
          }
        }
      }

      // Refilter the resistanceClassFilter if changing from Outside/Outside to something else or back while the resistanceClassFilter is active
      let oldNew = { 'old': null, 'new': null };
      if (this.changeTracker.didFilterChange(this.filterHandler.applicationFilter, oldNew)) {
        this.filterHandler.resistanceClassFilter.application = this.filterHandler.applicationFilter.selectedFilter;
        if (oldNew['old'] === 'Outside/Outside' || oldNew['new'] === 'Outside/Outside') {
          this.changeTracker.setFilter(this.filterHandler.resistanceClassFilter, true);
        }
      }
      if (this.changeTracker.didFilterChange(this.filterHandler.whiteGlassFilter, oldNew) && oldNew.new === null) {
        // CDR: Middle glass must be extrawhite when using a default 3-glazed ISO, so ensure the glasses are reset when the whiteglass filter is reset
        let glassStruct = this.changeTracker.getGlassStructure();
        if (glassStruct === '3-glazed') {
          this.changeTracker.setFirstGlass('frg', false);
          this.changeTracker.setFirstGlass('mid', false);
          this.changeTracker.setFirstGlass('out', false);

        } else if (glassStruct === '2-glazed') {
          this.changeTracker.setFirstGlass('frg', false);
          this.changeTracker.setFirstGlass('out', false);

        } else {
          this.changeTracker.setFirstGlass('frg', false);
        }
      }

      if (this.changeTracker.getResetAllFilters()) {
        // little hack to ensure that the code above's changes to filters doesn't make them appear as new filters
        // when we're trying to actually reset/disable all filters.
        this.changeTracker.resetAllFilters();
      }

      let resetFilters = this.changeTracker.getResetFilters();
      let newFilters = this.changeTracker.getNewFilters();

      if (resetFilters.length > 0) {
        resetFilters.forEach(f => f.setFilter(null));
      }

      if (resetFilters.length > 0 || this.changeTracker.getRefilterAll()) {
        // create a new view and rerun all filters
        this.dbView = this.glassDB.createView();
        this.filterHandler.run(this.dbView);
      } else if (newFilters.length > 0) {
        this.filterHandler.run(this.dbView, newFilters);
      }

      if (newFilters.length > 0) {
        this._onCommitFilterChanged(newFilters);
      }
      if (!isChangedStructure && this.changeTracker.isChangedStructure()) {
        console.debug("Structure changed during filter processing");
        redo = true;
        continue;
      }

      try {
        let frg = this.changeTracker.getChangedGlass('frg');
        if (frg !== undefined) {
          this.structure.setFireResistantGlass(frg);
        }
        let mid = this.changeTracker.getChangedGlass('mid');
        if (mid !== undefined) {
          this.structure.setMiddleGlass(mid);
        }
        let out = this.changeTracker.getChangedGlass('out');
        if (out !== undefined) {
          this.structure.setOuterGlass(out);
        }

        this.updateDefaultSpacersAndGas();

        let glassStruct = this.changeTracker.getGlassStructure();
        let opts = {};
        let { gas, spacer } = this.changeTracker.getChangedGasAndSpacer();
        if (spacer !== null) {
          opts['spacer1'] = (glassStruct === '1-glazed' ? 0 : spacer);
          opts['spacer2'] = (glassStruct === '3-glazed' ? spacer : 0);
        }
        if (gas !== null) {
          opts['gas1'] = (glassStruct === '1-glazed' ? 0 : gas);
          opts['gas2'] = (glassStruct === '3-glazed' ? gas : 0);
        }
        if (Object.keys(opts).length > 0) {
          this.structure.setSpacersAndGas(opts);
        }

        let insulationFilter = this.filterHandler.insulationFilter;
        let uValue = insulationFilter.uValue;
        if (uValue && this.structure.getInsulationValue() > uValue) {
          this.changeTracker.resetFilter(insulationFilter, true);
          redo = true;
        }
      } catch (e) {
        if (e instanceof GlassChangeTracker.NoDefaultGlassError) {
          // this.setWarning("No glass available. Resetting all filters");
          let triggeringFilter = this.changeTracker.getTriggeringFilter();
          if (triggeringFilter) {
            this.changeTracker.resetAllOtherFilters(triggeringFilter, true);
          } else {
            this.changeTracker.resetAllFilters(true);
          }
          redo = true;
        } else if (e instanceof GlassChangeTracker.NoDefaultSpacerOrGasError) {
          // this.setWarning("No spacer available for this U-Value. Resetting insulation filter");
          this.changeTracker.resetFilter(this.filterHandler.insulationFilter, true);
          redo = true;
        } else if (e instanceof GlassChangeTracker.GlassStructureChanged) {
          // this.setWarning("Glass structure changed");
          redo = true;
        }
      } finally {
        numPass += 1;
        if (numPass === 3) {
          this.changeTracker.resetAllOtherFilters(this.changeTracker.getTriggeringFilter(), true);
        }
      }
    } while (redo && numPass <= 3);
    if (redo) {
      console.warn("Commit() needed another pass");
    }

    if (allowUserPrompt) {
      let triggeringFilter = this.changeTracker.getTriggeringFilter();
      triggeringFilter = triggeringFilter?.filterName;
      if (this.changeTracker.getPromptUserReset()) {
        Object.keys(this.changeTracker._snapshot.userFilters)
          .filter((filterName) => filterName !== triggeringFilter)
          .map((filterName) => {
            let elem = this.uiFilterMap[filterName].ref.current.parentElement;
            elem?.classList.add('warn-flash');
            setTimeout(() => elem?.classList.remove('warn-flash'), 3000);
          });
      }
    }

    this.confirmFilterChanges();
  }

  updateDefaultSpacersAndGas() {
    let glassStruct = this.changeTracker.getGlassStructure();

    if (glassStruct === '1-glazed') {
      // Remove all spacers and gas
      this.changeTracker.setSpacer(0);
      this.changeTracker.setGas(0);

    } else {
      this.changeTracker.setFirstGasAndSpacer();
    }
  }

  // setWarning(warningMsg) {
  //   if (this.warningUIElement) {
  //     this.warningUIElement.setWarningMessage(warningMsg)
  //   }
  // }

  recreateGlassUI() {
    this.uiFireResistant.selectGlass(this.structure.fireResistantGlass);
    this.uiMiddleGlass.selectGlass(this.structure.middleGlass);
    this.uiOutsideGlass.selectGlass(this.structure.outerGlass);

    if (this.changeTracker.getRefreshSpacerAndGasUValues()) {
      if (this.filterHandler.insulationFilter.selectedFilter) {
        this.spacer1UIElement.refreshAvailableOptions();
        this.gas1UIElement.refreshAvailableOptions();
      } else {
        this.spacer1UIElement.resetFilteredOptions();
        this.gas1UIElement.resetFilteredOptions();
      }
    }

    let { gas, spacer } = this.changeTracker.getChangedGasAndSpacer();
    if (spacer !== null || this.changeTracker.getRefreshSpacerAndGasUValues()) {
      this.spacer1UIElement.recreate(this.structure.spacerOutside.thickness);
    }

    if (gas !== null) {
      this.gas1UIElement.recreate(gas);
    } else if (this.changeTracker.getRefreshSpacerAndGasUValues()) {
      this.gas1UIElement.recreate(this.structure.gasOutside);
    }

    if (this.changeTracker.isChangedStructure()) {
      let glassStruct = this.changeTracker.getGlassStructure();
      let noMidGlass = (glassStruct === '1-glazed' || glassStruct === '2-glazed');
      let noOutGlass = (glassStruct === '1-glazed');

      // disable spacer and gas selection on 1-glazed structure
      this.spacer1UIElement.setDisabled(noOutGlass);
      this.uiMiddleGlass.setDisabled(noMidGlass);
      // disable outside glass on 1-glazed structure
      this.uiOutsideGlass.setDisabled(noOutGlass);
      this.gas1UIElement.setDisabled(noOutGlass);
    }
  }

  recreateFilterUI() {
    this.structureFilterUIElement.selectValue(this.structure.getGlassStructure());
    this.fireClassFilterUIElement.selectValue(this.structure.fireResistantGlass.fireResistanceClass);
    this.manufacturerFilterUIElement.selectValue(this.structure.fireResistantGlass.manufacturer);
    this.insulationFilterUIElement.selectValue(this.structure.getFormattedInsulationValue());
    this.applicationFilterUIElement.selectValue(this.structure.insideOutsideApplication);
    this.whiteGlassFilterUIElement.selectValue(this.structure.isClearGlass() ? "checked" : "unchecked");
    this.resistanceClassFilterUIElement.selectValue(this.structure.getResistanceClass());
    this.buttJointsFilterUIElement.selectValue(this.structure.isButtJointCompatible() ? "checked" : "unchecked");
    const sdv = SoundDampening.getCanonicalSoundValue(this.structure);
    this.soundFilterUIElement.selectValue(sdv);
    // This was a debug feature used to highlight <select> boxes with filters that are currently active
    // Since the conversion to <Select> React classes, the AbstractUIElement would have to be slightly
    // adapted to set the className={className} prop on <Select> in render().
    // Since we don't need it now, we don't add it.
    // this.filterHandler.filters.forEach((f => {
    //   if (f.selectedFilter) {
    //     this.uiFilterMap[f.filterName].ref.current.addClassName('glass_product_filter_active');
    //   } else {
    //     this.uiFilterMap[f.filterName].ref.current.removeClassName('glass_product_filter_active');
    //   }
    // }));
  }
}