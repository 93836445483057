import { SceneManager } from 'ohzi-core';

import { Mesh } from 'three';
import { Shape } from 'three';
import { ExtrudeGeometry } from 'three';
import { BackSide } from 'three';
import { BoxGeometry } from 'three';
import { MeshBasicMaterial } from 'three';

import GlassFrontMaterial from '../materials/GlassFrontMaterial';
import TexturedGlassFrontMaterial from '../materials/TexturedGlassFrontMaterial';
import GlassBackMaterial from '../materials/GlassBackMaterial';
import GlassSideMaterial from '../materials/GlassSideMaterial';
import GenericStructureElement from './GenericStructureElement';

import AluminiumTapeMaterial from './../materials/AluminiumTapeMaterial';

import OhziLabels from './OhziLabels';

export default class GenericGlass extends GenericStructureElement
{

  constructor(structure, rt, render_order_offset, with_tape)
  {
    super();

    this.structure = structure;

    const alu_tape_thickness = with_tape ? 0.002 : 0;
    const bias = 0.0001;

    const extrude = 0.01;
    const length = 2, height = 2, cut = 1 - extrude * 2;
    const depth = 1;
    const shape = new Shape();
    shape.moveTo( 0 + alu_tape_thickness,0 );
    shape.lineTo( 0 + alu_tape_thickness, height - alu_tape_thickness - extrude * 2 );
    shape.lineTo( length - cut, height - alu_tape_thickness - extrude * 2 );
    shape.lineTo( (length - extrude * 2), height - alu_tape_thickness - extrude * 2 - cut );
    shape.lineTo( (length - extrude * 2), 0 );
    shape.lineTo( 0 + alu_tape_thickness, 0 );

    const extrudeSettings = {
      steps: 1,
      depth: depth - extrude * 2,
      bevelEnabled: true,
      bevelThickness: extrude,
      bevelSize: extrude,
      bevelOffset: 0,
      bevelSegments: 1
    };

    const main_geometry = new ExtrudeGeometry(shape, extrudeSettings );
    main_geometry.translate(extrude + alu_tape_thickness, extrude + alu_tape_thickness + bias, extrude);
    main_geometry.computeVertexNormals();
    this.frontMaterial = new GlassFrontMaterial(rt);
    this.side_material = new GlassSideMaterial(rt);
    this.texturedFrontMaterial = new TexturedGlassFrontMaterial();

    const backMaterial = new GlassBackMaterial();
    backMaterial.transparent = true;

    this.cube = new Mesh(main_geometry, [this.frontMaterial, this.side_material]);
    this.cube.renderOrder = 998;// + render_order_offset;

    this.frontMaterial_back = new GlassFrontMaterial(rt);
    this.side_material_back = new GlassSideMaterial(rt);
    this.frontMaterial_back.side = BackSide;
    this.side_material_back.side = BackSide;
    this.frontMaterial_back.transparent = true;
    this.side_material_back.transparent = true;

    this.cube_back = new Mesh(main_geometry, [this.frontMaterial_back, this.side_material_back]);
    this.cube_back.renderOrder = 998;// + render_order_offset;
    // above 999: inner glass frame is white
    // below 999: textures are not shown when viewed through other glasses, only when viewed directly

    let back_cube = this.cube.clone();
    back_cube.material = backMaterial;
    this.add(this.cube);
    this.cube.add(this.cube_back);

    // for the glass to cast shadows
    this.shadow_cube = this.cube_back.clone();
    this.shadow_cube.material = new MeshBasicMaterial({opacity : 0, transparent: true});
    this.shadow_cube.material.colorWrite = false;
    this.shadow_cube.material.depthWrite = false;
    this.shadow_cube.receiveShadow = true;
    this.shadow_cube.castShadow = true;

    SceneManager.backScene.add(back_cube);
    SceneManager.current.add(this.shadow_cube);

    // alu tape around frg
    if ( with_tape )
    {
      const alu_tape_geometry_x = new BoxGeometry(length, alu_tape_thickness, depth);
      alu_tape_geometry_x.translate(length * 0.5, alu_tape_thickness * 0.5, depth * 0.5);

      this.alu_tape = new Mesh(alu_tape_geometry_x, new AluminiumTapeMaterial());
      this.alu_tape.receiveShadow = true;
      this.alu_tape.castShadow = true;
      this.alu_tape.renderOrder = 1000 + render_order_offset;
      this.cube.add(this.alu_tape);

      const alu_tape_geometry_y = new BoxGeometry(length, alu_tape_thickness, depth);
      alu_tape_geometry_y.rotateZ(Math.PI * 0.5);
      alu_tape_geometry_y.translate(alu_tape_thickness * 0.5, length * 0.5, depth * 0.5);

      this.alu_tape_y = new Mesh(alu_tape_geometry_y, new AluminiumTapeMaterial());
      this.alu_tape_y.receiveShadow = true;
      this.alu_tape_y.castShadow = true;
      this.alu_tape_y.renderOrder = 1000 + render_order_offset;
      this.cube.add(this.alu_tape_y);
    }

    this.label = new OhziLabels('holis', 0.1, 0x92c7b4);
    this.label.position.y = -0.1;
    this.label.position.x = length;
    this.label.renderOrder = 1000000 + render_order_offset;
    SceneManager.textScene.add(this.label);

    this.set(0.01, ['.01:0']);
  }

  update()
  {
    this.side_material.update();
    this.frontMaterial.update();
  }

  fade_animation(lerp)
  {
    this.cube.position.y = lerp * 0.5;
    this.cube.position.x = lerp * 0.5;
    this.frontMaterial.opacity = 1 - lerp;
    this.side_material.opacity = 1 - lerp;
  }

  set(width, layers, flip) //
  {
    if ( !layers )
      layers = [width + ':0'];

    if(width < 1)
    {
      this.label.visible = false;
    }
    else
    {
      this.label.visible = true;
      this.label.text = '' + width.toFixed(0);
    }

    this._layers = layers;

    if (width < 0.01)
    {
      this.visible = false;
    }
    else
    {
      this.frontMaterial.glassThickness = width;
      this.set_front_material(layers);
      this.side_material.set(width, layers, flip);
      this.side_material_back.set(width, layers);
      this.visible = true;
    }

    super.set(width);

    this.shadow_cube.scale.z = this.scale.z;
    this.shadow_cube.position.z = this.position.z;
  }

  set_front_material(layers)
  {

    if (layers.find((layer)=>
    {
      if(layer.split(':')[1] == 100) {
        this.texturedFrontMaterial.set(0);
        return true;
      }
      if(layer.split(':')[1] == 101) {
        this.texturedFrontMaterial.set(1);
        return true;
      }
      if(layer.split(':')[1] == 102) {
        this.texturedFrontMaterial.set(2);
        return true;
      }
      if(layer.split(':')[1] == 103) {
        this.texturedFrontMaterial.set(3);
        return true;
      }
      return false;
    }))
    {
      this.cube.material[0] = this.texturedFrontMaterial;
    }
    else
    {
      this.cube.material[0] = this.frontMaterial;
    }
  }

  set shiftZ(shift)
  {
    super.shiftZ = shift;
    this.label.position.z = shift - (this.structure.structure_width / 2.0) + (this.frontMaterial.glassThickness / 325.0);
  }
}
