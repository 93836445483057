import RegisterNewCompanyUserForm from 'components/Form/RegisterNewCompanyUserForm';
import React from 'react';

const RegisterByInvintationPage = () => {
	return (
		<section className="section section--register_invitation">
			<div className="section__in">
				<RegisterNewCompanyUserForm
					limitContainerMod
				/>
			</div>
		</section>
	);
};

export default RegisterByInvintationPage;
