import { BaseShaderMaterial } from 'ohzi-core';
import fragment from '../shaders/GlassFrontShader.frag';
import vertex from '../shaders/GlassFrontShader.vert';
import DatGui from '../components/DatGui';
import { ResourceContainer } from 'ohzi-core';
import { Screen } from 'ohzi-core';

import { Vector2 } from 'three';
import { Color } from 'three';
import { FrontSide } from 'three';



export default class GlassFrontMaterial extends BaseShaderMaterial {
  constructor(rt) {
    super(vertex, fragment, {
      resolution: {
        value:
          new Vector2(Screen.width, Screen.height).multiplyScalar(
            window.devicePixelRatio
          )
      }
      ,
      backNormals: { value: rt.texture },
      envMap: { value: ResourceContainer.get_resource('cube') },
      color: { value: new Color(0xe0ffe0) },
      refractionIndex: { value: DatGui.settings.refractionIndex },
      dispersion: { value: DatGui.settings.dispersion },
      opacity: { value: DatGui.settings.opacity },
      roughness: { value: DatGui.settings.roughness },
      fesnel: { value: DatGui.settings.fesnel },
      glassThickness: { value: 0.0 },
      noise: { value: ResourceContainer.get_resource('noise') },
      timer: { value: 0 },
    });
    this.original_opacity = DatGui.settings.opacity;
    this.side = FrontSide;
    this.alphaTest = 0.0;
    this.transparent = true;
    this.needsUpdate = true;
  }

  set glassThickness(value) {
    if (this.uniforms) {
      // console.log(`Thickness: ${value}`);
      this.uniforms.glassThickness.value = value;
      this.uniforms.opacity.value = value * 0.1 * this.original_opacity;
    }
  }

  get glassThickness() {
    return this.uniforms.glassThickness.value;
  }

  set opacity(value) {
    if (this.uniforms)
      this.uniforms.opacity.value = value * this.original_opacity;
  }

  get opacity() { }

  update() {
    //if(this.uniforms.timer.value !== Time.elapsed_time)
    //  this.uniforms.timer.value = Time.elapsed_time;
    //if(this.uniforms.refractionIndex.value !== DatGui.settings.refractionIndex)
    //  this.uniforms.refractionIndex.value = DatGui.settings.refractionIndex;
    //if(this.uniforms.dispersion.value !== DatGui.settings.dispersion)
    //  this.uniforms.dispersion.value = DatGui.settings.dispersion;
    //if(this.uniforms.roughness.value !== DatGui.settings.roughness)
    //  this.uniforms.roughness.value = DatGui.settings.roughness;
    //if(this.uniforms.opacity.value !== DatGui.settings.opacity)
    //  this.uniforms.opacity.value = DatGui.settings.opacity;
    //if(this.uniforms.fesnel.value !== DatGui.settings.fesnel)
    //  this.uniforms.fesnel.value = DatGui.settings.fesnel;
    //if(this.uniforms.color.value !== DatGui.settings.color)
    //  this.uniforms.color.value.set(DatGui.settings.color);
  }

  //set(white) {
  //  if (white) {
  //    this.uniforms.color.value.setHex(0xe0e0e0)
  //  } else {
  //    this.uniforms.color.value.setHex(0xa0e0a0)
  //  }
  //}
}
