import React from "react";
import { View, Text } from '@react-pdf/renderer';

import styles from '../styles';

const labelStyles = {
	...styles.glassConfigTableCellTitle,
	...styles.glassConfigTableCellTitleOffsetMod,
};

const valueStyles = {
	...styles.glassConfigTableCellTitle,
	...styles.glassConfigTableCellTitleValueMod,
};

const PdfGlassConfigTotalCell = ({ label, value }) => {
	if (!label || !value) return null;

	return (
		<View style={styles.glassConfigTableTotalCell}>
			<Text style={labelStyles} className="glass_config_table__cell_title glass_config_table__notes_hdr">
				{label}
			</Text>
			<Text style={valueStyles}>{value}</Text>
		</View>
	);
};

export default PdfGlassConfigTotalCell;
