import { ResourceContainer } from 'ohzi-core';

import { Mesh } from 'three';
import { BoxGeometry } from 'three';
import { ShadowMaterial } from 'three';
import { PlaneGeometry } from 'three';
import { Shape } from 'three';
import { ExtrudeGeometry } from 'three';
import { MeshPhysicalMaterial } from 'three';

import { BufferGeometryUtils } from 'three/examples/jsm/utils/BufferGeometryUtils.js';

import GenericStructureElement from './GenericStructureElement';
import InsulationMaterial from './../materials/InsulationMaterial';

export default class SpacerSegment extends GenericStructureElement
{

	constructor(length) {
		super();

		this.depth = 1;

		const thickness = 0.1;
		// in decimeters
		const alu_thickness = 0.01;
		const alu_vertical = 0.04 - alu_thickness;
		const alu_sides = 0.0192;

		const alu_diagonal = Math.sqrt(Math.pow(alu_sides,2) + Math.pow(alu_sides,2));;
		const height_tilted_alu_thickness = Math.cos(0.25 * Math.PI) * alu_thickness;
		const height_tilted_alu_diagonal = Math.cos(0.25 * Math.PI) * alu_diagonal;
		this.alu_sides = alu_sides;

		const geometry_left_vertical = new BoxGeometry(length, alu_vertical, alu_thickness);
		const geometry_right_vertical = new BoxGeometry(length, alu_vertical, alu_thickness);
		geometry_left_vertical.translate(length * 0.5, - alu_vertical * 0.5, alu_thickness * 0.5);
		geometry_right_vertical.translate(length * 0.5, - alu_vertical * 0.5, -alu_thickness * 0.5 + alu_sides);

		const geometry_left_diagonal = new BoxGeometry(length, alu_diagonal, alu_thickness);
		const geometry_right_diagonal = new BoxGeometry(length, alu_diagonal, alu_thickness);
		geometry_left_diagonal.rotateX(-0.25 * Math.PI);
		geometry_right_diagonal.rotateX(0.25 * Math.PI);
		geometry_left_diagonal.translate(length * 0.5, -alu_vertical - height_tilted_alu_diagonal * 0.5 + height_tilted_alu_thickness * 0.5, height_tilted_alu_diagonal * 0.5 + height_tilted_alu_thickness * 0.5);
		geometry_right_diagonal.translate(length * 0.5, -alu_vertical - height_tilted_alu_diagonal * 0.5 + height_tilted_alu_thickness * 0.5, height_tilted_alu_diagonal * 0.5 - height_tilted_alu_thickness * 0.5);

		const geometry_left_side = new BoxGeometry(length, alu_thickness, alu_sides);
		const geometry_right_side = new BoxGeometry(length, alu_thickness, alu_sides);
		geometry_left_side.translate(length * 0.5, alu_thickness * 0.5, alu_sides * 0.5);
		geometry_right_side.translate(length * 0.5, alu_thickness * 0.5, alu_sides * 0.5);

		const side_left = BufferGeometryUtils.mergeBufferGeometries([
			geometry_left_vertical,
			geometry_left_diagonal,
			geometry_left_side,
			])

		const side_right = BufferGeometryUtils.mergeBufferGeometries([
			geometry_right_vertical,
			geometry_right_diagonal,
			geometry_right_side
			])

		side_left.translate(0, -alu_thickness, -this.alu_sides * 2);
		side_right.translate(0, -alu_thickness, -this.alu_sides * 2);

		const geometry_up_horizontal = new BoxGeometry(length, alu_thickness, this.depth);
		const geometry_down_horizontal = new BoxGeometry(length, alu_thickness, this.depth);

		geometry_up_horizontal.translate(length * 0.5, - alu_thickness * 0.5, 0);
		geometry_down_horizontal.translate(length * 0.5, - alu_thickness * 0.5 - alu_vertical - alu_sides, 0);

		const geometry_horizontal = BufferGeometryUtils.mergeBufferGeometries([
			geometry_up_horizontal,
			geometry_down_horizontal
			])

		side_left.translate(0,0.1,0);
		side_right.translate(0,0.1,0);
		geometry_horizontal.translate(0,0.1,0);
		
		const shape_main_plastic = new Shape();
    	shape_main_plastic.moveTo( 0, 0 );
    	shape_main_plastic.lineTo( 0, thickness * 0.4 );
    	shape_main_plastic.lineTo( this.depth, thickness * 0.4 );
    	shape_main_plastic.lineTo( this.depth, 0 );

    	const number_of_cuts = 32;
    	for ( let i = 0; i < number_of_cuts; i++ ) {
    		let alpha = i / number_of_cuts;
    		shape_main_plastic.lineTo( this.depth * (1 - alpha), Math.sin(alpha * Math.PI) * 0.02 );
    	}

    	const extrude_settings_main_plastic = {
    	  steps: 1,
    	  depth: length,
    	  bevelEnabled: false,
    	};

    	const geometry_plastic_below = new ExtrudeGeometry(shape_main_plastic, extrude_settings_main_plastic );
    	geometry_plastic_below.rotateY(Math.PI * 0.5);
		geometry_plastic_below.translate(0, 0, this.depth);
    	geometry_plastic_below.computeVertexNormals();


		const plastic_material = new MeshPhysicalMaterial( {
			roughness : 0.5,
			metalness : 0.1,
			reflectivity : 0.12,
			clearcoat : 0.59,
			clearcoatRoughness : 0.44,
			color : 0x505050
		});

		const alu_material = new MeshPhysicalMaterial( {
			roughness : 0.9,
			metalness : 0.1,
			reflectivity : 0.20,
			clearcoat : 0.20,
			clearcoatRoughness : 0.5,
			color : 0x0f0f0f
		});

		this.spacer_side_left = new Mesh(side_left, alu_material);
		//this.spacer_side_left.receiveShadow = true;
		this.spacer_side_left.castShadow = true;
		this.spacer_side_left.renderOrder = 1001;
		this.add(this.spacer_side_left);

		this.spacer_side_right = new Mesh(side_right, alu_material);
		//this.spacer_side_right.receiveShadow = true;
		this.spacer_side_right.castShadow = true;
		this.spacer_side_right.renderOrder = 1001;
		this.add(this.spacer_side_right);

		this.spacer_horizontal = new Mesh(geometry_horizontal, alu_material);
		//this.spacer_horizontal.receiveShadow = true;
		this.spacer_horizontal.castShadow = true;
		this.spacer_horizontal.renderOrder = 1001;
		this.add(this.spacer_horizontal);

		this.spacer_plastic_below = new Mesh(geometry_plastic_below, plastic_material);
		//this.spacer_plastic_below.receiveShadow = true;
		this.spacer_plastic_below.castShadow = true;
		this.spacer_plastic_below.renderOrder = 1001;
		this.add(this.spacer_plastic_below);

		const shape = new Shape();
    	shape.moveTo( alu_sides, alu_sides );
    	shape.lineTo( alu_sides, 0 );
    	shape.lineTo( 0, 0 );

    	const extrudeSettings = {
    	  steps: 1,
    	  depth: length,
    	  bevelEnabled: false,
    	};

    	const triangle_geometry = new ExtrudeGeometry(shape, extrudeSettings );
    	triangle_geometry.rotateY(Math.PI * 0.5);
    	triangle_geometry.translate(0, thickness * 0.4, 0);
    	triangle_geometry.computeVertexNormals();

    	this.triangle_left = new Mesh(triangle_geometry, plastic_material);
    	this.triangle_left.position.z = -alu_sides;
		this.triangle_left.castShadow = true;
		this.triangle_left.renderOrder = 1001;
		this.spacer_side_left.add(this.triangle_left);

		this.triangle_right = new Mesh(triangle_geometry, plastic_material);
		//this.triangle_right.rotation.y = Math.PI;
    	this.triangle_right.position.z = -alu_sides * 2;
		this.triangle_right.scale.z = -1;
		this.triangle_right.castShadow = true;
		this.triangle_right.renderOrder = 1001;
		this.spacer_side_right.add(this.triangle_right);

		this.styrofoam_material = new InsulationMaterial();
		
		const geometry_styrofoam = new PlaneGeometry(this.depth * 0.9, thickness * 0.5);
		geometry_styrofoam.rotateY(Math.PI * 0.5);

		geometry_styrofoam.translate(length - 0.01, thickness * 0.65, this.depth * 0.5);

		const styrofoam = new Mesh(geometry_styrofoam, this.styrofoam_material);//this.styrofoam_material);
		this.spacer_plastic_below.add(styrofoam);
	}

	set(width)
	{	
		width *= .01;
		this.depth = width;
		if (width === 0) {
			this.visible = false;
		} else {
			//let ratio =  (((this.depth * 0.9 )) * width) * 50   0.05 / ;
			let ratio = 1.5 * ((this.depth * 0.9 ) / 0.05);
			this.visible = true;

			this.styrofoam_material.set_size((ratio * 0.25) / 1.08, 1 * 0.75);
			this.spacer_horizontal.scale.z = (width) - this.alu_sides * 2;
			this.spacer_plastic_below.scale.z = width;
			this.spacer_horizontal.position.z = width * 0.5;
			this.spacer_side_right.position.z = this.spacer_horizontal.scale.z + this.alu_sides * 3;
			this.spacer_side_left.position.z = this.alu_sides * 2;
		}
	}

	get width() {
		return this.depth;
	}

	update() {
		//this.alu_material.update()
	}
}


