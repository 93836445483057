import React, {
	useCallback,
	useContext,
	useEffect,
	useRef,
	useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
	collection,
	onSnapshot,
	query,
	where,
} from 'firebase/firestore';

import { DashboardBlock, AdminDashboardCompanyInfo } from 'components/Dashboard';
import { AdminClientsList } from 'components/AdminClientsList';
import { AuthContext } from 'context/auth';
import ToastifyHandler from 'utils/ToastifyHandler';
import {
	CONFIGURATION_TYPE,
	CONFIGURATION_STATUS,
	CONFIGURATIONS_COLLECTION_NAME,
} from 'utils/constant';
import { firebaseDB } from 'utils/firebase';
import { cloneObjectWithoutReference } from 'utils';

const AdminDashboardPage = () => {
	const { t } = useTranslation(['commonAppValues', 'firebaseErrorMessages']);
	const { currentUserAdditionalData } = useContext(AuthContext);
	const notificationsHandler = useRef(new ToastifyHandler());

	const [allCompaniesConfigurations, setAllCompaniesConfigurations] = useState(null);

	const [companiesRequestsConfigurations, setCompaniesRequestsConfigurations] = useState(null);
	const [companiesOrdersConfigurations, setCompaniesOrdersConfigurations] = useState(null);
	const [companiesOpenRequestsConfigurations, setCompaniesOpenRequestsConfigurations] = useState(null);
	const [companiesOpenOrdersConfigurations, setCompaniesOpenOrdersConfigurations] = useState(null);

	const [numberOfConfigurationsByType, setNumberOfConfigurations] = useState(null);

	const subscribeToListenConfigurationsChanges = useRef(null);

	const listenCompanyConfigurationsChanges = () => {
		const companyConfigurationsRef = collection(firebaseDB, CONFIGURATIONS_COLLECTION_NAME);

		const queryCompanyCollection = query(companyConfigurationsRef, where('type', '!=', 'drafts'));

		subscribeToListenConfigurationsChanges.current = onSnapshot(queryCompanyCollection, (querySnapshot) => {
			const allConfigurations = [];

			querySnapshot.forEach((doc) => {
				allConfigurations.push(doc.data());
			});

			setAllCompaniesConfigurations(allConfigurations);
		}, (error) => {
			notificationsHandler.current.error(error.message);
		});
	};

	useEffect(() => {
		listenCompanyConfigurationsChanges();
	}, []);

	useEffect(() => {
		const splitConfigurationsByType = () => {
			const openRequestsConfigurations = [];
			const requestsConfigurations = [];
			const openOrdersConfigurations = [];
			const ordersConfigurations = [];

			allCompaniesConfigurations.forEach(companyConfiguration => {
				switch (companyConfiguration.type) {
					case CONFIGURATION_TYPE.request:
						if (companyConfiguration.status === CONFIGURATION_STATUS.open) {
							openRequestsConfigurations.push(companyConfiguration);
						} else {
							requestsConfigurations.push(companyConfiguration);
						}

						break;

					case CONFIGURATION_TYPE.order:
						if (companyConfiguration.status === CONFIGURATION_STATUS.open) {
							openOrdersConfigurations.push(companyConfiguration);
						} else {
							ordersConfigurations.push(companyConfiguration);
						}
						break;

					default:
						break;
				}
			});

			setCompaniesOpenRequestsConfigurations(openRequestsConfigurations);
			setCompaniesRequestsConfigurations(requestsConfigurations);
			setCompaniesOpenOrdersConfigurations(openOrdersConfigurations);
			setCompaniesOrdersConfigurations(ordersConfigurations);

			setNumberOfConfigurations({
				openRequests: openRequestsConfigurations.length,
				requests: requestsConfigurations.length,
				openOrders: openOrdersConfigurations.length,
				orders: ordersConfigurations.length,
			});
		};

		if (allCompaniesConfigurations) {
			splitConfigurationsByType();
		}
	}, [allCompaniesConfigurations]);

	const setTotalNumberClientsCompany = useCallback((totalNumberClientsCompany) => {
		if (!numberOfConfigurationsByType) return;
		if (totalNumberClientsCompany === null || totalNumberClientsCompany === 'undefined') return;

		const clonedNumberOfConfigurationsByType = cloneObjectWithoutReference(numberOfConfigurationsByType);
		clonedNumberOfConfigurationsByType.companies = totalNumberClientsCompany;
		setNumberOfConfigurations(clonedNumberOfConfigurationsByType);
	}, [numberOfConfigurationsByType, setNumberOfConfigurations]);

	useEffect(() => {
		return () => {
			if (subscribeToListenConfigurationsChanges.current) {
				subscribeToListenConfigurationsChanges.current();
			}
		};
	}, []);

	const companyData = currentUserAdditionalData?.company;
	return (
		companyData ? (
			<section className="section dashboard">
				<div className="section__in">
					<ul className="dashboard__list">
						<li className="dashboard__item">
							<AdminDashboardCompanyInfo
								companyName={companyData.name}
								numberOfConfigurationsByType={numberOfConfigurationsByType}
							/>
						</li>
						{companiesOpenRequestsConfigurations && companiesOpenRequestsConfigurations.length ? (
							<li className="dashboard__item">
								<DashboardBlock
									titleLabel={t('openRequests')}
									configurationData={companiesOpenRequestsConfigurations}
								/>
							</li>
						) : null}
						{companiesOpenOrdersConfigurations && companiesOpenOrdersConfigurations.length ? (
							<li className="dashboard__item">
								<DashboardBlock
									titleLabel={t('openOrders')}
									configurationData={companiesOpenOrdersConfigurations}
								/>
							</li>
						) : null}
						{companiesRequestsConfigurations && companiesRequestsConfigurations.length ? (
							<li className="dashboard__item">
								<DashboardBlock
									titleLabel={t('requests')}
									configurationData={companiesRequestsConfigurations}
								/>
							</li>
						) : null}
						{companiesOrdersConfigurations && companiesOrdersConfigurations.length ? (
							<li className="dashboard__item">
								<DashboardBlock
									titleLabel={t('orders')}
									configurationData={companiesOrdersConfigurations}
								/>
							</li>
						) : null}
						<li className="dashboard__item">
							<AdminClientsList
								setTotalNumberClientsCompany={setTotalNumberClientsCompany}
							/>
						</li>
					</ul>
				</div>
			</section>
		) : null
	);
};

export default AdminDashboardPage;
