import React from 'react';
import Select from 'react-select';

const FormNumEntriesSelector = ({
	id,
	defaultValue,
	options,
	onChange,
}) => {
	const selectStyles = {
		control: (base, state) => ({
			...base,
			width: "100%",
			minWidth: "8em",
			minHeight: 0,
			height: "3.4rem",
			padding: "0 0.6rem",
			backgroundColor: "#fbfbfb",
			// borderRadius: "2px",
			borderColor: state.isFocused ? "#456d86" : "transparent",
			boxShadow: '0 0 0 1px #456d86',
			// border: "1px",
			fontFamily: "DINNextLTPro",
			fontSize: "1.3rem",
			color: "#575757",
		}),
		menu: (base) => ({
			...base,
			minWidth: '6em',
			width: 'auto',
		}),
		option: (base, state) => ({
			...base,
			backgroundColor: state.isSelected ? "#e5e5e5" : "#fbfbfb",
			":hover": {
				backgroundColor: "#EDEDED",
			},
			fontFamily: "DINNextLTPro",
			fontSize: "1.3rem",
			color: "#575757",
		}),
		placeholder: (base) => ({
			...base,
			fontFamily: "DINNextLTPro",
			fontSize: "1.3rem",
			color: "#575757",
		}),
		singleValue: (base) => ({
			...base,
			fontFamily: "DINNextLTPro",
			fontSize: "1.3rem",
			color: "#575757",
		}),
		indicatorsContainer: (base) => ({
			...base,
			margin: '-8px',
			padding: 0,
		}),
		indicatorSeparator: (base) => {
			return {
				...base,
				display: 'none',
			};
		},
	};

	return (
		<div className="form_num_entries_selector">
			<Select
				id={id}
				defaultValue={defaultValue}
				options={options}
				onChange={onChange}
				styles={selectStyles}
			/>
		</div>
	);
};

export default FormNumEntriesSelector;
