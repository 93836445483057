import AbstractGlass from "./AbstractGlass";

export default class FireResistantGlass extends AbstractGlass {
  constructor(data) {
    super(data);
    const {
      FIRE_RESISTANCE_CLASS,
      STRUCTURE,
      MANUFACTURER,
      U_VALUE,
      BUTT_JOINTS,
      MITER,
    } = data;
    this.fireResistanceClass = FIRE_RESISTANCE_CLASS;
    this.structure = STRUCTURE;
    this.manufacturer = MANUFACTURER;
    this.u_value = parseFloat(U_VALUE.replace(",", "."));
    this.butt_joints = BUTT_JOINTS === 'Yes';
    this.miter = MITER === 'Yes';
  }
}
