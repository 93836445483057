import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from 'context/auth';
import { USER_ROLES } from 'utils/constant';
import { Spinner } from 'components/Spinner';

const PrivateAdminRoute = ({ children }) => {
	const { currentUserRole, currentUserAdditionalData } = useContext(AuthContext);

	if (!currentUserRole && !currentUserAdditionalData) {
		return (
			<section className="section dashboard">
				<div className="section__in">
					<div><Spinner darkTheme /></div>
				</div>
			</section>
		);
	}
	return currentUserRole && currentUserRole === USER_ROLES.admin ? children : <Navigate to="/" />;
};

export default PrivateAdminRoute;
