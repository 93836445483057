/* eslint-disable indent */
/* eslint-disable react/jsx-indent */
import React, { useContext } from "react";

import { ConfigurationCode } from "components/ConfigurationCode";
import { GuestForm, RegistrationForm } from "components/Form";
import { ConfigurationDataContext } from "context/configuration";
import { SaveAsSection } from "components/SaveAsSection";
import { useTranslation } from "react-i18next";
import { AuthContext } from "context/auth";
import { Navigate } from "react-router-dom";
import BackButton from "components/Buttons/BackButton";

const GetMyGlassPage = () => {
	const { configuration } = useContext(ConfigurationDataContext);
	const { currentUser, guestUser } = useContext(AuthContext);
	const { t } = useTranslation("cartPage");

	return (currentUser || guestUser) ? (
		<Navigate to="/summary" />
	) : (
		configuration ? (
			<>
				<section className="section">
					<div className="section__in">
						{configuration && configuration.code ? (
							<div className="section__block">
								<ConfigurationCode code={configuration.code} />
							</div>
						) : null}
						<div className="section__block">
							<div className="auth_list">
								<div className="auth_block">
									<GuestForm />
								</div>
								<div className="auth_block">
									<RegistrationForm />
								</div>
							</div>
						</div>
					</div>
				</section>
				<SaveAsSection />
			</>
		) : (
			<section className="section">
				<div className="section__in">
					<div className="section__block">
						<h1 className="section__title section__center">
							{t('cartIsEmpty')}
						</h1>
						<div className="section__center">
							<BackButton
								buttonText={t('orderConfirmationPage:backToStart')}
								navigateToUrl="/"
							/>
						</div>
					</div>
				</div>
			</section>
		)
	);
};

export default GetMyGlassPage;
