import { firebaseDB } from 'utils/firebase';
import {
	setDoc,
	doc,
	deleteDoc,
	query,
	collection,
	where,
	getDocs,
} from 'firebase/firestore';

const COLLECTION_NAME = 'configurations';

const addNewConfiguration = async (configuration) => {
	if (!configuration) return null;

	const res = await setDoc(doc(firebaseDB, COLLECTION_NAME, configuration.code), configuration);

	return res;
};

const deleteConfiguration = async (configurationId) => {
	if (!configurationId) return null;

	const res = await deleteDoc(doc(firebaseDB, COLLECTION_NAME, configurationId));

	return res;
};

const getGuestConfiguration = async (code) => {
	const configurationQuery = query(collection(firebaseDB, COLLECTION_NAME), where('code', '==', code), where('companyId', '==', null), where('type', '==', 'drafts'));

	const snap = await getDocs(configurationQuery);

	const res = [];

	snap.forEach((docSnap) => {
		res.push(docSnap.data());
	});

	return res[0];
};

const CONFIGURATIONS_API = {
	addNewConfiguration,
	deleteConfiguration,
	getGuestConfiguration,
};

export default CONFIGURATIONS_API;
