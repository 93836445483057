// eslint-disable-next-line object-curly-newline
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import jsonAppData from 'utils/jsonAppData';
import { cloneObjectWithoutReference, generateUniqueId, getCurrentDate } from 'utils';
import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import { PdfGlassDocument } from 'components/PdfComponents';
import { ConfigurationDataContext, GlassStructureContext } from 'context/configuration';
import { UiContext } from 'context/ui';

const GlassConfigEditOptions = (
	{
		parent,
		attrKey,
		glassData,
		showPrice,
	},
) => {
	const { t } = useTranslation('commonConfigurationAction');
	const { cartPageActionRef, showPopupByKey } = useContext(UiContext);
	const { editConfiguration, totalGlassSizes } = useContext(
		ConfigurationDataContext,
	);
	const { uiHandlerRef } = useContext(GlassStructureContext);
	const [pdfLoading, setPdfLoading] = useState(false);

	const removeGlassItem = () => {
		if (parent.constructor === Array) {
			showPopupByKey('genericWarning', {
				title: t('notifications:confirmationOfGlassDeletion'),
				confirmDialog: isConfirmed => isConfirmed && parent.splice(attrKey, 1),
			});
		}
	};

	const editGlassItem = () => {
		// editing item {glassData} with index {attrKey}
		if (parent.constructor === Array) {
			showPopupByKey('genericWarning', {
				title: t('notifications:confirmationOfGlassEdit'),
				confirmDialog: isConfirmed => isConfirmed && editConfiguration(glassData),
			});
		}
	};

	const generatePdf = async () => {
		setPdfLoading(true);
		const currentDate = getCurrentDate();
		const documentFileName = (t("configurationDotPdfFmt")).replace("{date}", currentDate);
		const pdfDocument = (
			<PdfGlassDocument
				glassData={glassData}
				totalValues={totalGlassSizes?.[attrKey]}
				showPrice={showPrice}
				uiHandlerRef={uiHandlerRef}
			/>
		);
		const blob = await pdf(pdfDocument).toBlob();
		setPdfLoading(false);
		saveAs(blob, documentFileName);
	};

	const onClickOptionHandler = action => {
		if (action === 'delete') {
			removeGlassItem();
		} else if (action === 'edit') {
			editGlassItem();
		} else if (action === 'duplicate') {
			const duplicatedGlass = cloneObjectWithoutReference(parent[attrKey]);
			duplicatedGlass.id = generateUniqueId();
			let trans = parent.transact();

			if (duplicatedGlass.positions) {
				duplicatedGlass.positions.forEach(position => {
					// eslint-disable-next-line no-param-reassign
					position.id = generateUniqueId();
				});
			}

			const cpa = cartPageActionRef.current;
			cpa.action = 'duplicate';
			cpa.glassId = duplicatedGlass.id;
			trans.push(duplicatedGlass);
			parent.run();
		} else if (action === 'saveAsPdf') {
			generatePdf();
		}
	};

	return (
		<ul className="glass_config_edit_options">
			{jsonAppData.glassConfigEditOptions.map(({
				label,
			}, index) => {
				return (
					<li className="glass_config_edit_options__item" key={index}>
						{label === "saveAsPdf" && pdfLoading
							? (
								<button
									className="glass_config_edit_options__button"
									type="submit"
									disabled
								>
									{t('creatingPdf')}
								</button>
							) : (
								<button
									className="glass_config_edit_options__button"
									type="submit"
									onClick={
										(e) => {
											e.stopPropagation();
											onClickOptionHandler(label);
										}
									}
								>
									{t(label)}
								</button>
							)}
					</li>
				);
			})}
		</ul>
	);
};

export default GlassConfigEditOptions;
