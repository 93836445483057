import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import './styles/main_global.scss';
import './utils/i18n';
import { UiState } from 'context/ui';
import { AuthState } from 'context/auth';
import { AppDataState } from 'context/appData';
import { ConfigurationDataState, GlassStructureState } from 'context/configuration';
import App from './App';
// import reportWebVitals from './reportWebVitals';

ReactDOM.render(
	<React.StrictMode>
		<Router>
			<AuthState>
				<AppDataState>
					<UiState>
						<GlassStructureState>
							<ConfigurationDataState>
								<App />
							</ConfigurationDataState>
						</GlassStructureState>
					</UiState>
				</AppDataState>
			</AuthState>
		</Router>
	</React.StrictMode>,
	document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
