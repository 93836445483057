const Sections = {
  INITIAL: 'initial',
  KONFIGURATOR: 'konfigurator',
  LOADER: 'loader',
  HOME: 'home'
};

const SectionsURLs = {
  INITIAL: '/initial',
  KONFIGURATOR: '/konfigurator',
  LOADER: '/loader',
  HOME: '/'
};

export { Sections, SectionsURLs };
