import { BaseShaderMaterial } from 'ohzi-core';
import fragment from '../shaders/TexturedGlassFrontShader.frag';
import vertex from '../shaders/TexturedGlassFrontShader.vert';
import { ResourceContainer } from 'ohzi-core';
import { Screen } from 'ohzi-core';

import { Vector2 } from 'three';
import { FrontSide } from 'three';
import { LinearFilter, LinearMipmapLinearFilter } from 'three';

export default class TexturedGlassFrontMaterial extends BaseShaderMaterial {
  constructor(rt) {
    super(vertex, fragment, {
      difuseTexture: { value: undefined },
      alphaExponent: { value: 1.0 },
    });

    this.tx = [];
    this.tx[0] = ResourceContainer.get_resource('glass_texture_100');
    this.tx[0].magFilter = LinearFilter;
    this.tx[0].minFilter = LinearMipmapLinearFilter;
    this.tx[0].alphaExponent = 2.0;
    this.tx[0].map = ResourceContainer.get_resource('noise');
    this.tx[1] = ResourceContainer.get_resource('glass_texture_101');
    this.tx[1].magFilter = LinearFilter;
    this.tx[1].minFilter = LinearMipmapLinearFilter;
    this.tx[1].alphaExponent = 1.0;
    this.tx[2] = ResourceContainer.get_resource('glass_texture_102');
    this.tx[2].magFilter = LinearFilter;
    this.tx[2].minFilter = LinearMipmapLinearFilter;
    this.tx[2].alphaExponent = 0.05; // (r+g+b)*0.4 ^ exp  - 0 is transp. 1 is opaque
    this.tx[3] = ResourceContainer.get_resource('glass_texture_103');
    this.tx[3].magFilter = LinearFilter;
    this.tx[3].minFilter = LinearMipmapLinearFilter;
    this.tx[3].alphaExponent = 0.5;

    this.transparent = true;

    this.side = FrontSide;
    this.alphaTest = 0.0;

    //this.uniforms.difuseTexture.value = this.tx[0];

  }

  set opacity(value) {
  }

  get opacity() {
  }

  update() {
  }

  set(texture_id) {
    if (texture_id >= 0 && texture_id < this.tx.length) {
      this.uniforms.difuseTexture.value = this.tx[texture_id];
      this.uniforms.alphaExponent.value = this.tx[texture_id].alphaExponent;
    }
  }
}
