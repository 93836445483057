import { View, Image } from '@react-pdf/renderer';
import React from 'react';

import CheckedIcon from 'i/icons/checked.png';
import styles from '../styles';

const PdfFormCheckbox = ({
	value,
}) => {
	return (
		<View style={styles.glassConfigTableFormCheckbox}>
			<View style={styles.glassConfigTableFormCheckboxLabel}>
				{value.toLowerCase() === 'unchecked' ? (
					<View style={styles.glassConfigTableFormCheckboxLabelTextUnchecked} />
				) : (
					<View style={styles.glassConfigTableFormCheckboxLabelTextChecked}>
						<Image style={styles.glassConfigTableFormCheckboxLabelTextCheckedImage} src={CheckedIcon} />
					</View>
				)}
			</View>
		</View>
	);
};

export default PdfFormCheckbox;
