import React from 'react';
import { useTranslation } from 'react-i18next';

import {
	Text,
	View,
} from '@react-pdf/renderer';

import { PdfBlockHeading } from '../PdfBlockHeading';

import styles from './styles';

const PdfConfigurationCode = ({
	code,
}) => {
	const { t } = useTranslation('cartPage');

	return (
		<View>
			<PdfBlockHeading
				title={t('configurationCodeTitle')}
				iconName="config_code"
			/>
			<View style={styles.configurationCodeIn}>
				<View style={styles.configurationCodeHead}>
					<Text style={styles.configurationCodeTitle}>{t('configurationCodeLabel')}</Text>
					<Text style={styles.configurationCodeValue}>{code}</Text>
				</View>
				<Text style={styles.configurationCodeDescr}>{t('configurationCodeDescription')}</Text>
			</View>
		</View>
	);
};

export default PdfConfigurationCode;
