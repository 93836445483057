import {
	StyleSheet,
} from '@react-pdf/renderer';

const styles = StyleSheet.create({
	uploadedDocListItem: {
		marginBottom: 2,
	},
	uploadedDocBlock: {
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
		justifyContent: 'flex-start',
		alignContent: 'center',
		alignItems: 'center',
		marginBottom: 2,
		paddingHorizontal: 11,
		paddingVertical: 8,
		borderRadius: 2,
		backgroundColor: '#fff',
	},
	uploadedDocBlockSize: {
		marginRight: 15,
		minWidth: 27,
		fontSize: 10,
		fontWeight: 400,
		textTransform: 'uppercase',
		textAlign: 'center',
		color: '#e5e5e5',
	},
	uploadedDocBlockName: {
		fontSize: 10,
		fontWeight: 400,
		color: '#575757',
	},
});

export default styles;
