import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatNumberToFixed } from 'utils';
import { BlockHeading } from 'components/BlockHeading';
import { TotalInfoItem } from './TotalInfoItem';

export const TotalInfo = ({
	quantity, m2, linearMeter, kg, price, showPrice,
}) => {
	const { t } = useTranslation('configurationTableHeading');

	return (
		<div className="total_info">
			<BlockHeading
				title={t('totalCart')}
				iconName="equals"
			/>
			<div className="total_info__head">
				<TotalInfoItem show={quantity} value={t('quantity')} />
				<TotalInfoItem show={m2} value="m" unit={<sup>2</sup>} />
				<TotalInfoItem show={linearMeter} value={t('linearMeterAbbrev')} />
				<TotalInfoItem show={kg} value="kg" />
				{showPrice ? (
					<TotalInfoItem show={price} value={t('price')} />
				) : null}
			</div>
			<div className="total_info__body">
				<TotalInfoItem show={quantity} value={formatNumberToFixed(quantity, 0)} />
				<TotalInfoItem show={m2} value={formatNumberToFixed(m2)} />
				<TotalInfoItem show={linearMeter} value={formatNumberToFixed(linearMeter)} />
				<TotalInfoItem show={kg} value={formatNumberToFixed(kg)} />
				{showPrice ? (
					<TotalInfoItem show={price} value={formatNumberToFixed(price)} />
				) : null}
			</div>
		</div>
	);
};

export default TotalInfo;
