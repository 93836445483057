import React from 'react';

// import { ReactComponent as KeyIcon } from 'i/icons/key.svg';
// import { ReactComponent as ConfigCodeIcon } from 'i/icons/config_code.svg';
// import { ReactComponent as DocumentIcon } from 'i/icons/document.svg';
// import { ReactComponent as OrdersIcon } from 'i/icons/orders.svg';
// import { ReactComponent as PlusIcon } from 'i/icons/plus.svg';
// import { ReactComponent as UserIcon } from 'i/icons/user.svg';
// import { ReactComponent as DubleArrowIcon } from 'i/icons/duble_arrow.svg';

import ConfigCodeIcon from 'i/icons/config_code.png';
import DubleArrowIcon from 'i/icons/duble_arrow.png';
import GearIcon from 'i/icons/gear.png';
import DatasheetIcon from 'i/icons/datasheet.png';
import DocumentIcon from 'i/icons/document.png';
import EqualsIcon from 'i/icons/equals.png';

import {
	Text,
	View,
	Image,
	Link,
} from '@react-pdf/renderer';

import styles from './styles';

const PdfBlockHeading = ({
	title,
	iconName,
	blockIndex,
	datasheetUrl,
}) => {
	const renderIcon = (icon, altText = null, style = null) => {
		const usedStyle = style || styles.blockHeadingIconImage;
		if (icon === 'config_code') {
			return <Image src={ConfigCodeIcon} style={usedStyle} altText={altText} />;
		}

		if (icon === 'duble_arrow') {
			return <Image src={DubleArrowIcon} style={usedStyle} altText={altText} />;
		}

		if (icon === 'datasheet') {
			return <Image src={DatasheetIcon} style={usedStyle} altText={altText} />;
		}

		if (icon === 'document') {
			return <Image src={DocumentIcon} style={usedStyle} altText={altText} />;
		}

		if (icon === 'gear') {
			return <Image src={GearIcon} style={usedStyle} altText={altText} />;
		}

		if (icon === 'equals') {
			return <Image src={EqualsIcon} style={usedStyle} altText={altText} />;
		}

		return null;
	};

	return (
		<View style={styles.blockHeading}>
			<View style={styles.blockHeadingLeft}>
				<View style={styles.blockHeadingIcon}>
					{blockIndex ? (
						<Text style={styles.blockHeadingIndex}>{`${blockIndex}.`}</Text>
					) : (
						renderIcon(iconName)
					)}
				</View>
				<Text style={styles.blockHeadingTitle}>{title}</Text>
			</View>
			{datasheetUrl ? (
				<View style={styles.blockHeadingIconRight}>
					<Link src={datasheetUrl}>
						{renderIcon('datasheet', datasheetUrl, styles.blockHeadingIconImageRight)}
					</Link>
				</View>
			) : null}
		</View>
	);
};

export default PdfBlockHeading;
