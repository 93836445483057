import Insulation from "./Glass/InsulationValues";
import Papa from 'papaparse';

export default class GlassDBLoader {
  static loadDb(glassDb, onLoaded) {
    const csvsToLoadPromises = [
      fetch('./data/glass_translation.json')
        .then((response) => response.json())
        .then((translation) => glassDb.addTranslation(translation))
        .catch((e) => console.error('Error fetching glass translations:', e))
        .finally(() => onLoadCallback('glass_translation')),
    ];

    // Create a timestamp that changes every hour
    const now = new Date();
    const cacheBuster = `?t=${now.getFullYear()}${now.getMonth()}${now.getDate()}${now.getHours()}`;
    const glass_csvs = {
      'frg': { url: './data/frg.csv', header: true },
      'out': { url: './data/Outside-Glass.csv', header: true },
      'mid': { url: './data/Middle-Glass.csv', header: true },
      'price': { url: './data/preismatrix.csv', header: false },
    };
    Object.keys(glass_csvs).forEach(k => {
      const v = glass_csvs[k];
      let csvLoaded, csvLoadError;

      csvsToLoadPromises.push(new Promise((resolve, reject) => {
        csvLoaded = resolve;
        csvLoadError = reject;
      }));

      Papa.parse(v.url.concat(cacheBuster), {
        header: v.header,
        download: true,
        step: function (row) {
          if (row.errors.length) {
            // don't warn on empty lines
            if (Object.keys(row.data).length > 1) {
              console.warn(`Error parsing csv ${k}`);
              console.warn(row.errors);
            }
          } else {
            try {
              glassDb.addRow(k, row.data);
            } catch (e) {
              console.error(`Error parsing ${v.url} at ${row.data.ID ? `row ${row.data.ID}` : `cursor ${row.meta.cursor}`}: ${e}`);
              console.error(row.data);
              csvLoadError();
            }
          }
        },
        complete: csvLoaded,
      });
    });
    glassDb.db['uValue']['2-glazed'] = Insulation.uValueTable['2-glazed'];
    glassDb.db['uValue']['3-glazed'] = Insulation.uValueTable['3-glazed'].filter(v => v.spacer1 === v.spacer2); // only load values with equal spacers

    Promise.allSettled(csvsToLoadPromises).then(() => onLoaded());
  }

  static linkDatasheets(glassDb) {
    const mapGlass = (glassLabel, ds) => {
      const glass = glassDb.db[glassLabel].find(o => o.id === ds.glass_id);
      if (glass) {
        glass.datasheets = ds;
      }
    }
    const dsdb = glassDb.db['datasheets'];
    dsdb['frg'].map(ds => mapGlass('frg', ds));
    dsdb['mid'].map(ds => mapGlass('mid', ds));
    dsdb['out'].map(ds => mapGlass('out', ds));
    dsdb.loaded = true;
  }
}
