import { BlockHeading } from 'components/BlockHeading';
import React from 'react';
import { useTranslation } from 'react-i18next';
import DashboardCompanyInfoItem from './DashboardCompanyInfoItem';

const DashboardCompanyInfo = ({
	companyName,
	numberOfConfigurationsByType,
}) => {
	const { t } = useTranslation('commonAppValues');

	const renderCompanyInfoList = () => {
		if (!numberOfConfigurationsByType) return null;

		const { drafts, requests, orders } = numberOfConfigurationsByType;

		return (
			<ul className="dashboard_company_info__list">
				<DashboardCompanyInfoItem
					title={t('drafts')}
					value={drafts}
				/>
				<DashboardCompanyInfoItem
					title={t('requests')}
					value={requests}
				/>
				<DashboardCompanyInfoItem
					title={t('orders')}
					value={orders}
				/>
			</ul>
		);
	};

	return (
		<div className="dashboard_company_info">
			<BlockHeading
				title={`${t('company')} ${companyName}`}
				iconName="user"
			/>
			{renderCompanyInfoList()}
		</div>
	);
};

export default DashboardCompanyInfo;
