/* eslint-disable react/jsx-no-target-blank */
import React, { useState } from 'react';
import { ReactComponent as DatasheetIcon } from 'i/icons/datasheet.svg';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

const DatasheetBox = ({ datasheetUrl, rightOffsetMod }) => {
	const [isHovering, setIsHovering] = useState(false);
	const { t } = useTranslation('datasheet');
	const BASE_ICON_CLASS = 'icon'; // size_mod

	const dsName = decodeURIComponent(datasheetUrl.split('/').pop());
	const iconClasses = classNames('glass_product_datasheet_box_icon', {
		right_offset_mod: rightOffsetMod,
	});

	return (
		<span
			className="glass_product_datasheet_box"
			onMouseEnter={() => setIsHovering(true)}
			onMouseLeave={() => setIsHovering(false)}
		>
			<a className={iconClasses} href={datasheetUrl} target="_blank" rel="noopener">
				<DatasheetIcon className={BASE_ICON_CLASS} />
			</a>
			{isHovering && (
				<div className="glass_product_datasheet_box_message">
					{t('datasheetFmt').replace('{}', dsName)}
				</div>
			)}
		</span>
	);
};

export default DatasheetBox;
