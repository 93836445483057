import React, {
	useContext, useEffect, useMemo, useState,
} from "react";
import { ReactComponent as PdfIcon } from "i/icons/pdf.svg";
import { useTranslation } from "react-i18next";
import {
	PdfConfigurationsDocument,
	PdfGlassDocument,
} from "components/PdfComponents";
import { AuthContext } from "context/auth";
import { GlassStructureContext } from "context/configuration";
import { saveAs } from 'file-saver';
import { getCurrentDate } from "utils";
import userInfo from "utils/userInfo";
import { pdf } from "@react-pdf/renderer";

const SaveAsBlock = ({
	totalValuesSum, totalValues, pdfData, isHomePage, takeScreenshot, isDisabled,
}) => {
	const { t } = useTranslation("commonConfigurationAction");
	const { uiHandlerRef } = useContext(GlassStructureContext);
	const { guestUser, currentUser, currentUserAdditionalData } = useContext(AuthContext);
	const [pdfLoading, setPdfLoading] = useState(false);
	const customerInfo = useMemo(() => {
		return userInfo({
			guestUser, currentUser, currentUserAdditionalData, companyInfo: currentUserAdditionalData?.company,
		});
	}, [guestUser, currentUser, currentUserAdditionalData]);

	const generatePdf = async () => {
		const currentDate = getCurrentDate();
		const documentFileName = (
			isHomePage ? t("glassDotPdfFmt") : t("configurationDotPdfFmt")
		).replace("{date}", currentDate); // TODO: maybe add glass description / config code
		const showPrice = currentUserAdditionalData?.company?.showPrices || false;
		const pdfDocument = isHomePage ? (
			<PdfGlassDocument glassData={pdfData} totalValues={totalValues} showPrice={showPrice} uiHandlerRef={uiHandlerRef} />
		) : (
			<PdfConfigurationsDocument
				configuration={pdfData}
				totalValuesSum={totalValuesSum}
				totalValues={totalValues}
				customerInfo={customerInfo}
				showPrice={showPrice}
				uiHandlerRef={uiHandlerRef}
			/>
		);
		const blob = await pdf(pdfDocument).toBlob();
		setPdfLoading(false);
		saveAs(blob, documentFileName);
	};

	useEffect(() => {
		if (pdfLoading && Object.keys(pdfData).length > 0) {
			generatePdf();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pdfData]);

	const completePdfDataAndGeneratePdf = async () => {
		setPdfLoading(true);
		if (isHomePage && (!pdfData || Object.keys(pdfData).length === 0) && takeScreenshot) {
			return takeScreenshot();
		} else {
			return generatePdf();
		}
	};

	const renderPDFLink = () => {
		return (
			<button type="button" onClick={completePdfDataAndGeneratePdf} disabled={isDisabled || pdfLoading} className="save_as_block__pdf_button">
				{pdfLoading
					? t("creatingPdf")
					: (
						<>
							<div className="save_as_block__icon">
								<PdfIcon className="icon icon-pdf size_mod" />
							</div>
							<span>{t("saveAsPdf")}</span>
						</>
					)}
			</button>
		);
	};

	return (
		<div className={`save_as_block${isHomePage ? ' save_as_block__left_margin' : ''}`}>
			{(takeScreenshot || (pdfData && Object.keys(pdfData).length > 0)) && renderPDFLink()}
		</div>
	);
};

export default SaveAsBlock;
