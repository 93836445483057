import React, { useEffect, useState } from 'react';

const WarningUIElement = ({ uiHandlerRef }) => {
    const [warningMessage, setWarningMessage] = useState(null);

    useEffect(() => {
        const uiHandler = uiHandlerRef.current;
        if (uiHandler) {
            uiHandler.setUiElement(WarningUIElement, 'warning');
        }
    }, []);

    return (
        <div className="warning">
            {warningMessage !== null ? <p>{warningMessage}</p> : null}
        </div>
    );
};

export default WarningUIElement;