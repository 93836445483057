import AbstractFilter from './AbstractFilter.js'

export default class FireClassFilter extends AbstractFilter {
	constructor() {
		super('FireClassFilter', false);
		this.i18n_prefix = "fireResistanceClass_";
		this.filterOptions = {
			"EI30": "EI30",
			"EI60": "EI60",
			"EI90": "EI90",
		};
	}

	applyFilter(dbView) {
		super.applyFilter(dbView);
		if (this.selectedFilter === null) {
			return;
		}

		for (var i = 0; i < dbView['frg'].length; ++i) {
			if (dbView['frg'][i].fireResistanceClass !== this.selectedFilter) {
				dbView['frgFiltered'].push(dbView['frg'].splice(i, 1)[0]);
				i -= 1;
			}
		}
	}
}
