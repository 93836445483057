import { Mesh } from 'three';
import { Object3D } from 'three';

export default class GenericStructureElement extends Object3D
{
  constructor()
  {
    super();

    this.cube = new Mesh();
    this.cube.renderOrder = 999;
  }

  get width()
  {
    return this.scale.z;
  }

  get thickness()
  {
    return this.width * 100;
  }

  set(width) //
  {
    width *= 0.01;
    this.scale.z = width;

    if (width === 0)
    {
      this.visible = false;
    }
    else
    {
      this.visible = true;
    }
  }

  set shiftZ(shift)
  {
    this.position.z = shift;
  }

  init()
  {

  }

  start()
  {

  }

  update()
  {
  }

}


