import React from 'react';

const GlassConfigTableTextarea = React.forwardRef(({
	placeholder = '',
	name,
	type = 'text',
	required,
	// defaultValue = '',
	value,
	onBlur,
	onKeyDown,
	onChange,
}, ref) => {
	return (
		<div className="glass_config_table_input">
			<div className="glass_config_table_input__box">{value}</div>
			<div className="glass_config_table_input__in">
				<textarea
					ref={ref}
					className="glass_config_table_input__element"
					type={type}
					name={name}
					value={value}
					placeholder={placeholder}
					required={required}
					onBlur={onBlur}
					onKeyDown={onKeyDown}
					onChange={onChange}
				/>
			</div>
		</div>
	);
});

export default GlassConfigTableTextarea;
