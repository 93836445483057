import AbstractFilter from './AbstractFilter.js'

export default class StructureFilter extends AbstractFilter {
  constructor() {
    super("StructureFilter", false);

    this.i18n_prefix = "structure_";
    this.filterOptions = {
      "1-glazed": "1Glazed",
      "2-glazed": "2Glazed",
      "3-glazed": "3Glazed",
    };
  }
}
