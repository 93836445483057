import { BaseShaderMaterial } from 'ohzi-core';
import fragment from '../shaders/GlassSideShader.frag';
import vertex from '../shaders/GlassFrontShader.vert';
import DatGui from '../components/DatGui';
import { ResourceContainer } from 'ohzi-core';
import { Screen } from 'ohzi-core';

import { Vector2 } from 'three';
import { Color } from 'three';
import { DoubleSide } from 'three';
import { DataTexture } from 'three';
import { RGBFormat } from 'three';
import { RepeatWrapping } from 'three';


export default class GlassSideMaterial extends BaseShaderMaterial {
  constructor(rt) {
    super(vertex, fragment, {
      resolution: {
        value:
          new Vector2(Screen.width, Screen.height).multiplyScalar(
            window.devicePixelRatio
          )
      }
      ,
      backNormals: { value: rt.texture },
      envMap: { value: ResourceContainer.get_resource('cube') },
      color: { value: new Color(0x197f66) },
      refractionIndex: { value: DatGui.settings.refractionIndex },
      dispersion: { value: DatGui.settings.dispersion },
      opacity: { value: 0.8 },
      //
      roughness: { value: 0.9 },
      fesnel: { value: DatGui.settings.fesnel },
      noise: { value: ResourceContainer.get_resource('noise') },
      uLayersTexture: { value: undefined },
      timer: { value: 0 },
    });
    this.side = DoubleSide;
    this.transparent = true;
    this.needsUpdate = true;
    this.original_opacity = 0.85;
    this.layer_colors = {
      //float
      '0': { r: 0x72, g: 0xa6, b: 0x92 },
      //white
      '1': { r: 0xe4, g: 0xee, b: 0xea },
      //satin
      '2': { r: 0x54, g: 0x7e, b: 0x5f },
      //gel
      '3': { r: 0xb3, g: 0xcf, b: 0xc5 },
      //pvb
      '4': { r: 0x47, g: 0x6b, b: 0x5d },
      //special textures - irrelevant if width remains 0
      '100': { r: 0xe4, g: 0xee, b: 0xea }, // white
      '101': { r: 0x72, g: 0xa6, b: 0x92 }, // float
      '102': { r: 0xe4, g: 0xee, b: 0xea }, // white
    };
  }

  set opacity(value) {
    if (this.uniforms)
      this.uniforms.opacity.value = value * this.original_opacity;
  }

  get opacity() {

  }

  update_layers_texture(glass_width, layers, flip) {

    // create a buffer with color data
    // please note layers are stacked on the Y axis, so the variation is
    // in the heigth componenet
    const texture_width = 1;
    const texture_height = 512;
    // in 0.5mm

    const size = texture_width * texture_height;
    const data = new Uint8Array(3 * size);

    const layer_unit = (texture_height / glass_width);
    let stride = 0;

    for (let i = 0; i < layers.length; i++) {

      // we read the layers the other way around if we need to flip it
      let index = flip ? layers.length - i - 1 : i;
      let layer_index = 0;
      let layer_width = layers[index].split(':')[0] * layer_unit;
      let layer_color = this.layer_colors[layers[index].split(':')[1]];

      while (layer_index < layer_width) {
        layer_index++;
        stride++;
        data[stride * 3] = layer_color.r;
        data[stride * 3 + 1] = layer_color.g;
        data[stride * 3 + 2] = layer_color.b;
      }
    }

    const texture = new DataTexture(data, texture_width, texture_height, RGBFormat);
    texture.wrapS = RepeatWrapping;
    texture.wrapT = RepeatWrapping;
    this.uniforms.uLayersTexture.value = texture;
  }
  update() {
    //if(this.uniforms.timer.value !== Time.elapsed_time)
    //  this.uniforms.timer.value = Time.elapsed_time;
    //if(this.uniforms.refractionIndex.value !== DatGui.settings.refractionIndex)
    //  this.uniforms.refractionIndex.value = DatGui.settings.refractionIndex;
    //if(this.uniforms.dispersion.value !== DatGui.settings.dispersion)
    //  this.uniforms.dispersion.value = DatGui.settings.dispersion;
    //if(this.uniforms.roughness.value !== DatGui.settings.roughness)
    //  this.uniforms.roughness.value = DatGui.settings.roughness;
    //if(this.uniforms.fesnel.value !== DatGui.settings.fesnel)
    //  this.uniforms.fesnel.value = DatGui.settings.fesnel;
  }

  set(width, clear) {
    this.update_layers_texture(width, clear);
  }
}
