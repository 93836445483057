import {CameraManager} from 'ohzi-core';
import {SceneManager} from 'ohzi-core';
import {BaseRender} from 'ohzi-core';
import {Graphics} from 'ohzi-core';

export default class CustomNormalRender extends BaseRender
{
  constructor()
  {
    super();
  }

  render()
  {
    Graphics.clear(undefined, CameraManager.current, true, true);
    Graphics.render(SceneManager.current, CameraManager.current);
    Graphics.render(SceneManager.frontScene, CameraManager.current);
    Graphics.clear(undefined, undefined, true, false);
    Graphics.render(SceneManager.textScene, CameraManager.current);
  }
}